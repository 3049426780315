export default {
    state: {
        playAgainModal: false,
        noOverflow: false,
        game: {}
    },
    mutations: {
        setVisiblePlayAgainModal(state, payload) {
            state.playAgainModal = payload.playAgainModal
            state.game = payload.game
            state.noOverflow = payload.noOverflow
        }
    },
    actions: {
        showPlayAgainModal({ commit }, game) {
            commit('setVisiblePlayAgainModal', { game, playAgainModal: true, noOverflow: true })
        },
        hidePlayAgainModal({ commit }) {
            commit('setVisiblePlayAgainModal', { game: {}, playAgainModal: false, noOverflow: false })
        }
    }
}
