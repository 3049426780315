<template>
    <div v-if="showGameFooter" class="footer-bar" :class="{ 'mobile-only': this.$route.path.includes('/contests') }">
        <menu-route v-if="visible" @hideSubMenu="hideSubMenu" />
        <router-link v-else :to="`/games/profile`" class="avatar-icon router-link">
            <img
                v-if="!$store.getters.getCurrentUser.avatarUploadFile"
                src="@/assets/avatar-placeholder.png"
                draggable="false"
                class="responsive-avatar"
                alt="avatar icon"
            >
            <img
                v-else
                :src="config.assetsHost + '/' + currentUser.avatarUploadFile.relativePath + '/'+ currentUser.avatarUploadFile.fileName"
                alt="avatar icon"
                draggable="false"
                class="responsive-avatar"
            >
            <div class="avatar-border" />
        </router-link>
        <div class="footer-bar-item">
            <router-link to="/games" class="router-link">
                <button class="footer-button" :class="{'active': $route.path === '/games'}">
                    <div class="icon-container">
                        <img v-if="$route.path === '/games'" src="@/assets/games_icon-hover.png" alt="" draggable="false" class="responsive-img">
                        <img v-else src="@/assets/games_icon.png" alt="" draggable="false" class="responsive-img">
                    </div>
                    <div class="mt-2">{{ $t('gameFooterBar.games') }}</div>
                </button>
            </router-link>
        </div>
        <div class="footer-bar-item">
            <router-link to="/games/history" class="router-link">
                <button class="footer-button" :class="{'active': $route.path === '/games/history'}">
                    <div class="icon-container">
                        <img v-if="$route.path === '/games/history'" src="@/assets/history_icon-hover.png" alt="" draggable="false" class="responsive-img">
                        <img v-else src="@/assets/history_icon.png" alt="" draggable="false" class="responsive-img">
                    </div>
                    <div class="mt-2">{{ $t('gameFooterBar.history') }}</div>
                </button>
            </router-link>
        </div>
        <div class="footer-bar-item">
            <div class="footer-button profile-text" :class="{'active': $route.path === '/games/profile'}">{{ $t('gameFooterBar.myProfile') }}</div>
        </div>
        <div class="footer-bar-item">
            <router-link to="/games/leaderboard" class="router-link">
                <button class="footer-button" :class="{'active': $route.path === '/games/leaderboard'}">
                    <div class="icon-container">
                        <img v-if="$route.path === '/games/leaderboard'" src="@/assets/leaderboard_icon-hover.png" alt="" draggable="false" class="responsive-img">
                        <img v-else src="@/assets/leaderboard_icon.png" alt="" draggable="false" class="responsive-img">
                    </div>
                    <div class="mt-2">{{ $t('gameFooterBar.ranking') }}</div>
                </button>
            </router-link>
        </div>
        <div class="footer-bar-item hidden-element">
            <router-link to="/games/information" class="router-link">
                <button class="footer-button" :class="{'active': $route.path === '/games/information'}">
                    <div class="icon-container">
                        <img v-if="$route.path === '/games/information'" src="@/assets/info_icon-hover.png" alt="" draggable="false" class="responsive-img">
                        <img v-else src="@/assets/info_icon.png" alt="" draggable="false" class="responsive-img">
                    </div>
                    <div class="mt-2">{{ $t('gameFooterBar.info') }}</div>
                </button>
            </router-link>
        </div>
        <div class="footer-bar-item">
            <button class="footer-button" @click="showSubMenu()">
                <div class="icon-container">
                    <img src="@/assets/menu-icon-more.png" alt="" draggable="false">
                </div>
                <div class="mt-2">{{ $t('gameFooterBar.menu') }}</div>
            </button>
        </div>
    </div>
</template>

<script>
    import config from '@/mixins/config'
    import MenuRoute from '@/components/games/MenuRoute'
    export default {
        name: 'GameFooterBar',
        components: {
            MenuRoute
        },
        mixins: [config],
        props: {
        },
        data() {
            return {
                visible: false
            }
        },
        computed: {
            currentUser() {
                return this.$store.getters.getCurrentUser || {}
            },
            showGameFooter() {
                return (
                    this.$store.getters.getCurrentUser && (
                        this.$route.path.includes('/games') ||
                        this.$route.path.includes('/MobileGames') ||
                        this.$route.path.includes('/contests')
                    )
                )
            }
        },
        methods: {
            showSubMenu() {
                this.visible = true
            },
            hideSubMenu() {
                this.visible = false
            }
        }
    }
</script>

<style scoped>
.footer-bar {
    position: fixed;
    bottom: 0;
    background: #0855f3;
    height: 140px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.footer-bar-item {
    margin-top: 8px;
    display: flex;
    justify-content: center;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.avatar-icon {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
}

.avatar-border {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border: 8px solid #fff;
    border-radius: 130px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.footer-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;
    background: none;
    font-size: 25px;
    font-weight: 500;
    font-family: 'CFA-light';
    color: #fff;
}

.footer-button:focus {
    outline: none;
    border: none;
    background: none;
}

.footer-button:active {
    outline: none;
    border: none;
    background: none;
}

.active {
    font-family: 'CFA-Bold';
    font-size: 25px;
    color: #fff;
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    margin: 0 auto;
}

.active .icon-container {
    background: #fff;
    border-radius: 50%;
    mix-blend-mode: initial;
}

.router-link {
    text-decoration: none;
}

.profile-text {
    line-height: 13px;
    margin-top: 50px;
    text-align: center;
}

@media screen and (min-width: 768px) and (orientation: landscape) {
    .mobile-only {
        display: none;
    }
}

@media screen and (max-width: 1600px) {
    .icon-container {
        width: 40px;
        height: 40px;
        text-align: center;
    }

    .responsive-img {
        width: 30px;
        height: 30px;
    }

    .footer-button {
        font-size: 11px;
    }

    .hidden-element {
        display: none;
    }

    .responsive-avatar {
        width: 60px;
        height: 60px;
    }

    .avatar-icon {
        bottom: 50px;
    }

    .profile-text {
        margin-top: 30px;
    }

    .avatar-border {
        border-radius: 130px;
        border: 3px solid #fff;
    }

    .footer-bar-item {
        margin-top: 0;
    }

    .footer-bar {
        height: 80px;
    }
}

</style>
