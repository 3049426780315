export default {
    data() {
        return {
            config: {
                host: process.env.VUE_APP_HOST_DOMAIN,
                assetsHost: process.env.VUE_APP_ASSETS_HOST,
                apiUrl: process.env.VUE_APP_HOST_DOMAIN + process.env.VUE_APP_BASE_API,
                env: process.env.VUE_APP_ENV,
                devAbCard: process.env.VUE_APP_DEV_AB_CARD,
                gameServerHost: process.env.VUE_APP_GAME_SERVER_HOST
            }
        }
    }
}
