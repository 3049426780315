<template>
    <div id="app" class="wrapper">
        <router-view />
        <game-play-again v-if="isLoggedIn" />
        <div v-if="isGamesSite && isLoggedIn ">
            <game-footer-bar />
        </div>
        <consent-modal />
        <employee-consent-modal v-if="$store.getters.isEmployeeConsentModalVisible" />
    </div>
</template>

<script>
    import GamePlayAgain from '@/components/games/GamePlayAgainModal'
    import GameFooterBar from '@/components/games/GameFooterBar'
    import ConsentModal from '@/components/modals/ConsentModal'
    import EmployeeConsentModal from '@/components/modals/EmployeeConsentModal'
    import AppPrefix from '@/utils/AppPrefix'
    // import contestService from '@/services/contest-service'

    export default {
        components: {
            GamePlayAgain, GameFooterBar, ConsentModal, EmployeeConsentModal
        },
        data() {
            return {
                refreshTokenInterval: null,
                isLoggedInInterval: null
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isUserLoggedIn
            },
            isAdminLoggedIn() {
                return this.$store.getters.isAdminUserLoggedIn
            },
            isGamesSite() {
                return new AppPrefix(this.$route).isGamesSite()
            },
            isGameAutoLogin() {
                return new AppPrefix(this.$route).isGameAutoLogin()
            },
            isConsentModalVisible() {
                return this.$store.getters.isConsentModalVisible
            },
            currentUser() {
                return this.$store.getters.getCurrentUser
            }
        },
        watch: {
            currentUser: {
                immediate: true,
                handler(newVal) {
                    if (newVal && newVal.barcode) {
                        // const appPrefix = new AppPrefix(this.$route)
                        // this.$store.dispatch('showConsentModal', { consentWay: appPrefix.getPrefix() })
                    }
                }
            }
            // isLoggedIn: {
            //     immediate: true,
            //     handler(newVal) {
            //         if (newVal) {
            //             contestService.fetchAvailable()
            //                 .then(response => {
            //                     this.$store.dispatch('setContest', response)
            //                 })
            //                 .catch(e => {
            //                     console.log(e)
            //                 })
            //         }
            //     }
            // }
        },
        created() {
            // this.addAPMFilter()
            this.initCurrentAdminUser()
            this.refreshJwtTokenUsingInterval()
            this.checkIfLoggedIn()
        },
        mounted() {
            this.$nextTick(() => {
                document.addEventListener('gesturestart', (e) => {
                    e.preventDefault()
                })
            })
        },
        methods: {
            addAPMFilter() {
                this.$apm.addFilter((payload) => {
                    for (const transaction of payload.transactions) {
                        if (transaction.name.startsWith('Click ')) {
                            transaction.name = this.$route.path + ' -> ' + transaction.name
                        }
                    }
                    return payload
                })
            },
            initCurrentAdminUser() {
                if (this.isAdminLoggedIn) {
                    this.$store.dispatch('refreshCurrentAdminUser')
                }
            },
            checkIfLoggedIn() {
                if (this.isLoggedInInterval) {
                    clearInterval(this.isLoggedInInterval)
                }
                this.isLoggedInInterval = setInterval(() => {
                    const apiToken = localStorage.getItem('web-app-jwt-token')
                    if (apiToken == null && !this.$route.meta.notSecure) {
                        this.$store.dispatch('logout', this.$router.history.current.fullPath)
                    }
                }, 10000)
            },
            refreshJwtTokenUsingInterval() {
                if (this.refreshTokenInterval) {
                    clearInterval(this.refreshTokenInterval)
                }
                this.refreshTokenInterval = setInterval(() => {
                    this.$store.dispatch('refreshJwtToken')
                }, 60000)
            }
        }
    }
</script>

<style>
    body {
        font-family: sans-serif;
        position: relative;
    }

    html,
    body {
        width: 100%;
        height: 100%;
        margin: 0;    /* ios only */
        padding: 0;   /* ios only */
        overflow-x: hidden;
        text-rendering: optimizeLegibility;
    }

    .bold {
        font-weight: bold;
    }

    .blue {
        color: #00c0f3;
    }

    .purple {
        color: #634a81;
    }

    .red {
        color: #ed1b25;
    }

    @font-face {
        font-family: "PFHandbookProMedium";
        src: url("~@/assets/fonts/PFHandbookPro-Medium.woff") format("woff");
    }

    @font-face {
        font-family: "ProximaBold";
        src: url("~@/assets/fonts/ProximaNova-Bold.otf") format("opentype");
    }

    @font-face {
        font-family: "OpenSansBold";
        src:
            url("~@/assets/fonts/opensans-bold-webfont.eot") format('embedded-opentype'),
            url("~@/assets/fonts/OpenSans-Bold.ttf") format("trueType"),
            url("~@/assets/fonts/opensans-bold-webfont.woff") format("woff"),
            url("~@/assets/fonts/opensans-bold-webfont.woff2") format("woff2");
    }

    @font-face {
        font-family: "OpenSansItalic";
        src:
            url("~@/assets/fonts/opensans-italic-webfont.eot") format('embedded-opentype'),
            url("~@/assets/fonts/OpenSans-Italic.ttf") format("trueType"),
            url("~@/assets/fonts/opensans-italic-webfont.woff") format("woff"),
            url("~@/assets/fonts/opensans-italic-webfont.woff2") format("woff2");
    }

    @font-face {
        font-family: "OpenSansRegular";
        src:
            url("~@/assets/fonts/opensans-regular-webfont.eot") format('embedded-opentype'),
            url("~@/assets/fonts/OpenSans-Regular.ttf") format("trueType"),
            url("~@/assets/fonts/opensans-regular-webfont.woff") format("woff"),
            url("~@/assets/fonts/opensans-regular-webfont.woff2") format("woff2");
    }

    @font-face {
        font-family: "OpenSansLight";
        src:
            url("~@/assets/fonts/opensans-light-webfont.eot") format('embedded-opentype'),
            url("~@/assets/fonts/OpenSans-Light.ttf") format("trueType"),
            url("~@/assets/fonts/opensans-light-webfont.woff") format("woff"),
            url("~@/assets/fonts/opensans-light-webfont.woff2") format("woff2");
    }

    @font-face {
        font-family: "CFA-Normal";
        src: url("~@/assets/fonts/abvassilopoulos_astycf-medium-webfont.eot") format('embedded-opentype');
        src:
            url("~@/assets/fonts/abvassilopoulos_astycf-medium-webfont.ttf") format("trueType"),
            url("~@/assets/fonts/abvassilopoulos_astycf-medium-webfont.woff") format("woff");
    }

    @font-face {
        font-family: "CFA-Bold";
        src: url("~@/assets/fonts/CFAstyStd-Bold.eot") format('embedded-opentype');
        src:
            url("~@/assets/fonts/CFAstyStd-Bold.ttf") format("trueType"),
            url("~@/assets/fonts/CFAstyStd-Bold.woff") format("woff");
    }

    @font-face {
        font-family: "CFA-Light";
        src: url("~@/assets/fonts/CFAstyStd-Light.eot") format('embedded-opentype');
        src:
            url("~@/assets/fonts/CFAstyStd-Light.ttf") format("trueType"),
            url("~@/assets/fonts/CFAstyStd-Light.woff") format("woff");
    }

    @font-face {
        font-family: "ThickStick";
        src: url("~@/assets/fonts/AC-ThickStick.otf") format('opentype');
        src: url("~@/assets/fonts/AC-ThickStick.ttf") format("trueType");
    }
</style>
