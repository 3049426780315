<template>
    <div class="easter-promo-tile">
        <img
            :src="`${config.assetsHost}/${contestPromo.image.relativePath}/${contestPromo.image.fileName}`"
            alt="Promo"
            class="easter-promo-logo"
        >
        <div class="btn easter-promo-btn" @click="goToPromoContestPage">
            <span><strong>ΞΕΚΙΝΑ</strong> ΕΔΩ</span><font-awesome-icon :icon="['fas', 'chevron-right']" />
        </div>
    </div>
</template>

<script>
    import config from '@/mixins/config'

    export default {
        mixins: [config],
        props: {
            contestPromo: {
                type: Object,
                required: true
            }
        },
        methods: {
            goToPromoContestPage() {
                this.$router.push({ name: 'AB Contest', params: { contestId: this.contestPromo.id } })
            }
        }
    }
</script>

<style scoped>
.easter-promo-tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.easter-promo-logo {
    width: 23.5rem;
    max-width: 90vw;
}

.btn {
    background-color: #009fe3;
    padding: 1.875rem;
    color: white;
    font-size: 1.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: CFA-Normal, sans-serif;
    width: 23.875rem;
    border-radius: 1.25rem;
    max-width: 95vw;
}

@media (min-width: 768px) {
    .btn {
        width: 30.625rem;
    }
}
</style>
