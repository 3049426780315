import restAPI from '@/utils/rest-api'

export default {
    fetchEmployeeConsent() {
        return restAPI.get('/consents/employee/fetch')
    },
    submitEmployeeConsent(userChoise) {
        return restAPI.post('/consents/employee/submit', { marketingConsent: userChoise })
    }
}
