<template>
    <div class="game-top-bar">
        <div v-if="showHome" class="hidden-element">
            <button :disabled="disableHome" class="btn icon-home" @click="backToHome()" />
        </div>
        <div v-if="showAbPlus" class="card-container">
            <img src="@/assets/letters.png" alt=" " class="letter-icon">
            <div class="ml-3">
                <div class="card-text-up">{{ $t('gameTopBar.cardCode') }}</div>
                <div v-if="abPlusNumber" class="card-text-down">{{ 'XXXXXXXXXX' + abPlusNumber.toString().slice(9, 12) }}</div>
            </div>
        </div>
        <div class="hr responsive-element" />
        <div class="info-bar">
            <div>
                <div class="text-simple">{{ $t('gameTopBar.points')[0] }}</div>
                <div class="text-simple">{{ $t('gameTopBar.points')[1] }}</div>
            </div>
            <div class="numbers-text">{{ totalPoints }}</div>
            <img src="@/assets/favorite.png" alt="" class="pb-1 ml-1 favorite-icon">
            <div class="hr hidden-element" />
            <span class="text-simple hidden-element">{{ $t('gameTopBar.position') }}</span>
            <div class="numbers-text hidden-element">{{ position === 0 ? '-' : position }}</div>
            <img src="@/assets/position_icon_top_bar.png" alt="" class="pb-2 hidden-element">
            <div class="hr hidden-element" />
            <div class="game-number-text hidden-element">
                {{ availableGames }}
            </div>
            <div class="available-text hidden-element">
                <div>
                    {{ $t('gameTopBar.available') }}
                </div>
                <div>
                    {{ $t('gameTopBar.games') }}
                </div>
            </div>
        </div>
        <div v-if="false" class="hidden-element">
            <button class="btn icon-sign-out" @click="logOut" />
        </div>
    </div>
</template>

<script>
    import gameService from '@/services/game-service'
    export default {
        name: 'TopBar',
        components: {},
        props: {
            showHome: {
                type: Boolean,
                default: () => false
            },
            showAbPlus: {
                type: Boolean,
                default: () => true
            },
            showLogout: {
                type: Boolean,
                default: () => true
            },
            disableHome: {
                type: Boolean,
                default: () => true
            }
        },
        data() {
            return {
                availableGames: 0
            }
        },
        computed: {
            abPlusNumber() {
                return this.$store.getters.getAbPlusNumber
            },
            currentUser() {
                return this.$store.getters.getCurrentUser || {}
            },
            gameHistory() {
                return this.$store.getters.getGameHistory
            },
            leaderboard() {
                return this.$store.getters.getLeaderboard
            },
            position() {
                if (!this.leaderboard.ranks) {
                    return 0
                }
                for (const rank of this.leaderboard.ranks) {
                    if (rank.customerId === this.currentUser.id) {
                        return rank.position
                    }
                }
                return 0
            },
            totalPoints() {
                let points = 0
                for (const game of this.gameHistory) {
                    points += game.pointsEarned
                }
                return points
            }
        },
        mounted() {
            this.getAvailableGames()
        },
        methods: {
            logOut() {
                this.$store.dispatch('logout', '/games')
            },
            backToHome() {
                this.$router.push({ path: '/games' })
            },
            getAvailableGames() {
                gameService.fetchGamePool().then((gameList) => {
                    gameList = gameList.content
                    this.availableGames = gameList.filter(
                        (gameList) => !gameList.played
                    ).length
                })
            }
        }
    }
</script>

<style scoped lang="scss">
.icon-sign-out {
    background-image: url("~@/assets/exit_new_button.png");
    display: inline-block;
    height: 138px;
    width: 137px;
}

.icon-home {
    background-image: url("~@/assets/home_new_button.png");
    display: inline-block;
    height: 138px;
    width: 137px;
}

.game-top-bar {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 1%, rgba(130, 215, 247, 1) 11%, rgba(0, 174, 239, 1) 25%);
    background-color: rgb(130, 215, 247);
    height: 115px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
}

.card-container {
    display: flex;
    align-items: center;
}

.info-bar {
    display: flex;
    align-items: center;
    margin-left: 345px;
}

.hr {
    border-right: 1px solid rgb(255, 255, 255, 0.5);
    height: 50px;
    margin-left: 50px;
    margin-right: 50px;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.text-simple {
    color: #94d5f1;
    font-size: 18px;
    text-align: right;
    font-family: 'CFA-Bold';
    white-space: pre;
    line-height: 20px;
    margin-right: 10px;
}

.numbers-text {
    color: #fff;
    font-size: 44px;
    font-weight: 700;
    font-family: 'CFA-Bold';
}

.card-text-up {
    color: #fff;
    font-size: 18px;
    font-family: 'CFA-Bold';
}

.responsive-element {
    display: none;
}

.card-text-down {
    text-align: left;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    font-family: 'CFA-light';
}

.game-number-text {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    height: 40px;
    border-radius: 50px;
    background: #ea1d24;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    font-family: 'CFA-Light';
}

.available-text {
    color: #fff;
    font-family: 'CFA-Light';
    margin-left: 20px;
    font-weight: 700;
    font-size: 20px;
    white-space: pre;
    line-height: 20px;
}

@media screen and (max-width: 1600px) {
    .card-text-up {
        font-size: 12px;
    }

    .favorite-icon {
        width: 23px;
    }

    .info-bar {
        margin-left: 10px;
    }

    .text-simple {
        font-size: 12px;
        line-height: 15px;
    }

    .letter-icon {
        width: 45px;
        height: auto;
    }

    .numbers-text {
        font-size: 28px;
    }

    .game-top-bar {
        height: 65px;
    }

    .star-icon {
        margin-right: 0;
        margin-top: 15px !important;
        width: 63px;
    }

    .hr {
        margin-left: 5px;
        margin-right: 5px;
    }

    .card-text-down {
        font-size: 12px;
    }

    .hidden-element {
        display: none !important;
    }

    .responsive-element {
        display: inline;
    }

    .games-banner {
        font-size: 15px;
        flex-direction: column;
        text-align: center;
        padding-bottom: 20px;
    }

    .banner-bold-text {
        font-size: 17px;
    }
}
</style>
