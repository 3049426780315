<template>
    <div>
        <b-navbar variant="secondary" class="d-flex flex-row-reverse">
            <b-button variant="danger" @click="logout">Logout</b-button>
        </b-navbar>
        <b-container fluid="lg">
            <b-row>
                <b-col>
                    <b-card-group deck class="my-5">
                        <b-card
                            header="Affinity partner details"
                            class="text-center"
                        >
                            <ul v-if="currentAdminUser" class="list-group text-left">
                                <li class="list-group-item">
                                    Username: {{ currentAdminUser.username }}
                                </li>
                                <li class="list-group-item">
                                    Company name: {{ currentAdminUser.company.name || 'not found' }}
                                </li>
                            </ul>
                        </b-card>
                    </b-card-group>
                    <b-alert
                        :show="dismissCountDown"
                        dismissible
                        :variant="state"
                        @dismissed="closeAlertMessage"
                    >
                        {{ alertMessage }}
                    </b-alert>
                    <b-card-group deck>
                        <b-card
                            header="Affinity user registration"
                            class="text-center"
                        >
                            <b-table />
                            <b-form @submit.prevent="onSubmit">
                                <b-form-group
                                    class="text-left"
                                    label="Customers phone number:"
                                    description="Insert customer's phone for the campaign"
                                >
                                    <b-form-input
                                        v-model="mobile"
                                        type="text"
                                        placeholder="exp: 6900000000"
                                        :state="allowPhoneSubmission"
                                        required
                                        :formatter="phoneNumberFormatter"
                                        @keypress="allowNumbersKeyPressUpToTenLength($event, mobile)"
                                    />
                                    <b-form-invalid-feedback :state="allowPhoneSubmission">
                                        Phone number must be 10 digits long only numbers
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                    class="text-left"
                                    label="Affinity offer:"
                                    description="Select the appropriate campaign"
                                >
                                    <b-form-select
                                        v-model="offerSelected"
                                        :options="availableOffers"
                                        required
                                    />
                                </b-form-group>
                                <b-button type="submit" variant="primary" class="mt-3">Submit</b-button>
                            </b-form>
                        </b-card>
                    </b-card-group>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import config from '@/mixins/config'
    import phoneValidation from '@/mixins/phone-validation'
    import userService from '@/services/user-service'
    import affinityService from '@/services/affinity-service'
    export default {
        name: 'AffinitySubmissionForm',
        components: {
        },
        mixins: [config, phoneValidation],
        data() {
            return {
                mobile: null,
                phoneIsValid: null,
                offerSelected: {},
                availableOffers: [],
                dismissSecs: 3,
                dismissCountDown: 0,
                state: 'danger',
                alertMessage: 'Successful user registration to campaign'
            }
        },
        computed: {
            allowPhoneSubmission() {
                if (this.mobile === '') {
                    return false
                }
                return this.mobileIsTenDigits(this.mobile) && this.phoneIsValid === true
            },
            currentAdminUser() {
                return this.$store.getters.getCurrentAdminUser
            }
        },
        watch: {
            mobile: {
                immediate: true,
                handler(newVal) {
                    if (newVal && this.mobileIsTenDigits(newVal)) {
                        this.validatePhoneFromBackend(newVal)
                    } else {
                        this.phoneIsValid = false
                    }
                }
            }
        },
        beforeCreate() {
            if (!this.$store.getters.isAdminUserLoggedIn) {
                this.$router.push('/affinity-partner/login')
            }
        },
        created() {
            this.getAvailableOffers()
        },
        methods: {
            onSubmit() {
                if (!this.allowPhoneSubmission) {
                    this.showAlert('danger', 'Invalid data entered')
                    return
                }
                affinityService.registerPhoneToOffer(this.offerSelected, '30' + this.mobile).then(response => {
                    this.showAlert('success', 'Successful user registration to campaign')
                }, error => {
                    this.showAlert('danger', error.response.data.message)
                })
            },
            validatePhoneFromBackend(mobile) {
                userService.checkPhoneNumber(30 + mobile)
                    .then(() => { this.phoneIsValid = true })
                    .catch(() => { this.phoneIsValid = false })
            },
            getAvailableOffers() {
                affinityService.fetchAffinityOffers()
                    .then(response => {
                        this.availableOffers = response.content.map(offer => {
                            return { text: offer.name, value: offer.id }
                        })
                    })
            },
            logout() {
                this.$store.dispatch('adminLogout')
                this.$router.push('/affinity-partner/login')
            },
            showAlert(state, message) {
                this.state = state
                this.alertMessage = message
                this.dismissCountDown = this.dismissSecs
            },
            closeAlertMessage() {
                this.dismissCountDown = 0
            }
        }
    }
</script>
