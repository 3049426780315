import { render, staticRenderFns } from "./CancelledCardView.vue?vue&type=template&id=19da1947&scoped=true&"
import script from "./CancelledCardView.vue?vue&type=script&lang=js&"
export * from "./CancelledCardView.vue?vue&type=script&lang=js&"
import style0 from "./CancelledCardView.vue?vue&type=style&index=0&id=19da1947&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19da1947",
  null
  
)

export default component.exports