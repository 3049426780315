<template>
    <div class="combo-container">
        <b-row class="justify-content-center">
            <div class="header">
                {{ $t('comboCoupon.create') }}
                <span class="bold">{{ $t('comboCoupon.combo') }}</span>
                {{ $t('comboCoupon.win') }}
                <span class="bold top-text">
                    {{ points }} {{ $t('comboCoupon.points') }}
                </span>
            </div>
            <div class="ml-4 mt-2">
                <img
                    class="show-modal"
                    :src="require('@/assets/images/combo/info-star.png')"
                    alt="info_star"
                    @click="showIntroModal"
                >
            </div>
        </b-row>
        <div class="main-menu">
            <div class="product-selection">
                <div class="group top-group" :class="{ 'one-option': topComboProducts.length === 1, 'two-options': topComboProducts.length === 2 }">
                    <combo-combination
                        id="top"
                        :combo-products="topComboProducts"
                        @update="selectTop"
                    />
                    <div class="top-line" />
                </div>
                <div class="group bottom-group" :class="{ 'one-option': bottomComboProducts.length === 1, 'two-options': bottomComboProducts.length === 2 }">
                    <combo-combination
                        id="bottom"
                        :combo-products="bottomComboProducts"
                        @update="selectBottom"
                    />
                    <div class="bottom-line" />
                </div>
            </div>
            <div class="results-container">
                <combo-results
                    :coupon="couponToRedeem"
                    :show-modal="showBRModal"
                />
            </div>
        </div>
        <bar-code-modal
            v-if="showBRModal"
            :coupon="couponToRedeem"
            :offer-id="offer.id"
        />
        <intro-modal v-if="checkIntroModal" :show-intro-modal="checkIntroModal" :offer="offer" />
    </div>
</template>

<script>
    import offerService from '@/services/offer-service'
    import config from '@/mixins/config'
    import ComboCombination from '@/components/combo/ComboCombination'
    import ComboResults from '@/components/combo/ComboResults'
    import BarCodeModal from '@/components/combo/BarCodeModal'
    import IntroModal from '@/components/combo/IntroModal'

    export default {
        name: 'ComboCoupon',
        components: { ComboCombination, ComboResults, BarCodeModal, IntroModal },
        mixins: [config],
        data() {
            return {
                offer: null,
                points: null,
                selectedTop: null,
                selectedBottom: null,
                couponPosition: null,
                couponToRedeem: null,
                showBRModal: false,
                checkIntroModal: false,
                resultingCouponPath: null
            }
        },
        computed: {
            topComboProducts() {
                if (!this.offer) {
                    return []
                }
                return this.offer.firstComboProducts
            },
            bottomComboProducts() {
                if (!this.offer) {
                    return []
                }
                return this.offer.secondComboProducts
            },
            currentUser() {
                return this.$store.getters.getCurrentUser
            }
        },
        watch: {
            currentUser: {
                handler(currentUser) {
                    const urlCode = this.$route.params.urlCode
                    if (currentUser && urlCode) {
                        this.fetchOffer(urlCode)
                    }
                },
                immediate: true
            }
        },
        methods: {
            fetchOffer(urlCode) {
                offerService.fetchOfferByUrlCode(urlCode).then(offerRes => {
                    this.offer = offerRes
                    if (this.offer.offerType !== 'COMBO') {
                        this.$router.push('/expired-or-invalid')
                    }
                    this.couponToRedeem = this.findRedeemedCoupon(this.offer)
                    if (this.couponToRedeem) {
                        this.resultingCouponPath = `${this.config.assetsHost}/${this.couponToRedeem.digitalImage.relativePath}/${this.couponToRedeem.digitalImage.fileName}`
                        this.showBRModal = true
                    }
                })
            },
            findRedeemedCoupon(offer) {
                for (const coupon of offer.coupons) {
                    if (coupon.redemptionDate) {
                        return coupon
                    }
                }
                return null
            },
            selectTop(top) {
                this.selectedTop = top
                this.calculateResultingCoupon()
            },
            selectBottom(bottom) {
                this.selectedBottom = bottom
                this.calculateResultingCoupon()
            },
            calculateResultingCoupon() {
                if (!this.selectedTop || !this.selectedBottom) {
                    this.resultingCouponPath = ''
                    return
                }
                for (const coupon of this.offer.coupons) {
                    if (coupon.firstComboProduct.id === this.selectedTop.id &&
                        coupon.secondComboProduct.id === this.selectedBottom.id) {
                        this.couponToRedeem = coupon
                        this.resultingCouponPath = `${this.config.assetsHost}/${coupon.digitalImage.relativePath}/${coupon.digitalImage.fileName}`
                        break
                    }
                }
                this.resultingCouponPath = ''
            },
            showIntroModal() {
                this.checkIntroModal = !this.checkIntroModal
            },
            redeemCoupon() {
                this.showBRModal = true
                offerService.redeemCoupon(this.offer.id, this.couponToRedeem.barcodeNumber, this.$route.params.urlCode)
                    .catch(() => {
                    })
            }
        }
    }
</script>

<style>
    .pointer {
        cursor: pointer;
    }
</style>

<style scoped>
    html {
        background: #eff1ee;
        overflow-y: auto;
    }

    .combo-container {
        max-width: 1100px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
    }

    .header {
        font-size: 1.5rem;
        letter-spacing: -1px;
        line-height: 2rem;
        color: #3d3d3d;
        font-family: "CFA-light";
        text-align: center;
        width: 70%;
    }

    .bold {
        font-family: "CFA-bold";
    }

    .top-text {
        color: #7c5cb4;
    }

    .results-container {
        display: flex;
        margin: 0 auto;
    }

    .main-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;
    }

    .product-selection {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .one-option {
        width: 33.33%;
    }

    .two-options {
        width: 66.66%;
    }

    .top-line,
    .bottom-line {
        min-height: 12px;
        height: 2.5vw;
        max-height: 18px;
        transform: translateY(-20px);
        border-radius: 0 0 5px 5px;
    }

    .top-line {
        background-color: #7c5cb4;
    }

    .bottom-line {
        background-color: #f9855a;
    }

    .show-modal {
        width: 40px;
        cursor: pointer;
    }

    @media screen and (min-width: 576px) {
        .header {
            font-size: 2rem;
            line-height: 2.2rem;
        }

        .combo-container {
            width: 75%;
        }
    }

    @media screen and (min-width: 768px) {
        .combo-container {
            width: 70%;
        }
    }

    @media screen and (min-width: 992px) {
        .header {
            font-size: 2.5rem;
            line-height: 2.6rem;
        }

        .combo-container {
            overflow: hidden;
            width: 90%;
        }

        .main-menu {
            flex-direction: row;
        }

        .product-selection {
            width: 70%;
            margin-right: 1rem;
        }

        .results-container {
            display: block;
            width: 50%;
            max-width: 60%;
        }
    }

</style>
