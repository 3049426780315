import restAPI from '@/utils/rest-api'

export default {
    fetchAvatars() {
        return restAPI.get(`/default-avatars?sort=${encodeURIComponent('[position,ASC]')}`)
    },
    postAvatar(avatar) {
        return restAPI.post('/default-avatars', avatar)
    }
}
