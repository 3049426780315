<template>
    <b-container v-if="offer && offer.id && offer.active" fluid class="background-image">
        <affinity-top-bar :show-home="!offer.hidden" :disable-home="false" />
        <div class="main-container">
            <b-row class="align-center">
                <b-col md="7">
                    <div class="text-responsive">
                        <div class="more-info mb-2">{{ $t('affinityOffer.more') }}</div>
                        <div class="title-bold mb-4">{{ offer.name }}</div>
                        <img :src="config.assetsHost + '/' + offer.sideImage.relativePath + '/' + offer.sideImage.fileName" class="more-info-image mb-4 d-md-none">
                        <div v-dompurify-html="offer.moreInfo" class="text-light-description" />
                        <img src="@/assets/images/back_button.png" class="back-button mb-4" @click="goBack">
                    </div>
                </b-col>
                <b-col md="5" class="d-none d-md-block">
                    <img :src="config.assetsHost + '/' + offer.sideImage.relativePath + '/' + offer.sideImage.fileName" class="more-info-image">
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
    import pageViewsService from '@/services/page-views-service'
    import config from '@/mixins/config'
    import offerService from '@/services/affinity-offer-service'
    import AffinityTopBar from '@/components/AffinityTopBar'

    export default {
        name: 'AffinityMoreInfoRoute',
        components: {
            AffinityTopBar
        },
        mixins: [config],
        data() {
            return {
                offer: {}
            }
        },
        created() {
            pageViewsService.submitPageView('AB PARTNERS INFO', this.$route.path, this.$route.params)
            offerService.fetchAffinityOfferById(this.$route.params.affinityOfferId).then(offer => {
                this.offer = offer
            })
        },
        methods: {
            goBack() {
                this.$router.back()
            }
        }
    }
</script>
<style scoped lang="scss">

.align-center {
    align-items: center;
}

.background-image {
    background: url('~@/assets/images/affinity_bg.jpg');
    background-repeat: repeat;
    height: 100%;
    position: fixed;
    width: 100%;
    overflow: scroll;
}

.more-info-image {
    max-width: 100%;
    margin-bottom: 15px;
}

.more-info {
    font-size: 16px;
    font-weight: 700;
    color: #102e9e;
    font-family: 'CFA-Bold';
    white-space: pre;
}

.text-light-description {
    font-size: 25px;
    color: #253e92;
    font-family: 'CFA-light';
}

.title-bold {
    font-size: 40px;
    line-height: 42px;
    color: #102e9e;
    font-family: 'CFA-Bold';
}

.main-container {
    margin-top: 25px;
}

.back-button {
    width: 350px;
    cursor: pointer;
}

@media screen and (max-width: 1600px) {
    .text-light-description {
        font-size: 16px;
    }
}

@media screen and (min-width: 1199px) {
    .main-container {
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media screen and (max-width: 990px) {
    .back-button {
        width: 218px;
    }
}
</style>
