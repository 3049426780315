import restAPI from '@/utils/rest-api'

export default {
    fetchGamePool() {
        return restAPI.get('/games/available')
    },
    fetchGameById(gameId) {
        return restAPI.get('/games/' + gameId + '/with-customer-data')
    },
    fetchDefaultGame() {
        return restAPI.get('/games/default')
    },
    startGame(gameId) {
        return restAPI.post('/games/' + gameId + '/start')
    },
    saveGame(gameId, points, finished, type) {
        return restAPI.post('/games/' + gameId + '/save', { points, finished, type })
    },
    fetchGameHistory() {
        return restAPI.get('/games/history')
    },
    fetchGameLeaderBoard() {
        return restAPI.get('/games/leaderboard')
    },
    gameWebViewed(gameId, channel, campaignId) {
        return restAPI.post('/games/web-viewed', { gameId, channel, campaignId })
    }
}
