
export default {
    state: {
        isEmployeeConsentModalVisible: false
    },
    mutations: {
        setConstentEmployeeModalVisibility(state, isEmployeeConsentModalVisible) {
            state.isEmployeeConsentModalVisible = isEmployeeConsentModalVisible
        }
    },
    actions: {
        showEmployeeConsentModal({ commit }) {
            commit('setConstentEmployeeModalVisibility', true)
        },
        hideEmployeeConsentModal({ commit }) {
            commit('setConstentEmployeeModalVisibility', false)
        }
    }
}
