<template>
    <b-container v-if="isLoggedIn" fluid class="background-image">
        <affinity-top-bar />
        <b-col class="header-text-simple mt-5">
            <div style="max-width: 700px;"> {{ $t('inactiveAffinityOffer.inactiveOffer') }}</div>
        </b-col>
    </b-container>
</template>

<script>
    import AffinityTopBar from '@/components/AffinityTopBar'

    export default {
        name: 'InactiveAffinityOfferRoute',
        components: {
            AffinityTopBar
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isUserLoggedIn
            }
        }
    }
</script>

<style scoped>
.background-image {
    background: url("~@/assets/images/affinity_bg.jpg");
    background-repeat: repeat;
    height: 100%;
    position: fixed;
    width: 100%;
    overflow: auto;
}

.header-text-simple {
    font-size: clamp(20px, 5vmin, 50px);
    font-family: 'CFA-light';
    color: #0d5198;
    display: flex;
    justify-content: center;
    text-align: center;
}
</style>
