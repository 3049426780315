import restAPI from '@/utils/rest-api'

export default {
    getConsent() {
        return restAPI.get('/consents')
    },
    postConsent(consentWay, hasConsent) {
        return restAPI.post('/consents', {
            consentWay,
            hasConsent
        })
    }
}
