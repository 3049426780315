<template>
    <b-container>
        <div
            class="combo-wrapper"
            :class="id+'-combo-wrapper'"
        >
            <b-row>
                <b-col
                    v-for="(comboProduct, index) in comboProducts"
                    :key="comboProduct.id"
                >
                    <b-form>
                        <input
                            :id="id+index"
                            v-model="form.selectedTopCombo"
                            type="radio"
                            :value="comboProduct"
                            @change="emitEventToParent"
                        >
                        <label
                            :for="id+index"
                            class="products pointer"
                            :class="{
                                'top-row-bg': introModalpreCheckTop(index),
                                'bottom-row-bg': introModalpreCheckBottom(index)
                            }"
                        >
                            <b-container class="products-wrapper">
                                <img
                                    :src="calculatePath(comboProduct)"
                                    alt=""
                                >
                                <font-awesome-icon
                                    icon="check-circle"
                                    class="fa-fw check-icon"
                                />
                            </b-container>
                        </label>
                    </b-form>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
    import config from '@/mixins/config'
    export default {
        mixins: [config],
        props: {
            comboProducts: {
                type: Array,
                default: null
            },
            id: {
                type: String,
                default: null
            },
            preChecked: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                form: {
                    selectedTopCombo: null
                }
            }
        },
        methods: {
            calculatePath(comboProduct) {
                return this.config.assetsHost + '/' + comboProduct.productImage.relativePath + '/' + comboProduct.productImage.fileName
            },
            emitEventToParent() {
                this.$emit('update', this.form.selectedTopCombo)
            },
            introModalpreCheckTop(index) {
                if (this.preChecked != null) {
                    if (this.id === 'top' && this.comboProducts[index].id === this.preChecked.firstComboProduct.id) {
                        return true
                    }
                }
            },
            introModalpreCheckBottom(index) {
                if (this.preChecked != null) {
                    if (this.id === 'bottom' && this.comboProducts[index].id === this.preChecked.secondComboProduct.id) {
                        return true
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .combo-wrapper input {
        display: none;
    }

    .combo-wrapper img {
        width: 100%;
    }

    .products {
        width: 100%;
        border-radius: 5px;
        box-shadow: 0.5px 0.866px 8px rgba(58, 58, 58, 0.23);
        background-color: #fff;
    }

    .check-icon {
        position: absolute;
        color: #fff;
        opacity: 0;
        width: 35%;
        bottom: 20%;
        left: 15%;
        font-size: 2rem;
    }

    .bottom-combo-wrapper :checked + label .check-icon,
    .top-combo-wrapper :checked + label .check-icon {
        opacity: 1;
    }

    .combo-wrapper .products-wrapper {
        padding: 0.5rem;
    }

    .combo-wrapper .col {
        padding: 0;
    }

    .top-combo-wrapper :checked + label,
    .top-row-bg {
        transition: all 0.3s ease-in-out;
        background-color: #7c5cb4;
    }

    .bottom-combo-wrapper :checked + label,
    .bottom-row-bg {
        transition: all 0.3s ease-in-out;
        background-color: #f9855a;
    }

    @media screen and (min-width: 992px) {
        .check-icon {
            font-size: 3rem;
        }
    }

</style>
