import userService from '@/services/user-service'
export default {
    state: {
        adminToken: localStorage.getItem('affinity-jwt-admin-token'),
        adminUser: null
    },
    mutations: {
        setAdminUser(state, adminUser) {
            state.adminUser = adminUser
        },
        setAdminToken(state, adminToken) {
            state.adminToken = adminToken
        }
    },
    actions: {
        adminLogin({ commit, dispatch }, response) {
            localStorage.setItem('affinity-jwt-admin-token', response.token)
            commit('setAdminToken', response.token)
            dispatch('refreshCurrentAdminUser')
        },
        refreshCurrentAdminUser({ commit, dispatch }) {
            userService.fetchCurrentAdminUser().then((user) => {
                commit('setAdminUser', user)
            }).catch(() => {
                dispatch('adminLogout')
            })
        },
        adminLogout({ commit }) {
            localStorage.removeItem('affinity-jwt-admin-token')
            commit('setAdminUser', null)
            commit('setAdminToken', null)
        }
    }
}
