import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/user'
import adminUser from '@/store/modules/affinity-user'
import getters from './getters'
import gamePlayAgain from './modules/gamePlayAgain'
import consent from '@/store/modules/consent'
import employeeConsent from '@/store/modules/employee-consent'
import contest from '@/store/modules/contest'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        adminUser,
        gamePlayAgain,
        employeeConsent,
        consent,
        contest
    },
    getters
})
