<template>
    <div>
        <cancelled-card-view />
    </div>
</template>

<script>
    import CancelledCardView from '@/components/CancelledCardView'
    export default {
        name: 'CancelledCardRoute',
        components: { CancelledCardView }
    }
</script>
