<template>
    <div class="modal-body">
        <b-container class="p-0 container-modal">
            <div class="top-container">
                <button class="cancel-btn" @click="goBackOnInsertCode" />
                <div class="text-header">{{ $t('employeeAffinity.header') }}</div>
                <div class="text-header-simple">{{ $t('employeeAffinity.headerBottom') }}</div>
            </div>
            <b-row class="pt-5 center-container">
                <b-col order="2" order-md="1" sm="12" md="12" lg="8">
                    <div class="text-wrapper">
                        <div class="bold-text mb-4">{{ $t('employeeAffinity.firstText') }}</div>
                        <div class="simple-text mb-4">{{ $t('employeeAffinity.secondText') }}</div>
                    </div>
                </b-col>
                <b-col order="1" order-md="2" sm="12" md="12" lg="4" class="ab-plus-card">
                    <img class="card" src="@/assets/card-affinity.png" alt="">
                </b-col>
            </b-row>
            <b-row class="pb-4">
                <b-col sm="12" md="12" lg="8">
                    <div class="text-wrapper">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="status"
                            size="lg"
                            name="checkbox-1"
                            class="mb-4"
                        >
                            <span class="consent-text">{{ $t('employeeAffinity.consentText') }}</span>
                        </b-form-checkbox>
                    </div>
                    <div class="text-wrapper">
                        <button class="accept-btn" @click="closeConsent">{{ $t('employeeAffinity.buttonText') }}<img src="@/assets/icon_next.png" alt=" " draggable="false" class="ml-1"></button>
                    </div>

                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import employeeConsentService from '@/services/employee-consent-service'
    export default {
        name: 'EmployeeConsentModal',
        data() {
            return {
                status: false
            }
        },
        created() {
        },
        methods: {
            closeConsent() {
                employeeConsentService.submitEmployeeConsent(this.status).then(() => {
                    this.$store.dispatch('hideEmployeeConsentModal')
                })
            },
            goBackOnInsertCode() {
                this.$router.push(`/partners/${this.$route.params.affinityOfferId}`)
                this.$store.dispatch('hideEmployeeConsentModal')
            }
        }
    }
</script>

<style scoped>
@media screen and (max-width: 977px) {
    .custom-checkbox.b-custom-control-lg,
    .input-group-lg .custom-checkbox {
        font-size: 16px;
        line-height: 18px;
    }
}

</style>

<style lang="scss" scoped>

    .modal-body {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 0;
    }

    .container-modal {
        position: fixed;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        max-width: 1600px;
        width: 100%;
        border-radius: 8px;
        background-color: #e5f2fa;
        box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.2);
        padding: 0;
    }

    .text-header {
        padding-top: 10px;
        font-size: 60px;
        color: #fff;
        font-family: 'CFA-Bold';
    }

    .card {
        background-color: #e5f2fa;
        border: none;
        max-width: 100%;
    }

    .bold-text {
        font-size: 24px;
        line-height: 30px;
        color: #102e9e;
        font-weight: 700;
        font-family: 'CFA-Light';
    }

    .simple-text {
        font-size: 19px;
        line-height: 23px;
        color: #102e9e;
        font-weight: 600;
        font-family: 'CFA-Light';
    }

    .text-wrapper {
        padding: 0 50px 0 50px;
    }

    .ab-plus-card {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .consent-text {
        font-size: 24px;
        color: #102e9e;
        font-weight: 600;
        line-height: 30px;
        font-family: 'CFA-Light';
    }

    .text-header-simple {
        font-size: 34px;
        color: #fff;
        font-weight: 500;
        font-family: 'CFA-Light';
    }

    .top-container {
        position: relative;
        width: 100%;
        height: 170px;
        background: #102e9e;
        text-align: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .cancel-btn {
        background: transparent;
        background-repeat: no-repeat;
        border: none;
        background-image: url('~@/assets/concent_cancel.png');
        cursor: pointer;
        height: 90px;
        width: 90px;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .accept-btn {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-family: 'CFA-bold', sans-serif;
        width: 367px;
        height: 90px;
        background: #253e92;
        border-radius: 20px;
        font-size: 34px;
        border: none;
        color: white;
    }

    @media screen and (max-width: 1600px) {
        .container-modal {
            max-width: 95%;
            height: 95vh;
            overflow: scroll;
            overflow-x: hidden;
            justify-content: flex-start;
        }
    }

    @media screen and (max-width: 1023px) {
        .top-container {
            height: 150px;
        }

        .cancel-btn {
            background: transparent;
            background-repeat: no-repeat;
            border: none;
            background-image: url('~@/assets/button_cancel_responsive.png');
            cursor: pointer;
            height: 40px;
            width: 40px;
            position: relative;
            margin: 0% 0% 0% 85%;
        }

        .container-modal {
            max-width: 95%;
            height: 95vh;
            overflow: scroll;
            overflow-x: hidden;
            justify-content: flex-start;
        }

        .text-header {
            font-size: 38px;
            line-height: 40px;
        }

        .card {
            width: 170px;
            height: auto;
        }

        .bold-text {
            font-size: 20px;
            line-height: 24px;
        }

        .simple-text {
            font-size: 16px;
            line-height: 18px;
        }

        .text-wrapper {
            padding: 0 20px 0 20px;
        }

        .consent-text {
            font-size: 18px;
            line-height: 20px;
        }

        .text-header-simple {
            font-size: 24px;
        }

        .accept-btn {
            width: 335px;
            height: 90px;
            font-size: 30px;
        }

        .custom-control-label {
            line-height: 1;
        }
    }

    @media screen and (max-width: 576px) {
        .top-container {
            height: 260px;
        }

        .modal-body {
            text-align: center;
        }

        .text-header {
            font-size: 30px;
            line-height: 38px;
        }

        .accept-btn {
            width: 300px;
            height: 90px;
            font-size: 30px;
            margin: 0 auto;
        }
    }
</style>
