<template>
    <iframe
        id="gameIframe"
        title="Game Server Iframe"
        :src="iframeSrc"
        class="iframe-box"
        allow="fullscreen"
    />
</template>

<script>
    import config from '@/mixins/config'
    import gameService from '@/services/game-service'
    export default {
        name: 'GameRoute',
        components: {
        },
        mixins: [config],
        data() {
            return {
                game: null,
                locationPath: window.location.protocol + '//' + window.location.host
            }
        },
        computed: {
            iframeSrc() {
                if (this.game && this.game.engineVersion) {
                    return `${this.config.gameServerHost}/ab/old/games/${this.game.id}?engine=${this.game.engineVersion}&scale=1.0&nopoints=${this.game.played}&targetOrigin=${this.locationPath}&jwtToken=${this.jwtToken}`
                } else if (this.game) {
                    return `${this.config.gameServerHost}/ab/games/${this.game.id}?nopoints=${this.game.played}&targetOrigin=${this.locationPath}&apiHost=${this.config.host}&jwtToken=${this.jwtToken}`
                } else {
                    return null
                }
            },
            jwtToken() {
                return this.$store.getters.getJwtToken
            }
        },
        mounted() {
            const vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty('--vh', `${vh}px`)
            window.addEventListener('resize', () => {
                const vh = window.innerHeight * 0.01
                document.documentElement.style.setProperty('--vh', `${vh}px`)
            })
        },
        created() {
            gameService.fetchGameById(this.$route.params.gameId).then((game) => {
                this.game = game
                gameService.gameWebViewed(this.game.id, this.$route.params.channel, this.$route.params.campaign)
            })
            window.addEventListener('message', this.messageEventHandler)
        },
        beforeDestroy() {
            this.closeFrame()
            window.removeEventListener('message', this.messageEventHandler)
        },
        methods: {
            messageEventHandler(event) {
                if (!this.config.gameServerHost) {
                    return
                }
                const json = event.data
                if (!this.game) {
                    return
                }
                if (!this.game.played) {
                    if (json.action === 'finish') {
                        gameService.saveGame(this.$route.params.gameId, json.score, json.finished, json.type).then(() => {
                            if (json.finished) {
                                window.fbq('trackCustom', 'GamePlays')
                            }
                            this.$store.dispatch('refreshGameHistory')
                            this.$store.dispatch('refreshLeaderboard')
                        })
                    } else if (json.action === 'start') {
                        gameService.startGame(this.$route.params.gameId)
                    } else if (json.action === 'points') {
                        if (!this.game.pointsList.includes(json.score)) {
                            return
                        }
                        gameService.saveGame(this.$route.params.gameId, json.score, json.finished)
                    } else if (json.action === 'close') {
                        this.redirectToGameList()
                    }
                } else {
                    if (json.action === 'close') {
                        this.redirectToGameList()
                    }
                }
            },
            redirectToGameList() {
                this.$router.push('/games')
            },
            closeFrame() {
                const frame = document.getElementById('gameIframe')
                if (frame) {
                    frame.remove()
                }
            }
        }
    }
</script>
<style scoped>
.iframe-box {
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    z-index: 4;
    position: absolute;
}
</style>
