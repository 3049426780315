<template>
    <b-container v-if="isAvailableCosmoteOffer && isLoggedIn" fluid class="background-image">
        <affinity-top-bar :disable-home="false" />
        <div class="main-container">
            <div class="custom-margin text-responsive">
                <div class="text-bold-description mb-4">SUPERMARKET DEALS από την COSMOTE</div>
                <div class="text-light-description">Τα SUPERMARKET DEALS προσφέρουν αποκλειστικά στους συνδρομητές COSMOTE εκπτωτικά κουπόνια συνολικής αξίας έως 25€ για εξαργύρωση σε όλα τα καταστήματα AB Βασιλόπουλος.</div>
                <div class="text-light-description my-3">Έχεις εκπτωτικά κουπόνια από την COSMOTE;</div>
                <div class="text-light-description mb-3">Ενεργοποίησέ τα εδώ και πάρε αυτόματα έκπτωση στα προϊόντα που επέλεξες κατά το check out!</div>
                <button class="code-button" @click="goToInsertCode">
                    Έχω Κωδικό <img src="@/assets/icon_next.png" alt=" " draggable="false" class="ml-3">
                </button>
                <div class="my-4">
                    <button class="more-button" @click="goToInfo">
                        {{ $t('affinityOffer.more') }}
                    </button>
                </div>
            </div>
            <img src="@/assets/cosmote_more.png" class="side-image">
        </div>
    </b-container>
</template>

<script>
    import cosmoteOfferService from '@/services/cosmote-offer-service'
    import config from '@/mixins/config'
    import AffinityTopBar from '@/components/AffinityTopBar'

    export default {
        name: 'CosmoteOfferRoute',
        components: {
            AffinityTopBar
        },
        mixins: [config],
        data() {
            return {
                isAvailableCosmoteOffer: false
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isUserLoggedIn
            }
        },
        created() {
            cosmoteOfferService.fetchCosmoteAvailableOffer().then(cosmoteOffers => {
                this.isAvailableCosmoteOffer = cosmoteOffers.content.length > 0
            })
        },
        methods: {
            goToInfo() {
                this.$router.push({ name: 'Cosmote More Informations' })
            },
            goToInsertCode() {
                this.$router.push({ name: 'Cosmote Offer - Insert Code' })
            }
        }
    }
</script>

<style scoped lang="scss">
.background-image {
    background: url("~@/assets/images/affinity_bg.jpg");
    background-repeat: repeat;
    height: 100%;
    position: fixed;
    width: 100%;
    overflow: auto;
}

.side-image {
    max-width: 100%;
}

.text-bold-description {
    font-size: 40px;
    line-height: 42px;
    color: #102e9e;
    font-family: 'CFA-Bold';
}

.text-light-description {
    font-size: 25px;
    color: #253e92;
    font-family: 'CFA-light';
}

.code-button {
    background: #253e92;
    color: white;
    font-family: 'CFA-Bold';
    width: 338px;
    max-height: 90px;
    border: none;
    border-radius: 20px;
    font-size: 26px;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.bold-text {
    font-weight: bold;
}

.more-button {
    background: transparent;
    color: #253e92;
    font-family: 'CFA-Bold';
    width: 338px;
    max-height: 90px;
    border-radius: 20px;
    text-align: left;
    border: 1px solid #253e92;
    font-size: 26px;
    padding: 25px;
    z-index: 1;
}

.main-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.custom-margin {
    max-width: 990px;
}

@media screen and (max-width: 1600px) {
    .main-container {
        flex-wrap: wrap;
    }

    .button-container {
        text-align: center;
    }

    .code-button {
        width: 338px;
    }

    .custom-margin {
        margin: 0 10%;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .text-light-description {
        font-size: 25px;
    }
}

@media screen and (max-width: 776px) {
    .text-light-description {
        font-size: 20px;
    }

    .company-name {
        font-size: 32px;
        line-height: 34px;
    }
}

</style>
