import restAPI from '@/utils/rest-api'

export default {
    fetchAvailable() {
        return restAPI.get('/contests/available')
    },
    redeemParticipation(participationId) {
        return restAPI.post(`/contests/participations/${participationId}/redeem`)
    },
    getContestTerms(contestId) {
        return restAPI.get(`/contests/${contestId}/terms`)
    }
}
