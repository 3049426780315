<template>
    <div v-if="promoData" class="footer w-100 mb-4">
        <a target="_blank" :href="promoData.url">
            <img :src="imageUrl()" class="w-100" alt="">
        </a>
    </div>
</template>

<script>
    import config from '@/mixins/config'
    export default {
        mixins: [config],
        props: {
            promoData: { type: Object, default: null }
        },
        methods: {
            imageUrl() {
                return this.config.assetsHost + '/' + this.promoData.properties.imagePath
            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer {
        position: relative;
    }
</style>
