<template>
    <div>
        <top-bar />
        <b-container fluid class="background-image default-font-size responsive-game-scrollable-container">
            <div class="header-text pt-4 pb-3">
                {{ $t('editProfile.header') }}
            </div>
            <div class="edit-profile-container mt-5">
                <game-select-avatar />
                <div v-if="currentUser" class="info-container">
                    <div class="right-container">
                        <div class="step-header-text mb-2">
                            <div class="number-container">
                                <div class="step-number"> 2 </div>
                            </div>
                            <div class="responsive-text-info">{{ $t('editProfile.fillForm') }}</div>
                        </div>
                        <div class="mt-3">
                            <div>
                                <b-form-input
                                    v-model="currentUser.nickName"
                                    size="lg"
                                    :placeholder="$t('editProfile.avatarName')"
                                    class="input-box"
                                    :class="{wrong: !correctNickName}"
                                    :formatter="formatName"
                                />
                                <span v-show="!correctNickName" class="error-message">
                                    {{ $t('editProfile.onlyUpperGreek') }}
                                </span>
                            </div>
                        </div>
                        <div class="">
                            <div class="toggle-container pt-4">
                                <div class="pr-5">
                                    <span> {{ $t('editProfile.consentMessage') }} </span>
                                </div>
                                <div>
                                    <toggle-button
                                        v-model="currentUser.showOnLeaderboard"
                                        class="mt-1 toggle-button"
                                        :color="{ checked: '#253E92', unchecked: '#D6E2EE', disabled: '#CCCCCC' }"
                                        switch-color="#ffffff"
                                        :labels="{checked: 'ΝΑΙ', unchecked: 'ΟΧΙ'}"
                                        :font-size="15"
                                        :width="74"
                                        :height="32"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="submit-profile">
                            <button class="submit-button" :class="{ 'submit-button-active' : showSavingInfo}" @click="submitProfile()">
                                <div>{{ $t('editProfile.saveCredentials') }}</div>
                                <img v-if="showSavingInfo" src="@/assets/check-icon-white.png" alt="" class="check-icon">
                            </button>
                            <div class="refresh-text">
                                <i> {{ $t('editProfile.refresh') }}</i>
                            </div>
                        </div>
                        <div class="mt-1">
                            <b-alert v-model="showSavingInfo" variant="primary" class="saved-message">
                                <b-icon icon="check-circle" font-scale="2" />
                                <p class="mb-0">{{ $t('editProfile.saved') }}</p>
                            </b-alert>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>
    </div>
</template>

<script>
    import TopBar from '@/components/TopBar'
    import GameSelectAvatar from '@/components/games/GameSelectAvatar'
    import config from '@/mixins/config'
    import { ToggleButton } from 'vue-js-toggle-button'
    export default {
        name: 'EditProfileRoute',
        components: {
            TopBar,
            ToggleButton,
            GameSelectAvatar
        },
        mixins: [config],
        data() {
            return {
                avatars: [],
                correctNickName: true,
                showSavingInfo: false
            }
        },
        computed: {
            currentUser() {
                return this.$store.getters.getCurrentUser
            },
            allowProfileSubmission() {
                if (!this.mobile) {
                    return true
                }
                return this.mobileIsTenDigits(this.mobile) &&
                    this.phoneIsValid && !this.phoneInMoreThanFourCards
            }
        },
        methods: {
            submitProfile() {
                this.showSavingInfo = false
                this.$store.dispatch('updateUserProfile', this.currentUser).then(response => {
                    this.showSavingInfo = true
                    this.mobile = null
                    setTimeout(() => {
                        this.showSavingInfo = false
                    }, 5000)
                })
            },
            formatName(input) {
                const letters = /^[a-zA-Zα-ωΑ-Ω \s]+$|^$/
                if (!input.match(letters)) {
                    this.correctNickName = false
                    return this.currentUser.nickName
                }
                this.correctNickName = true
                return String(input).substring(0, 25).toUpperCase()
            }
        }
    }
</script>

<style>

.vue-js-switch .v-switch-core {
    border-radius: 8px !important;
}

.vue-js-switch .v-switch-core .v-switch-button {
    border-radius: 8px !important;
}

.input-box:disabled {
    color: #0d5198;
}

</style>

<style scoped lang="scss">

@media screen and (min-height: 768px) and (max-width: 1600px) {
    .background-image {
        position: fixed !important;
    }
}

@media screen and (min-height: 900px) and (min-width: 1601px) {
    .background-image {
        position: fixed !important;
    }
}

.outer-container {
    margin: 0 auto;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
    position: fixed;
    width: 100%;
}

.number-container {
    width: 30px;
}

.custom-font {
    font-size: clamp(10px, 2vw, 1px) !important;
}

.check-icon {
    min-width: 20px;
    min-height: 20px;
}

.responsive-game-scrollable-container {
    overflow-x: hidden;
    height: 100%;
    padding-bottom: 175px;
}

.background-image {
    background: repeat url('~@/assets/player_info_background.png');
    background-size: cover;
    height: 100%;
    padding-bottom: 11rem;
    position: relative;
}

.edit-profile-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.step-number {
    background: #ea1d24;
    border-radius: 50%;
    color: #fff;
    width: 25px;
    height: 25px;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    margin-left: 5px;
}

.step-header-text {
    display: flex;
    font-size: clamp(22px, 1.5vw, 28px);
    font-family: 'CFA-light';
    align-items: center;
    justify-content: flex-start;
    column-gap: 20px;
    font-weight: 700;
    color: #102e9e;
}

.input-box {
    background-color: #fff;
    border: none;
    color: #253e92;
    font-family: 'CFA-Bold';
    border-radius: 12px;
    height: clamp(45px, 6.5vmin, 70px);
    font-size: clamp(18px, 1.5vw, 35px);
    margin: 1rem 0;
}

.toggle-container {
    display: flex;
    justify-content: space-between;
    font-size: clamp(14px, 1.3vw, 22px);
    font-family: 'CFA-light';
    font-weight: 700;
    text-align: left;
    color: #253e92;
}

.right-container {
    padding: 0 10% 0 15%;
    border-left: 1px solid #2aabe2;
}

.submit-profile {
    display: flex;
    font-family: 'CFA-light';
    font-weight: 500;
    font-size: 20px;
    color: #0d2166;
    margin-top: 1.3rem;
    align-items: flex-start;
    flex-direction: column;
}

.submit-button {
    width: 66%;
    max-width: 350px;
    min-height: 90px;
    outline: none;
    border: 3px solid transparent;
    font-family: 'CFA-Light';
    font-weight: 700;
    border-radius: 13px;
    background:
        linear-gradient(to right, #e3edf6, #e3edf6),
        linear-gradient(to right, #019fe3, #236dfd);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    color: #0f2d9d;
    padding: 0.7rem 1rem;
    font-size: clamp(20px, 2vw, 36px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.submit-button-active {
    background: #276aff;
    width: 66%;
    max-width: 350px;
    min-height: 90px;
    outline: none;
    font-family: 'CFA-Light';
    font-weight: 700;
    border-radius: 13px;
    color: #fff;
    border: none;
    padding: 0.7rem 1rem;
    font-size: clamp(20px, 2vw, 36px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.submit-profile.disabled,
.submit-profile.disabled .submit-button {
    opacity: 0.3;
    color: grey;
}

.refresh-text {
    font-size: clamp(12px, 1.3vw, 18px);
    font-family: 'CFA-light';
    font-weight: 700;
    text-align: left;
    color: #253e92;
    margin-top: 1rem;
}

.saved-message {
    display: flex;
    align-items: center;
    font-size: clamp(12px, 1.3vw, 18px);
    font-family: 'CFA-light';
    font-weight: 700;
    gap: 2%;
}

.error-message {
    font-size: clamp(13px, 0.9vw, 25px);
    color: red;
    font-weight: 700;
    font-family: 'CFA-light';
}

.header-text {
    font-size: clamp(30px, 1.5vw + 1rem, 40px);
    color: #253e92;
}

.default-font-size {
    font-size: clamp(15px, 1.5vw, 25px);
    font-family: 'CFA-light';
    color: #005d81;
    text-align: center;
}

.phone-number-hint {
    font-family: 'CFA-light';
    color: #005d81;
    font-weight: 900;
    font-size: clamp(12px, 1.2vw, 14px);
}

.input-box.wrong {
    border: 4px solid red;
}

.input-box:focus {
    background-color: #0d5198;
    color: #fff;
}

.input-box::placeholder {
    color: #0d5198;
}

.input-box:focus::placeholder {
    color: #fff;
}

@media screen and (max-width: 1600px) {
    .header-text {
        display: none;
    }

    .responsive-text-info {
        text-align: left;
    }

    .step-header-text {
        justify-content: left;
        padding: 0;
    }

    .edit-profile-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .input-box {
        width: 100%;
    }

    .toggle-container {
        width: 100%;
    }

    .right-container {
        border: none;
        padding: 0;
        margin-top: 1.5rem;
    }

    .submit-profile {
        flex-direction: column;
        align-items: center;
    }

    .submit-button {
        width: 53%;
        justify-content: center;
        font-size: 20px;
        min-height: 52px;
    }

    .submit-button img {
        padding: 10px;
    }

    .refresh-text {
        margin: 0.5rem 0;
    }
}
</style>
