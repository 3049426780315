<template>
    <div class="background-image">
        <div>
            <top-bar />
            <div class="leaderboard-container">
                <div class="label">
                    <div class="ranking-label">{{ $t('gamesLeaderBoard.rankingLabel', {totalPlayers: leaderboard.totalRankedCustomers}) }}</div>
                    <div class="refresh">{{ $t('gamesLeaderBoard.refresh') }}</div>
                </div>
                <div class="leaderboard">
                    <div
                        v-for="(rank, index) in leaderboard.ranks"
                        :key="index"
                        class="players"
                    >
                        <div class="player-container">
                            <div v-if="index > 1 && leaderboard.ranks[index].position - leaderboard.ranks[index - 1].position > 1" class="more-icon">
                                <img src="@/assets/more_icon.png" alt="">
                            </div>
                            <div
                                :class="{'border-game-active-player': rank.customerId === currentUser.id }"
                                class="player-row"
                            >
                                <div class="player">
                                    <div class="position"><span class="hashtag">#</span><span>{{ rank.position }}</span></div>
                                    <img v-if="rank.customerId === currentUser.id" class="statue" src="@/assets/active_myplace_icon.png" alt="">
                                    <img v-else src="@/assets/leaderboard_active_statue_icon.png" class="statue" alt="">
                                    <div class="avatar-container">
                                        <img v-if="rank.avatarFileName" :src="config.assetsHost + '/' + rank.avatarPath + '/'+ rank.avatarFileName" class="avatar" alt="">
                                        <img v-else src="@/assets/avatar-placeholder.png" class="avatar" alt="">
                                        <div class="avatar-border" />
                                    </div>
                                    <div v-if="rank.nickName" class="nickname">
                                        <span
                                            v-if="rank.nickName.length > 4"
                                            :class="{'name-text-active': rank.customerId === currentUser.id }"
                                        >{{ rank.nickName.slice(0, 4) }}*** </span>
                                        <span
                                            v-else-if="rank.nickName.length <= 4"
                                            :class="{'name-text-active': rank.customerId === currentUser.id }"
                                        >{{ rank.nickName }}</span>
                                    </div>
                                    <div class="games">
                                        <div class="number-of-games"> {{ rank.gamesPlayed }} </div>
                                        <div v-if="rank.gamesPlayed > 1 ">{{ $t('gamesLeaderBoard.games')[0] }}</div>
                                        <div v-else>{{ $t('gamesLeaderBoard.games')[1] }}</div>
                                    </div>
                                </div>
                                <div class="results">
                                    <div class="points">
                                        <span class="number-of-points">
                                            {{ rank.sumOfPoints }}
                                        </span>
                                        <span class="points-word">
                                            ΠΟΝΤΟΙ
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-shadow" />
    </div>
</template>

<script>
    import TopBar from '@/components/TopBar'
    import config from '@/mixins/config'

    export default {
        name: 'GamesLeaderBoardRoute',
        components: {
            TopBar
        },
        mixins: [config],
        computed: {
            currentUser() {
                return this.$store.getters.getCurrentUser
            },
            leaderboard() {
                return this.$store.getters.getLeaderboard
            }
        },
        created() {
        },
        methods: {
        }
    }
</script>

<style scoped lang="scss">

.bottom-shadow {
    height: 90px;
    margin-bottom: 120px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.background-image {
    background: url('~@/assets/player_info_background.png');
    background-size: cover;
    height: 100%;
    padding-bottom: 11rem;
    position: fixed;
    width: 100%;
    overflow: scroll;
}

.leaderboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label {
    text-align: center;
    padding: 1rem 2rem;
}

.ranking-label {
    font-family: 'CFA-Bold';
    color: #253e92;
    font-size: 40px;
}

.refresh {
    font-family: 'CFA-Light';
    font-weight: 700;
    color: #253e92;
    font-size: 20px;
}

.leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    padding-bottom: 2.2rem;
}

.players,
.player-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.player-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    max-width: 1050px;
    min-width: 800px;
    height: 80px;
    background: #fff;
    border: 2px solid #2aabe2;
    border-radius: 10px;
    margin-bottom: 0.8rem;
    padding: 0.8rem 0.8rem 0.8rem 0.2rem;
}

.player,
.results {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.player {
    width: 100%;
}

.position {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    height: 40px;
    font-family: 'CFA-Bold';
    background: #2aabe2;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    margin-left: 0.5rem;
}

.hashtag {
    display: none;
}

.avatar-container {
    position: relative;
}

.statue {
    height: 30px;
}

.avatar {
    height: 60px;
}

.nickname {
    color: #253e92;
    font-size: 26px;
    font-family: 'CFA-Bold';
}

.results {
    font-family: 'CFA-Light';
    font-weight: 700;
}

.points {
    background: #2aabe2;
    color: #fff;
    font-size: clamp(22px, 1vw + 1rem, 32px);
    border-radius: 10px;
    padding: 7px;
    width: 250px;
    text-align: center;
}

.number-of-points {
    font-family: 'CFA-Bold';
}

.games {
    font-size: clamp(14px, 1vw + 0.5rem, 24px);
    color: #2aabe2;
    height: 68px;
    padding: 1.8rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 250px;
    border-left: 2px solid #2aabe2;
    align-self: flex-end;
    margin-left: auto;
}

.number-of-games {
    color: #253e92;
    font-family: 'CFA-Bold';
    font-size: 46px;
}

.border-game-active-player {
    background: #eb2127;
    border: #eb2127;
    width: 82%;
    max-width: 1180px;
    min-width: 900px;
    height: 90px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    margin: 1.6rem 0;

    .games {
        border-left: 2px solid #fff;
        padding: 2.3;
        width: 300px;
    }

    .games,
    .number-of-games {
        color: #fff;
    }

    .points {
        text-align: center;
        padding: 7px;
        font-size: clamp(32px, 1vw, 40px);
        background: #fff;
        color: #253e92;
        width: 320px;
    }

    .nickname {
        font-size: clamp(20px, 1vw, 30px);
    }

    .statue {
        height: 36px;
    }

    .avatar {
        height: 72px;
    }

    .avatar-border {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border: 6px solid #fff;
        border-radius: 130px;
    }

    .position {
        background: #fff;
        color: #eb2127;
    }
}

.name-text-active {
    color: #fff;
}

.more-icon {
    text-align: center;
    margin: 0.2rem 0 1rem 0;
}

.more-icon img {
    height: 18px;
}

// @media screen and (max-width: 1200px) {
//     .border-game-active-player {
//         width: 930px;

//         .points {
//             padding: 1.1rem 1.4rem;
//             width: 272px;
//         }
//     }
// }

@media screen and (max-width: 1600px) {
    .bottom-shadow {
        margin-bottom: 70px;
        height: 60px;
    }
}

@media (max-width: 1200px) {
    .ranking-label {
        font-family: 'CFA-Bold';
        color: #253e92;
        font-size: 22px;
    }

    .refresh {
        font-family: 'CFA-Light';
        font-weight: 700;
        color: #253e92;
        font-size: 14px;
    }

    .player-row {
        width: 90%;
        min-width: 0;
        max-width: 650px;
        height: 90px;
        padding: 0.4rem 0.7rem 0.4rem 0.4rem;
    }

    .position {
        background: none;
        color: #2aabe2;
        width: auto;
        border-radius: 0;
        display: flex;
        justify-content: flex-start;
        grid-area: 1/2/2/3;
        margin: 0;
        height: 30px;
    }

    .hashtag {
        display: block;
    }

    .statue {
        display: none;
    }

    .player {
        display: grid;
        gap: 0;
        grid-template-columns: 75px auto;
        grid-template-rows: auto auto auto;
    }

    .avatar-container {
        grid-area: 1/1/4/2;
    }

    .nickname {
        grid-area: 2/2/3/3;
        line-height: 20px;
        font-size: clamp(20px, 1vw, 26px);
    }

    .games {
        grid-area: 3/2/4/3;
        margin: 0;
        border: none;
        display: flex;
        align-items: center;
        padding: 0;
        height: auto;
        width: auto;
    }

    .number-of-games {
        font-family: 'CFA-Light';
        font-weight: 700;
        font-size: clamp(14px, 1vw, 20px);
    }

    .points-word {
        font-size: 14px;
        line-height: 14px;
    }

    .points {
        display: flex;
        flex-direction: column;
        line-height: 22px;
        align-items: center;
        justify-content: center;
        width: auto;
        padding: 0.6rem 1rem;
    }

    .avatar {
        height: 52px;
    }

    .border-game-active-player {
        width: 95%;
        max-width: 750px;

        .position {
            background: none;
            color: #fff;
        }

        .games {
            border-left: 0;
            width: auto;
        }

        .player {
            display: grid;
            column-gap: 15px;
            grid-template-columns: 70px auto;
            grid-template-rows: auto auto auto;
        }

        .points-word {
            font-size: 16px;
        }

        .points {
            display: flex;
            flex-direction: column;
            line-height: 18px;
            align-items: center;
            justify-content: center;
            width: auto;
            padding: 0.6rem 1rem;
        }

        .number-of-points {
            font-size: clamp(28px, 1vw, 40px);
            line-height: 26px;
        }

        .avatar {
            height: 52px;
        }

        .avatar-border {
            position: absolute;
            height: 100%;
            // width: 100%;
            top: 0;
            left: 0;
            border: 2px solid #fff;
            border-radius: 130px;
            width: 52px;
        }
    }
}

@media screen and (max-width: 480px) {
    .player-row {
        height: 80px;
        width: 85%;
    }

    .points {
        padding: 0.7rem 1.3rem;
    }

    .player {
        grid-template-columns: 70px auto;
    }

    .border-game-active-player {
        width: 90%;
        height: 90px;

        .points {
            padding: 1rem 1.5rem;
        }
    }

    .more-icon img {
        height: 12px;
    }
}
</style>
