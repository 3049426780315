const getters = {
    isUserLoggedIn: state => !!state.user.jwtToken,
    getCurrentUser: state => state.user.user,
    isAdminUserLoggedIn: state => !!state.adminUser.adminToken,
    getCurrentAdminUser: state => state.adminUser.adminUser,
    getGameHistory: state => state.user.gameHistory,
    getLeaderboard: state => state.user.leaderboard,
    getAbPlusNumber: state => state.user.abPlusNumber,
    getPlayAgainModal: state => state.gamePlayAgain.playAgainModal,
    getPlayAgainGame: state => state.gamePlayAgain.game,
    getNoOverflow: state => state.gamePlayAgain.noOverflow,
    isConsentModalVisible: state => state.consent.consentModalVisible,
    isEmployeeConsentModalVisible: state => state.employeeConsent.isEmployeeConsentModalVisible,
    getConsentDetails: state => state.consent.consent,
    getRefreshToken: state => state.user.refreshToken,
    getJwtToken: state => state.user.jwtToken,
    getContest: state => state.contest.contest
}

export default getters
