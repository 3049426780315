var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.contest)?_c('div',{staticClass:"redeem-container"},[_c('contest-top-bar',{staticClass:"top-bar"}),(!_vm.error)?_c('div',{staticClass:"content"},[(_vm.showPrizeAnimation)?_c('div',{staticClass:"redeem-animation gift-animation",style:({
                'background-image': ("url(" + (require('@/assets/images/gifs/Easter_animation_big_W_BG_HighQ.gif')) + _vm.animationQuery + ")")
            })}):_vm._e(),(_vm.loading)?_c('div',{staticClass:"redeem-animation loading-animation",style:({
                'background-image': ("url(" + (require('@/assets/images/gifs/Easter_animation_small_CLEAN.gif')) + _vm.animationQuery + ")")
            })}):_c('div',{staticClass:"result"},[_c('value-campaign-tile',{staticClass:"redeem-tile",class:{ full: _vm.showFullPrize }},[_c('div',{staticClass:"redeem-tile-container"},[_c('div',{staticClass:"prize",style:(_vm.prizeStyle)}),_c('div',{staticClass:"text"},[_c('div',{staticClass:"intro"},[_c('div',{staticClass:"checkout-date"},[_vm._v(_vm._s(_vm.$t('contestRedeem.transaction', { date: _vm.checkoutDate })))]),_c('div',[_c('div',{staticClass:"congrats"},[_vm._v(" "+_vm._s(_vm.$t(("contestRedeem." + (_vm.hasGift ? 'winPrize' : 'winNoPrize'))))+" ")]),(_vm.isGiftFreeProduct)?_c('div',{staticClass:"free-product"},[_vm._v(" "+_vm._s(_vm.$t('contestRedeem.freeProduct'))+" ")]):_vm._e()])]),_c('div',{staticClass:"prize-description"},[_vm._v(" "+_vm._s(_vm.giftDescription || _vm.$t('contestRedeem.bigGiftParticipation'))+" ")])])])]),_c('div',{staticClass:"next-participation"},[(_vm.showGiftInfo)?_c('div',[(_vm.remainingParticipations.length > 0)?_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                            _vm.$t('contestRedeem.remainingParticipations', {
                                count: _vm.remainingParticipations.length
                            })
                        ),expression:"\n                            $t('contestRedeem.remainingParticipations', {\n                                count: remainingParticipations.length\n                            })\n                        "}],staticClass:"remaining-participations"}):_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                            _vm.$t('contestRedeem.noRemainingParticipations')
                        ),expression:"\n                            $t('contestRedeem.noRemainingParticipations')\n                        "}],staticClass:"remaining-participations"})]):_vm._e(),(_vm.showGiftInfo)?_c('div',{staticClass:"next-participation-btn btn",class:{ disabled: _vm.remainingParticipations.length <= 0 },on:{"click":_vm.redeemNext}},[_c('span',[_vm._v(_vm._s(_vm.$t('contestRedeem.nextParticipation')))]),_c('font-awesome-icon',{staticClass:"btn-icon",attrs:{"icon":['fas', 'chevron-right']}})],1):_vm._e()]),(_vm.showGiftInfo)?_c('div',{staticClass:"disclaimer"},[_vm._v(" "+_vm._s(_vm.contest.winLoseDisclaimer)+" ")]):_vm._e()],1)]):_c('div',{staticClass:"error-message-container"},[_c('div',{staticClass:"mt-1 error-message-light"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('contestRedeem.error'))+" ")]),_c('div',{staticClass:"try-again"},[_vm._v(" "+_vm._s(_vm.$t("contestRedeem.tryAgain"))+" ")])]),_c('div',{staticClass:"align-center text-responsive"},[_c('div',{staticClass:"back-btn",on:{"click":_vm.goBack}},[_c('font-awesome-icon',{staticClass:"back-btn-icon",attrs:{"icon":['fas', 'chevron-left']}}),_c('span',{staticClass:"back-btn-text"},[_vm._v(_vm._s(_vm.$t('contestRedeem.back')))])],1)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }