import moment from 'moment'
import 'moment-timezone'
export default {
    getHumanizedDate(timestamp) {
        return moment.utc(timestamp).format('DD/MM/YYYY')
    },
    dateFormatter: (value, row, index) => {
        return value
            ? moment(value).tz('Europe/Athens').format('DD/MM/YYYY') : value
    },
    getPreviousDaySkipSundays() {
        // Based on the current day, handle accordingly
        let momentDate = null
        switch (moment().day()) {
        // If it is Monday (1) or Sunday (0), Get the previous Saturday (6)
        // and ensure we are on the previous week
        case 0:
        case 1:
            momentDate = moment().subtract(6, 'days').day(6)
            break
            // If it any other weekend, just return the previous day
        default:
            momentDate = moment().subtract(1, 'days')
            break
        }
        return momentDate.tz('Europe/Athens').format('DD/MM/YYYY')
    },
    getCurrentDate() {
        return moment.tz('Europe/Athens')
    }
}
