
export default class AppPrefix {
    route
    constructor(route) {
        this.route = route
    }

    getPrefix() {
        if (this.isVisitsSite()) {
            return 'visits'
        } else if (this.isGamesSite()) {
            return 'games'
        } else if (this.isCouponsSite()) {
            return 'coupons'
        } else if (this.isComboCouponsSite()) {
            return 'combo'
        } else if (this.isMapsSite()) {
            return 'maps'
        } else if (this.isOffersSite()) {
            return 'offers'
        } else if (this.isAffinityOffersSite()) {
            return 'partners'
        }
        return null
    }

    isGamesSite() {
        const routeLowerCase = this.route.path.toLowerCase()
        return routeLowerCase.startsWith('/games') ||
           routeLowerCase.startsWith('/g/') ||
           this.route.path === '/' ||
           routeLowerCase.startsWith('/mobilegames') ||
           routeLowerCase.startsWith('/contests')
    }

    isGameAutoLogin() {
        return this.route.path.toLowerCase().startsWith('/g/')
    }

    isCouponsSite() {
        return this.route.path.toLowerCase().startsWith('/d/')
    }

    isComboCouponsSite() {
        const routeLowerCase = this.route.path.toLowerCase()
        return routeLowerCase.startsWith('/m/') || routeLowerCase.startsWith('/c/')
    }

    isVisitsSite() {
        return this.route.path.toLowerCase().startsWith('/visits')
    }

    isMapsSite() {
        return this.route.path.toLowerCase().startsWith('/maps')
    }

    isOffersSite() {
        return this.route.path.toLowerCase().startsWith('/offers')
    }

    isAffinityOffersSite() {
        return this.route.path.toLowerCase().startsWith('/partners')
    }
}
