<template>
    <b-container fluid="lg" class="mt-5">
        <b-row>
            <b-col>
                <b-card
                    header="Affinity partner Login"
                    class="text-center"
                >
                    <b-form @submit.prevent="onSubmit">
                        <b-form-group
                            class="text-left"
                            label="Username"
                            description="Insert username"
                        >
                            <b-form-input
                                v-model="username"
                                type="text"
                                placeholder="exp: ab@vasil.com"
                                required
                                :state="successfulLogin"
                            />
                            <b-form-invalid-feedback :state="successfulLogin">
                                Either username or password are invalid
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            class="text-left"
                            label="Password"
                            description="Insert password"
                        >
                            <b-form-input
                                v-model="password"
                                type="password"
                                placeholder="exp: my_secret_password"
                                required
                                :state="successfulLogin"
                            />
                            <b-form-invalid-feedback :state="successfulLogin">
                                Either username or password are invalid
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-button type="submit" variant="primary" class="mt-3">Submit</b-button>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import config from '@/mixins/config'
    import userService from '@/services/user-service'

    export default {
        name: 'AffinityPartnerLogin',
        components: {
        },
        mixins: [config],
        data() {
            return {
                username: '',
                password: '',
                successfulLogin: null
            }
        },
        computed: {
            currentAdminUser() {
                return this.$store.getters.getCurrentAdminUser
            }
        },
        methods: {
            onSubmit() {
                userService.adminLogin(this.username, this.password)
                    .then(resp => {
                        this.$store.dispatch('adminLogin', resp)
                        this.successfulLogin = true
                    })
                    .then(() => { this.$router.push('/affinity-partner') })
                    .catch(() => {
                        this.successfulLogin = false
                    })
            }
        }
    }
</script>
