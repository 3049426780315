export default {
    methods: {
        mobileIsTenDigits(mobile) {
            return mobile && /^\d{10}$/.test(mobile)
        },
        allowNumbersKeyPressUpToTenLength(event, mobile) {
            const isNumber = /^[0-9]$/i.test(event.key)
            if (!isNumber || (mobile && mobile.length === 10)) {
                event.preventDefault()
            }
        },
        phoneNumberFormatter(input) {
            return String(input).substring(0, 10)
        }
    }
}
