<template>
    <b-container>
        <b-modal
            id="barcode-modal"
            v-model="modalShow"
            :hide-header="true"
            :hide-footer="true"
            centered
            no-close-on-esc
            no-close-on-backdrop
        >
            <b-container>
                <vue-barcode
                    class="text-center"
                    :value="coupon.barcodeNumber"
                    format="EAN13"
                    :width="2"
                    :options="{
                        font: 'PFHandbookProMedium'
                    }"
                />
            </b-container>
            <b-container>
                <div class="instructions">
                    <span class="d-block">{{ $t('carouselFlip.couponForCashIn') }}</span>
                    <span class="d-block">{{ $t('carouselFlip.eachCouponOneTime') }}</span>
                </div>
            </b-container>
            <combo-results
                :coupon="coupon"
                :show-modal="modalShow"
                class="w-100"
            />
        </b-modal>
    </b-container>
</template>

<script>
    import VueBarcode from 'vue-barcode'
    import config from '@/mixins/config'
    import ComboResults from '@/components/combo/ComboResults'

    export default {
        components: { ComboResults, VueBarcode },
        mixins: [config],
        props: {
            offerId: {
                type: Number,
                default: 0
            },
            coupon: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                modalShow: true
            }
        },
        computed: {
            couponImgPath() {
                if (!this.coupon) {
                    return ''
                }
                return `${this.config.assetsHost}/${this.coupon.digitalImage.relativePath}/${this.coupon.digitalImage.fileName}`
            }
        }
    }
</script>

<style lang="scss" scoped>
    #barcode-modal .modal-dialog.modal-md {
        max-width: 900px;
    }

    div#barcode-modal___BV_modal_content_ {
        border-radius: 2rem;
    }

    .instructions {
        text-align: center;
        font-weight: 700;
        font-size: 100%;
        font-family: PFHandbookProMedium, sans-serif;
    }
</style>
