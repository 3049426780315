<template>
    <div class="background w-100">
        <div class="panel-container">
            <coupon
                v-if="offer && offer.id"
                :offer-data="offer"
            />
        </div>
    </div>
</template>

<script>
    import offerService from '@/services/offer-service'
    import Coupon from './Coupon'

    export default {
        components: {
            Coupon
        },
        data() {
            return {
                offer: null,
                showOffer: null,
                expired: false
            }
        },
        computed: {
            currentUser() {
                return this.$store.getters.getCurrentUser
            }
        },
        watch: {
            currentUser: {
                handler(newVal, oldVal) {
                    const offerId = this.$route.params.offerId
                    const abPlusNumber = this.$route.params.abPlusNumber
                    if (JSON.stringify(newVal) !== JSON.stringify(oldVal) && abPlusNumber) {
                        this.fetchOffer(offerId)
                    }
                },
                immediate: true
            }
        },
        methods: {
            async fetchOffer(offerId) {
                if (!this.currentUser) {
                    return
                }
                if (offerId) {
                    try {
                        this.offer = await offerService.fetchOfferByIdWithCustomerData(offerId)
                    } catch (error) {
                        this.$router.push('/expired-or-invalid')
                    }
                } else {
                    try {
                        this.offer = await offerService.fetchDefaultOffer()
                    } catch (error) {
                        this.$router.push('/expired-or-invalid')
                    }
                }
                if (this.offer.offerType !== 'STANDARD' || !this.offer.active) {
                    this.$router.push('/expired-or-invalid')
                }
                this.offer.coupons = offerService.getAugmentedDataCoupons(this.offer)
                const channelQuery = this.$route.query.channel ? `?channel=${this.$route.query.channel}` : ''
                offerService.webOfferViewed(this.offer.id, channelQuery)
            }
        }
    }
</script>

<style scoped>
    .background {
        height: 100vh;
    }

    .panel-container {
        margin-bottom: 20px;
        background-color: #fff;
        border: 1px solid transparent;
        border-radius: 4px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    }
</style>
