import jwtUtils from '@/utils/jwt-utils'
import store from '@/store'
import axios from 'axios'

const API_URL = `${process.env.VUE_APP_HOST_DOMAIN}${process.env.VUE_APP_BASE_API}`

export default {
    async getToken() {
        let apiToken = localStorage.getItem('web-app-jwt-token')
        if (apiToken == null) {
            return null
        }
        if (!jwtUtils.isValidFormat(apiToken) || jwtUtils.isJwtExpired(apiToken)) {
            store.dispatch('setJwtToken', null)
            apiToken = null
        }
        return apiToken
    },
    async refreshJwtUsingRefreshToken() {
        const apiToken = await this.getToken()
        if (apiToken == null) {
            return
        }
        if (!jwtUtils.jwtExpiresInSeconds(apiToken, 60)) {
            return
        }
        const refreshToken = localStorage.getItem('web-app-refresh-token')
        if (!refreshToken) {
            store.dispatch('setJwtToken', null)
            return
        }
        const headers = {
            'Content-Type': 'application/json; charset=utf-8'
        }

        axios({
            url: API_URL + '/users/refresh-token',
            data: { tokenValue: refreshToken },
            method: 'POST',
            headers: headers
        }).then(response => {
            store.dispatch('setJwtToken', response.data.token)
            store.dispatch('setRefreshToken', response.data.refreshToken)
            store.dispatch('setRefreshTokenExpiration', response.data.refreshTokenExpiration)
            return response.data.token
        }).catch((err) => {
            store.dispatch('logout')
            throw err
        })
    }
}
