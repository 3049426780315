<template>
    <div
        v-if="isLoggedIn && contest"
        class="background-image"
        :class="{ 'no-overflow': noOverflow }"
    >
        <div class="responsive-game-scrollable-container">
            <contest-top-bar />
            <div class="promo-container">
                <div class="participations">
                    <value-campaign-tile class="participation-tile">
                        <div class="participation-tile-container">
                            <div class="intro">{{ $t('promoView.tileIntroText') }}</div>
                            <div class="value">{{ nonRedeemedParticipations }}</div>
                            <div class="outro">{{ $t('promoView.tileOutroText') }}</div>
                        </div>
                    </value-campaign-tile>
                    <div class="btn" :class="{ disabled: nonRedeemedParticipations === 0 }" @click="navigateToRedeemRoute">
                        <span>{{ $t('promoView.buttonText') }}</span>
                        <font-awesome-icon class="btn-icon" :icon="['fas', 'chevron-right']" />
                    </div>
                </div>
                <div class="gifts-container">
                    <div v-dompurify-html="contest.description" />
                </div>
                <div class="more-info">
                    <div class="btn terms" @click="navigateToTerms">
                        {{ $t('promoView.termsButtonText') }}
                    </div>
                    <div v-dompurify-html="contest.disclaimer" class="disclaimer-text" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ValueCampaignTile from '@/components/contests/ValueCampaignTile.vue'
    import ContestTopBar from '@/components/contests/ContestTopBar.vue'

    export default {
        components: { ContestTopBar, ValueCampaignTile },
        props: {
            contestId: {
                type: Number,
                required: true
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isUserLoggedIn
            },
            noOverflow() {
                return this.$store.getters.getNoOverflow
            },
            contest() {
                return this.$store.getters.getContest
            },
            nonRedeemedParticipations() {
                return (this.contest?.participations ?? []).filter(participation => !participation.redeemed).length
            }
        },
        methods: {
            navigateToRedeemRoute() {
                this.$router.push({
                    name: 'AB Contest Redeem',
                    params: { contestId: this.contestId }
                })
            },
            navigateToTerms() {
                this.$router.push({
                    name: 'AB Contest Terms',
                    params: { contestId: this.contestId }
                })
            }
        }
    }
</script>

<style scoped>
.background-image {
    background: url('~@/assets/images/easter-contest-mobile-bg.png') no-repeat center center;
    background-size: cover;
    min-height: 100vh;
}

.promo-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    color: #253e92;
    padding: 1.2rem 1.2rem 8rem;
    align-items: center;
    height: 100%;
}

.participations {
    display: flex;
    flex-direction: column;
    gap: 0.6875rem;
    order: 1;
    align-items: center;
}

.participations .participation-tile {
    width: 21rem;
    height: 100%;
}

.participations .btn {
    display: flex;
    background-color: #253e92;
    color: white;
    justify-content: space-between;
    padding: 1.25rem;
    width: 23.25rem;
    font-family: CFA-Bold, sans-serif;
    font-size: 1.375rem;
    align-items: center;
    border-radius: 1.25rem;
    max-width: 95vw;
}

.participations .btn .btn-icon {
    width: 0.9225rem;
    height: 1.38375rem;
}

.gifts-container {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    order: 2;
    text-align: center;
}

.gifts-container ::v-deep .gifts-title {
    font-size: 1.5rem;
    font-family: CFA-Bold, sans-serif;
    text-align: center;
}

.gifts-container ::v-deep .gifts-description {
    font-size: 1.5rem;
    font-family: CFA-Light, sans-serif;
    text-align: center;
}

.gifts-container ::v-deep .gifts-description div {
    line-height: 1.25;
}

.gifts-container ::v-deep ul {
    text-align: start;
    font-size: 1.25rem;
    font-family: CFA-Light, sans-serif;
}

.gifts-container ::v-deep ul strong {
    font-family: CFA-Normal, sans-serif;
}

.more-info .disclaimer-text *,
.more-info .btn.terms {
    font-size: 1.25rem;
    font-family: CFA-Normal, sans-serif;
}

.more-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.6875rem;
    order: 3;
}

.more-info .btn.terms {
    border: 1px solid #253e92;
    color: #253e92;
    border-radius: 1.25rem;
    padding: 1.875rem;
    width: 18rem;
}

.more-info .disclaimer-text {
    text-align: center;
    flex-grow: 1;
}

.participation-tile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: 0 auto;
    max-width: 90%;
    font-family: CFA-Bold, sans-serif;
    text-align: center;
    height: 100%;
}

.intro,
.outro {
    font-size: 3.5rem;
}

.value {
    background-color: #b6eafc;
    font-size: 4rem;
    color: #253e92;
    width: 100%;
    border-radius: 1.25rem;
    padding: 2rem;
    font-family: CFA-Normal, sans-serif;
}

.btn.disabled {
    background-color: #efefef;
    color: #696969;
    border: 1px solid #efefef;
    cursor: not-allowed;
    pointer-events: none;
}

@media (min-width: 768px) {
    .background-image {
        background-image: url("~@/assets/images/easter-contest-desktop-bg.png");
    }

    .gifts-container ::v-deep .gifts-description div {
        line-height: 1.5;
    }
}

@media (min-width: 1200px) {
    .promo-container {
        flex-flow: column wrap;
        flex-direction: row;
        padding: 1.5rem 4rem;
    }

    .participations {
        gap: 4rem;
        order: 2;
        align-items: center;
    }

    .participations .participation-tile {
        width: 28rem;
    }

    .participations .btn {
        width: 30rem;
        font-size: 2.125rem;
    }

    .participations .btn .btn-icon {
        width: 1.77rem;
        height: 2.65rem;
    }

    .gifts-container {
        gap: 2.3125rem;
        order: 1;
        max-width: 40vw;
        text-align: start;
    }

    .gifts-container ::v-deep .gifts-title {
        text-align: start;
        font-size: 1.75rem;
    }

    .gifts-container ::v-deep .gifts-description {
        text-align: start;
        font-size: 1.75rem;
    }

    .gifts-container ::v-deep ul {
        padding: 0;
    }

    .gifts-container ::v-deep ul,
    .more-info .disclaimer-text,
    .more-info .btn.terms {
        font-size: 1.5rem;
    }

    .more-info {
        gap: 1.25rem;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .more-info .disclaimer-text {
        text-align: center;
    }
}

@media screen and (min-width: 1385px) {
    .participations .participation-tile {
        width: 33rem;
    }

    .participations .btn {
        width: 33rem;
        font-size: 2.125rem;
    }

    .gifts-container .info {
        font-size: 2.25rem;
    }
}

@media screen and (min-width: 1500px) {
    .gifts-container .info {
        font-size: 2.5rem;
    }
}
</style>
