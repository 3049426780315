<template>
    <div v-if="isLoggedIn" class="background-image" :class="{'no-overflow': noOverflow}">
        <div class="responsive-game-scrollable-container">
            <top-bar />
            <div class="games-banner">
                <img src="@/assets/ICON STARS.png" class="star-icon mt-n2 " alt="">
                <div>
                    {{ $t('gameTopBar.bannerText')[0] }}
                    <span class="banner-bold-text">{{ $t('gameTopBar.bannerText')[1] }}</span>
                    {{ $t('gameTopBar.bannerText')[2] }}
                </div>
            </div>
            <div v-if="games.length > 0 || !!contest" fluid class="header-text-simple">
                <div class="header-text-bold">{{ $t('gameList.mainText') }}</div>
            </div>
            <div v-if="showNoGamesMessage && !contest" class="header-text-simple mt-5">{{ $t('gameList.noGamesMessage') }}</div>
            <vueper-slides
                v-else
                :bullets="false"
                :fade="false"
                :infinite="false"
                class="no-shadow hidden-element"
                disable-arrows-on-edges
                :touchable="false"
            >
                <template v-slot:arrow-left class="hidden-element">
                    <img src="@/assets/back-button.png" alt="" class="arrow-icon-left ">
                </template>
                <template v-slot:arrow-right class="hidden-element">
                    <img src="@/assets/next_button.png" alt="" class="arrow-icon-right">
                </template>
                <vueper-slide
                    v-for="(bucket, index) in gameBucket"
                    :key="index"
                >
                    <template #content>
                        <div class="game-container">
                            <div
                                v-for="game in bucket"
                                v-show="game.title!=='Stress Game'"
                                :key="game.id"
                            >
                                <game-tile v-if="!game.isContestPromo" :game="game" class="mx-3" />
                                <contest-tile v-else :contest-promo="game" class="mx-3" />
                            </div>
                        </div>
                    </template>
                </vueper-slide>
            </vueper-slides>
            <div class="scrollable-container">
                <div v-if="contest" class="mb-4 d-flex justify-content-center responsive-element">
                    <contest-tile :contest-promo="contest" class="mx-3" />
                </div>
                <div v-for="game in games" :key="game.id" class="mb-4 d-flex justify-content-center responsive-element">
                    <game-tile :game="game" class="mx-3" />
                </div>
            </div>
        </div>
        <div class="bottom-shadow" />
    </div>
</template>

<script>
    import TopBar from '@/components/TopBar'
    import gameService from '@/services/game-service'
    import config from '@/mixins/config'
    import GameTile from '@/components/GameTile'
    import {
        VueperSlides,
        VueperSlide
    } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'
    import moment from 'moment-timezone'
    import ContestTile from '@/components/contests/ContestTile.vue'

    export default {
        name: 'GameListRoute',
        components: {
            ContestTile,
            TopBar,
            VueperSlides,
            VueperSlide,
            GameTile
        },
        mixins: [config],
        data() {
            return {
                key: 1,
                games: [],
                gameBucket: [],
                bonus: {},
                showNoGamesMessage: null,
                gameWithNoPoints: null
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isUserLoggedIn
            },
            currentUser() {
                return this.$store.getters.getCurrentUser
            },
            noOverflow() {
                return this.$store.getters.getNoOverflow
            },
            contest() {
                return this.$store.getters.getContest
            }
        },
        watch: {
            isLoggedIn: {
                handler() {
                    this.loadGamesIfLoggedIn()
                },
                immediate: true
            }
        },
        async created() {
            this.gameDeepLinkOrFallBackToAllGames()
            this.loadGamesIfLoggedIn()
        },
        methods: {
            async gameDeepLinkOrFallBackToAllGames() {
                const abPlusNumber = this.$route.params.abPlusNumber
                const channelQuery = this.$route.query.channel ? this.$route.query.channel : null
                const campaignIdQuery = this.$route.query.campaignId ? this.$route.query.campaignId : null

                gameService.gameWebViewed(null, channelQuery, campaignIdQuery)

                if (abPlusNumber) {
                    let game = null
                    if (this.$route.params.gameId === 'pool') {
                        return
                    } else if (this.$route.params.gameId) {
                        game = await gameService.fetchGameById(this.$route.params.gameId)
                    } else {
                        game = await gameService.fetchDefaultGame()
                    }
                    if (game && game.active) {
                        // this.$store.dispatch('hideConsentModal')
                        this.clickGame(game, channelQuery, campaignIdQuery)
                    }
                }
            },
            loadGamesIfLoggedIn() {
                if (!this.isLoggedIn) {
                    return
                }
                this.loadGames()
            },
            loadGames() {
                return gameService.fetchGamePool()
                    .then(gamePoolRes => {
                        this.games = gamePoolRes.content
                        if (this.games.length === 0 && !this.contest) {
                            this.showNoGamesMessage = true
                        }
                        this.preprocessGames()
                    })
            },
            preprocessGames() {
                let index = 1
                let bucket = []
                this.gameBucket = []
                if (this.contest) {
                    bucket.push({ ...this.contest, isContestPromo: true })
                }
                for (const game of this.games) {
                    bucket.push(game)
                    if (index % 3 === 0) {
                        this.gameBucket.push(bucket)
                        bucket = []
                    }
                    index++
                }
                if (bucket.length > 0) {
                    this.gameBucket.push(bucket)
                }
            },
            clickGame(game, channel, campaign) {
                if (!game.played) {
                    this.$router.push({ name: 'AB Game', params: { gameId: game.id, channel: channel, campaign: campaign } })
                } else {
                    this.gameWithNoPoints = game
                    this.noOverflow = true
                    this.$store.dispatch('showPlayAgainModal', this.gameWithNoPoints)
                }
            },
            calcPointsToBeCredited() {
                return gameService.calcPointsToBeCredited(this.games, this.bonus)
            },
            dateFormatter: (value, row, index) => {
                return value
                    ? moment(value).tz('Europe/Athens').format('DD-MM-YYYY') : value
            }
        }
    }
</script>

<style>
    @media screen and (max-width: 1200px) {
        .vueperslide {
            display: none;
        }

        .hidden-element {
            display: none !important;
        }
    }
</style>

<style scoped lang="scss">

@media screen and (min-height: 768px) and (min-width: 1601px) {
    .background-image {
        position: fixed !important;
    }
}

.bottom-shadow {
    height: 90px;
    margin-bottom: 120px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.background-image {
    height: 100%;
    background: url('~@/assets/games_home_background.png') no-repeat center center;
    background-size: cover;
    position: relative;
    width: 100%;
}

.game-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    align-items: center;
}

.arrow-icon-left {
    margin-left: -14px;
    animation: blinker 2s infinite;
    margin-bottom: 185px;
}

.arrow-icon-right {
    margin-right: -14px;
    animation: blinker 2s infinite;
    margin-bottom: 185px;
}

.vueperslide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 470px;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.responsive-element {
    display: none !important;
}

.header-text-bold {
    font-family: 'CFA-Bold', sans-serif;
    font-size: clamp(1.25rem, 1vw + 1rem, 2.1875rem);
    color: #102e9e;
}

.header-text-simple {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    font-size: clamp(1.25rem, 1vw + 1rem, 2.1875rem);
    font-family: 'CFA-Light', sans-serif;
    color: #102e9e;
    line-height: 22px;
}

.no-overflow {
    overflow: hidden;
}

.games-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 75px;
    font-size: 26px;
    font-family: 'CFA-Light', sans-serif;
    font-weight: 700;
    line-height: 29px;
    background-color: #102e9e;
    color: #fff;
}

.banner-bold-text {
    color: #fff;
    font-family: 'CFA-Bold', sans-serif;
    font-size: 26px;
}

.star-icon {
    margin-right: 28px;
}

@media screen and (max-width: 1600px) {
    .star-icon {
        margin: 13px 0 !important;
        width: 63px;
    }

    .background-image {
        background: url('~@/assets/pattern-bg.jpg');
        background-repeat: repeat;
        height: 100%;
        position: fixed;
        width: 100%;
        overflow: scroll;
        background-size: cover;
    }

    .games-banner {
        font-size: 15px;
        flex-direction: column;
        text-align: center;
        padding-bottom: 20px;
        line-height: 18px;
    }

    .banner-bold-text {
        font-size: 15px;
    }

    .bottom-shadow {
        height: 60px;
        margin-bottom: 70px;
    }

    .hidden-element {
        display: none !important;
    }

    .responsive-element {
        display: flex !important;
    }

    .scrollable-container {
        padding-bottom: 120px;
    }

    .header-text-simple {
        margin: 20px 0 !important;
        flex-direction: column;
        margin-bottom: 10px;
        text-align: center;
        line-height: 1.1;
    }
}
</style>
