<template>
    <b-container fluid class="pb-2 background-image">
        <affinity-top-bar :show-home="!offer.hidden" :disable-home="false" />
        <b-col class="header-text text-center p-3">
            <div class="instruction-text">
                {{ $t('affinityCouponList.topText')[0] }}
                <span>{{ $t('affinityCouponList.topText')[1] }}</span>
                <span class="simple-instruction-text">{{ $t('affinityCouponList.topText')[2] }}</span>
            </div>
        </b-col>
        <b-row v-if="redemptionLimit!=0" class="justify-content-center text-center my-3 mx-2">
            <div class="coupon-number-text" :class="{'warning-color':couponsToActivate.length>=remainingCoupons}">
                {{ $t('affinityCouponList.couponLimit')[0] }} <span class="text-bold">{{ remainingCoupons }} {{ $t('affinityCouponList.couponLimit')[1] }}</span>
            </div>
        </b-row>
        <b-row v-for="(bucket, index) in couponBuckets" :key="index" cols="3" class="justify-content-center" align-v="center">
            <b-col
                v-for="coupon in bucket"
                :key="coupon.id"
                cols="auto"
                class="m-3 p-0 text-center"
            >
                <div class="background-card" :class="{pointer: !coupon.redemptionDate && (remainingCoupons > couponsToActivate.length || coupon.selected) }">
                    <div class="image-redeemed">
                        <img
                            :src="config.assetsHost + '/' + coupon.image.relativePath + '/' + coupon.image.fileName"
                            class="coupon-image"
                            :class="[{clicked: coupon.selected},{'opacity-changer': coupon.cashRedemptionDate}]"
                            alt=""
                            draggable="false"
                            @click="clickCoupon(coupon)"
                        >
                        <div v-if="coupon.cashRedemptionDate && coupon.cashRedemptionKiosk" class="coupon-redeemed">
                            <p class="header-shop-text">{{ $t('webCoupons.cashRedemption') }}</p>
                            <img v-if="coupon.cashRedemptionKiosk && coupon.cashRedemptionKiosk.storeCode === 686" src="@/assets/eshop.png" alt="">
                            <img v-else src="@/assets/shop.png" alt="" draggable="false">
                            <p v-if="!coupon.cashRedemptionKiosk" class="header-shop-text">{{ $t('webCoupons.fanchise') }}</p>
                            <p v-if="coupon.cashRedemptionKiosk.storeCode !== 686" class="header-shop-text">{{ $t('webCoupons.shop') }}</p>
                            <p v-else class="header-shop-text">{{ $t('webCoupons.eshop') }}</p>
                            <div v-if="coupon.cashRedemptionKiosk.storeCode === 686" class="simple-shop-text">
                                <div>{{ $t('webCoupons.eshopText')[0] }}</div>
                                <div>{{ $t('webCoupons.eshopText')[1] }}</div>
                            </div>
                            <div v-else>
                                <div class="simple-shop-text" :style="{visibility: coupon.cashRedemptionKiosk.storeCode !== 686 ? 'visible' : 'hidden'}">{{ coupon.cashRedemptionKiosk.address }}</div>
                                <div class="simple-shop-text" :style="{visibility: coupon.cashRedemptionKiosk.storeCode !== 686 ? 'visible' : 'hidden'}">{{ coupon.cashRedemptionKiosk.region }}</div>
                            </div>
                        </div>
                        <div v-if="!coupon.cashRedemptionKiosk && coupon.cashRedemptionDate" class="coupon-redeemed">
                            <p class="header-shop-text mt-n5">{{ $t('webCoupons.cashRedemption') }}</p>
                            <img src="@/assets/shop.png" alt="">
                            <p v-if="!coupon.cashRedemptionKiosk" class="header-shop-text">{{ $t('webCoupons.franchise') }}</p>
                        </div>
                    </div>
                    <div v-if="!coupon.cashRedemptionDate" class="text-center" :class="[{'layer-select':coupon.selected},{'layer-select-wrong':couponsToActivate.length>=remainingCoupons && !coupon.selected && !isLoadedOnCard(coupon) && redemptionLimit!=0} ]" @click="clickCoupon(coupon)">
                        <div v-if="isLoadedOnCard(coupon) && !coupon.selected" class="selection-active-banner">
                            <div class="flex-container"><img src="@/assets/check-circle-active.png" alt="" class="mr-2">{{ $t('couponList.activeCouponText') }}</div>
                            {{ dateTimeUtils.dateFormatter(coupon.redemptionDate) }}
                        </div>
                        <div v-if="coupon.selected" class="check-icon-position"><img class="check-icon" src="@/assets/check_icon.png" alt="" draggable="false"></div>
                        <span v-if="coupon.selected && !isLoadedOnCard(coupon)" class="helper" /><img v-if="coupon.selected && !isLoadedOnCard(coupon)" class="coupon-selected" src="@/assets/circles.png" alt="" draggable="false">
                        <div v-if="coupon.selected && !isLoadedOnCard(coupon)" class="text-on-coupon">
                            <div>{{ $t('affinityCouponList.nonActiveCoupon')[0] }}</div>
                            {{ $t('affinityCouponList.nonActiveCoupon')[1] }}
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <div class="help-container">
            <b-button class="activate-button" :class="{'grey-button':disabledButton}" :disabled="disabledButton" @click="activateCoupon">
                {{ $t('affinityCouponList.activateCoupons') }}
            </b-button>
        </div>
        <div class="help-container">
            <div class="info-text">
                {{ $t('affinityCouponList.infoMessage')[1] }} <span class="bold-text">{{ $t('affinityCouponList.infoMessage')[2] }}</span> {{ $t('affinityCouponList.infoMessage')[3] }}
            </div>
        </div>
    </b-container>
</template>

<script>
    import pageViewsService from '@/services/page-views-service'
    import config from '@/mixins/config'
    import affinityOfferService from '@/services/affinity-offer-service.js'
    import employeeConsentService from '@/services/employee-consent-service'
    import dateTimeUtils from '@/utils/date-time-utils'
    import AffinityTopBar from '@/components/AffinityTopBar'

    export default {
        name: 'AffinityCouponsListRoute',
        components: {
            AffinityTopBar
        },
        mixins: [config],
        data() {
            return {
                offer: {},
                couponBuckets: [],
                couponsToActivate: [],
                dateTimeUtils: dateTimeUtils,
                redemptionLimit: 0,
                affinityOffer: null
            }
        },
        computed: {
            remainingCoupons() {
                let activatedCoupons = 0
                if (!this.offer.affinityCoupons) {
                    return 0
                }
                for (const coupon of this.offer.affinityCoupons) {
                    if (this.isLoadedOnCard(coupon)) {
                        activatedCoupons++
                    }
                }
                return this.redemptionLimit - activatedCoupons
            },
            disabledButton() {
                if (this.couponsToActivate.length === 0) {
                    return true
                }
                if (this.redemptionLimit === 0) {
                    return false
                }
                return false
            }
        },
        async created() {
            pageViewsService.submitPageView('AB PARTNERS COUPONS', this.$route.path, this.$route.params)
            await this.initCoupons()
            this.affinityOffer = await affinityOfferService.fetchAffinityOfferById(this.$route.params.affinityOfferId)
            if (this.affinityOffer.employee) {
                employeeConsentService.fetchEmployeeConsent().then(() => {
                })
                    .catch(() => {
                        this.$store.dispatch('showEmployeeConsentModal')
                    })
            }
        },
        methods: {
            preprocessCoupons() {
                let index = 1
                let bucket = []
                this.couponBuckets = []
                for (const coupon of this.offer.affinityCoupons) {
                    bucket.push(coupon)
                    if (index % 8 === 0) {
                        this.couponBuckets.push(bucket)
                        bucket = []
                    }
                    index++
                }
                if (bucket.length > 0) {
                    this.couponBuckets.push(bucket)
                }
            },
            initCoupons() {
                affinityOfferService.fetchAffinityOfferCoupons(this.$route.params.code).then((offer) => {
                    this.offer = offer
                    this.preprocessCoupons()
                    this.redemptionLimit = offer.redemptionLimit
                    this.prepareAffinityOffer(this.offer)
                })
            },
            prepareAffinityOffer(offer) {
                offer.affinityCoupons = offer.affinityCoupons.sort(function(a, b) {
                    return a.position - b.position
                })

                let totalPoints = 0

                for (const coupon of offer.affinityCoupons) {
                    totalPoints += coupon.points
                }

                offer.totalPoints = totalPoints
            },
            clickCoupon(coupon) {
                if (!coupon.redemptionDate && (this.remainingCoupons > this.couponsToActivate.length || coupon.selected || this.redemptionLimit === 0)) {
                    this.toggleCouponSelection(coupon)
                    this.offer = { ...this.offer }
                }
            },
            toggleCouponSelection(coupon) {
                if (coupon.selected) {
                    coupon.selected = false
                    this.couponsToActivate = this.couponsToActivate.filter(c => c.promoId !== coupon.promoId)
                } else {
                    coupon.selected = true
                    this.couponsToActivate.push(coupon)
                }
            },
            isLoadedOnCard(coupon) {
                return coupon.redemptionDate
            },
            async activateCoupon() {
                const promoIdsToLoad = this.couponsToActivate.map(c => c.promoId)
                await affinityOfferService.redeemAffinityCoupons(this.offer.id, promoIdsToLoad, this.$route.params.code).then(res => {
                    this.couponsToActivate = []
                })
                await this.initCoupons()
            }
        }
    }
</script>

<style scoped>

.background-image {
    background: url("~@/assets/images/affinity_bg.jpg");
    background-size: cover;
    height: 100vh;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow-x: hidden;
}

.image-redeemed {
    position: relative;
}

.opacity-changer {
    opacity: 0.15;
}

.coupon-redeemed {
    position: absolute;
    top: 0;
    width: 100%;
    height: 101%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #253e92;
    background-color: rgba(42, 171, 226, 0.4);
}

.header-shop-text {
    font-size: 18px;
    font-family: 'CFA-Bold';
}

.simple-shop-text {
    font-size: 16px;
    font-family: 'CFA-Bold';
}

.text-on-coupon {
    position: relative;
    font-family: 'CFA-Bold';
    color: #253e92;
    font-size: clamp(20px, 1.6vmin, 29px);
    left: 0;
    right: 0;
    margin-top: -18%;
}

.selection-active-banner {
    color: #fff;
    position: absolute;
    top: 200px;
    line-height: 20px;
    left: 205px;
    width: 180px;
    border-radius: 12px;
    height: 49px;
    background: #eb2127;
    box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.7);
    z-index: 1;
}

.flex-container {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.check-icon {
    width: 100%;
    height: auto;
    max-width: 42px;
}

.coupon-selected {
    width: 100%;
    max-width: 273px;
}

.check-icon-position {
    position: absolute;
    right: 0;
    top: 0;
}

.no-coupons-text {
    font-size: 56px;
    font-weight: 700;
    margin-top: 250px;
    color: #0d5198;
    font-family: 'CFA-Bold';
}

.coupon-image.clicked {
    opacity: 0.3;
}

.text-bold {
    font-family: 'CFA-bold';
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.activate-button {
    background: #004bd1;
    position: absolute;
    bottom: 40px;
    right: 40px;
    color: white;
    font-family: 'CFA-Light';
    width: 338px;
    height: 70px;
    border: none;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 30px;
}

.grey-button {
    background: #ccc;
}

.background-card {
    min-width: 380px;
    min-height: 292px;
    display: table;
    width: 100%;
    height: 100%;
    padding: 0;
    background: #fff;
    line-height: 25px;
}

.background-card.pointer {
    cursor: pointer;
}

.coupon-image {
    max-width: 379px;
    vertical-align: middle;
    position: relative;
    width: 100%;
    background-color: white;
}

.layer-select {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    font-size: 8px;
    border: 5px solid transparent;
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(to right, #00aeef, #276aff) 1;
}

.layer-select-wrong {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    font-size: 8px;

    /* border: 6px solid #eb2127; */
    background: rgba(230, 230, 230, 0.7);
}

.instruction-text {
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    color: #102e9e;
    font-family: 'CFA-Bold';
}

.simple-instruction-text {
    font-size: 28px;
}

.warning-color {
    color: #eb2127 !important;
    border: 1px solid #eb2127 !important;
}

.header-text {
    font-size: clamp(15px, 5vmin, 30px);
    color: #0d5198;
    font-family: 'CFA-Light';
}

.coupon-number-text {
    text-align: center;
    font-size: 25px;
    color: #009fe3;
    font-family: 'CFA-Light';
    width: 100%;
    height: 100%;
    max-width: 688px;
    padding: 15px;
    border: 1px solid #00aeef;
    border-radius: 12px;
    max-height: 82px;
}

.info-text {
    font-family: 'CFA-Light';
    color: #253487;
    font-size: 28px;
    text-align: center;
    position: absolute;
    bottom: 20px;
    right: 580px;
    width: 100%;
    max-width: 770px;
    line-height: 34px;
}

.bold-text {
    font-family: 'CFA-Bold';
}

@media screen and (max-width: 1600px) {
    .selection-active-banner {
        top: 180px;
        left: 155px;
    }

    .coupon-number-text {
        font-size: 20px;
        line-height: 22px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .coupon-image {
        max-width: 330px;
    }

    .help-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
    }

    .background-card {
        min-width: 330px;
        min-height: 250px;
    }

    .info-text {
        margin-top: 20px;
        padding: 15px;
        position: relative;
        line-height: 18px;
        font-size: 16px;
        bottom: 0;
        right: 0;
    }

    .activate-button {
        position: relative;
        padding: 10px;
        bottom: 0;
        right: 0;
    }
}
</style>
