<template>
    <div v-if="gamePlayAgainModal" class="outer-container">
        <div class="card">
            <button
                class="cancel-btn"
                @click="goBack"
            >
                <img src="@/assets/x_icon_blue.png" class="icon-back" alt=" ">
            </button>
            <div class="card-top">
                <img
                    :src="config.assetsHost + '/' + playAgainGame.thumbnailImage.relativePath + '/'+ playAgainGame.thumbnailImage.fileName"
                    class="game-image"
                    draggable="false"
                >
                <div class="title-container">
                    <div class="title">{{ playAgainGame.title }}</div>
                    <div class="date mt-2">{{ $t('gamePlayAgainModal.youPlayed', { n: dateFormatter(playAgainGame.playedOnDate) }) }}</div>
                    <span class="played-state-text">{{ ( playAgainGame.playedOnWeb) ? 'Online': playAgainGame.playedOnKioskName }}</span>
                </div>
            </div>
            <div>
                <div class="points-earned mb-2">{{ $t('gamePlayAgainModal.youWon', { n: playAgainGame.pointsEarned }) }}</div>
                <div v-if="playAgainGame.cashRedeemedGameCoupons[0]">
                    <div class="game-redeemed-coupon-text redeemed-container">{{ $t('gamePlayAgainModal.redeemed[0]') }} {{ dateTimeUtils.dateFormatter(playAgainGame.cashRedeemedGameCoupons[0].cashRedemptionDate) }}</div>
                    <div v-if="!playAgainGame.cashRedeemedGameCoupons[0].cashRedemptionKiosk" class="game-redeemed-coupon-text">{{ $t('webCoupons.franchise') }}</div>
                    <div v-if="playAgainGame.cashRedeemedGameCoupons[0].cashRedemptionKiosk && playAgainGame.cashRedeemedGameCoupons[0].cashRedemptionKiosk.storeCode === 686" class="game-redeemed-coupon-text">{{ $t('webCoupons.eshop') }}</div>
                    <div v-if="playAgainGame.cashRedeemedGameCoupons[0].cashRedemptionKiosk && playAgainGame.cashRedeemedGameCoupons[0].cashRedemptionKiosk.storeCode !== 686">
                        <div class="game-redeemed-coupon-text mb-n1"> {{ $t('gamePlayAgainModal.redeemed[1]') }}</div>
                        <div class="game-redeemed-coupon-text">{{ playAgainGame.cashRedeemedGameCoupons[0].cashRedemptionKiosk.address }} {{ playAgainGame.cashRedeemedGameCoupons[0].cashRedemptionKiosk.region }}</div>
                    </div>
                </div>
                <div v-else class="get-your-points">{{ playAgainGame.webInstructions }}</div>
            </div>
            <div>
                <button class="play-again-button" @click="playAgain"><div class="btn-text">{{ $t('gamePlayAgainModal.playAgain') }}</div><img src="@/assets/play_again_icon.png" alt=""></button>
                <div class="without-points mt-3">{{ $t('gamePlayAgainModal.withoutPoints') }}</div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment-timezone'
    import config from '@/mixins/config'
    import dateTimeUtils from '@/utils/date-time-utils'
    export default {
        name: 'GamePlayAgainModal',
        mixins: [config],
        data() {
            return {
                dateTimeUtils: dateTimeUtils
            }
        },
        computed: {
            gamePlayAgainModal() {
                return this.$store.getters.getPlayAgainModal
            },
            playAgainGame() {
                return this.$store.getters.getPlayAgainGame
            },
            iframeSrc() {
                if (this.playAgainGame && this.playAgainGame.playerType) {
                    return `${this.config.gameServerHost}/ab/games/${this.playAgainGame.id}/popups/${this.playAgainGame.playerType}?jwtToken=${this.jwtToken}`
                } else {
                    return null
                }
            },
            jwtToken() {
                return this.$store.getters.getJwtToken
            }
        },
        methods: {
            goBack() {
                this.$store.dispatch('hidePlayAgainModal')
            },
            playAgain() {
                const channelQuery = this.$route.query.channel ? this.$route.query.channel : null
                const campaignIdQuery = this.$route.query.campaignId ? this.$route.query.campaignId : null
                this.$router.push({ name: 'AB Game', params: { gameId: this.playAgainGame.id, channel: channelQuery, campaign: campaignIdQuery } })
                this.$store.dispatch('hidePlayAgainModal')
            },
            dateFormatter: (value, row, index) => {
                return value ? moment(value).tz('Europe/Athens').format('DD-MM-YYYY HH:mm') : value
            }
        }
    }
</script>

<style scoped>
.outer-container {
    background: #deecf4;
    top: 0;
    text-align: center;
    z-index: 2;
    background-repeat: repeat;
    height: 100%;
    position: fixed;
    width: 100%;
    overflow-y: scroll;
}

.header {
    color: #0d2166;
    font-size: 2.3rem;
    font-family: 'CFA-Light';
    font-weight: 700;
    padding: 6.5rem;
    margin-top: 20px;
}

.redeemed-container {
    margin: 0 auto;
    border-radius: 8px;
    padding: 8px;
    width: 75%;
    color: white;
    background: #00aeef;
    margin-bottom: 15px;
}

.bold-header {
    font-family: 'CFA-Bold';
}

.card {
    position: relative;
    width: 608px;
    height: 534px;
    margin: 90px auto;
    color: #253e92;
    border-radius: 15px;
    padding: 1.5rem;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.17);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cancel-btn {
    top: 0;
    right: 0;
    border: none;
    background: none;
    padding: 10px 15px;
    position: absolute;
}

.card-top {
    display: flex;
    align-items: center;
}

.game-image {
    box-shadow: 0 3.65px 14.61px 0 rgba(0, 0, 0, 0.2);
    width: 180px;
    height: 180px;
    border: 8px solid #fff;
    border-radius: 20px;
}

.title-container {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.title {
    font-family: 'CFA-Bold';
    font-weight: 700;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
}

.date,
.get-your-points,
.without-points {
    font-family: 'CFA-Light';
    font-weight: 700;
}

.game-redeemed-coupon-text {
    font-family: 'CFA-Bold';
    font-size: 20px;
}

.get-your-points {
    color: #253e92;
}

.points-earned {
    color: #eb2127;
    font-family: 'CFA-Bold';
    font-size: 1.5rem;
}

.without-points {
    font-size: 19.2px;
    color: #0d2166;
    line-height: 21px;
}

.play-again-button {
    background: #0d2166;
    width: 100%;
    border: none;
    color: white;
    font-size: 1.5rem;
    font-family: 'CFA-Bold';
    padding: 1rem;
    border-radius: 10px;
    display: flex;
}

.play-again-button img,
.btn-text {
    margin-left: auto;
}

.kiosk-image {
    width: 14px;
    height: 21px;
}

.iframe-box {
    width: 100%;
    overflow-y: auto;
    border: none;
}

table,
th,
td {
    margin: 0 auto;
    border: 1px solid;
    font-size: 20px;
    padding: 10px;
}

.web-instructions {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 16px;
}

.play-again-btn {
    background: url('~@/assets/replay_game_btn.png');
    outline: none;
    border: none;
    width: 144px;
    height: 144px;
}

.play-again-btn:active {
    outline: none;
    border: none;
}

.play-again-btn:focus {
    outline: none;
    border: none;
}

.info-container {
    background-color: #fff;
    background-size: cover;
    position: relative;
    width: 636px;
    height: auto;
    top: 100px;
    border-radius: 12px;
    border: 4px solid #ccedfb;
    box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.2);
}

.go-back {
    margin-top: 50px;
}

.back-btn {
    background: url('~@/assets/cancel_replay_btn.png');
    position: absolute;
    top: 390px;
    left: 990px;
    outline: none;
    border: none;
    width: 187px;
    height: 187px;
}

.back-label {
    position: absolute;
    top: 566px;
    left: 1008px;
    color: #0d5198;
    font-size: 30px;
    font-family: 'CFA-Bold';
}

.top-text-bold {
    color: #0d5198;
    font-size: 48px;
    font-family: 'CFA-Bold';
}

.top-text-light {
    color: #0d5198;
    font-size: 48px;
    font-family: 'CFA-light';
}

.header-text {
    color: #0d5198;
    font-size: 42px;
    font-family: 'CFA-Bold';
    margin-top: 120px;
}

.date-text {
    color: #0d5198;
    font-family: 'CFA-light';
    font-weight: 700;
}

.potition-text {
    color: #0d5198;
    font-family: 'CFA-Bold';
}

.points-container {
    font-family: 'CFA-Bold';
    font-size: 24px;
    border: 2px solid lightgrey;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    color: #0d5198;
}

.win-text {
    color: #0d5198;
    font-family: 'CFA-Bold';
    font-size: 24px;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.bottom-text-ligth {
    color: #0d5198;
    font-family: 'CFA-light';
    font-size: 24px;
    font-weight: 700;
}

.bottom-text-bold {
    color: #0d5198;
    font-family: 'CFA-Bold';
    font-size: 24px;
}

@media screen and (max-width: 1660px) {
    .card {
        max-width: 500px;
        width: 100%;
        border-radius: 20px;
        height: auto;
    }

    .outer-container {
        background: #deecf4;
        padding: 0 10px;
    }

    .game-image {
        width: 120px;
        height: 120px;
    }

    .title-container {
        text-align: center;
        font-size: 14px;
    }

    .card-top {
        flex-direction: column;
    }

    .title {
        margin-top: 15px;
        font-size: 23px;
        line-height: 27px;
        margin-bottom: 0;
    }

    .points-earned {
        line-height: 27px;
        font-size: 25px;
        margin-top: 10px;
    }

    .game-redeemed-coupon-text {
        font-size: 17px;
    }

    .get-your-points {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .without-points {
        font-size: 18px;
    }

    .header {
        font-size: 30px;
        padding: 0.6rem 3.5rem;
        line-height: 33px;
    }
}
</style>
