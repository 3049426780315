import Vue from 'vue'
import VueRouter from 'vue-router'
import vueConfig from '@/../vue.config'
import AppPrefix from '@/utils/AppPrefix'
import userService from '@/services/user-service'
import store from '@/store'
import jwtUtils from '@/utils/jwt-utils'
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: vueConfig.publicPath,
    routes: [
        {
            path: '/MobileGames',
            name: 'Ab Mobile Games',
            meta: {
                title: 'AB Plus & Games'
            },
            component: () => import('@/components/games/GameListRoute')
        },
        {
            path: '/cancelled-card',
            name: 'AB Cancelled Card',
            meta: {
                title: 'AB Plus & Games'
            },
            component: () => import('@/components/CancelledCardRoute')
        },
        {
            path: '/expired-or-invalid',
            name: 'AB Expired Or Invalid Url',
            meta: {
                title: 'AB Plus & Games'
            },
            component: () => import('@/components/ExpiredOrInvalidCodeView')
        },
        {
            path: '/affinity-partner/login',
            name: 'Affinity Partner Login',
            meta: {
                title: 'AB Affinity Partner Login'
            },
            component: () => import('@/components/affinity-panel/AffinityPartnerLogin')
        },
        {
            path: '/affinity-partner',
            name: 'Affinity Partner home',
            meta: {
                title: 'AB Affinity Partner Submission Form'
            },
            component: () => import('@/components/affinity-panel/AffinitySubmissionForm')
        },
        {
            path: '/games',
            name: 'Mobile Games',
            meta: {
                title: 'AB Plus & Games'
            },
            component: () => import('@/components/games/GameListRoute')
        },
        {
            path: '/partners',
            name: 'AB Affinity Offers - Partners',
            component: () => import('@/components/affinity-offers/AffinityOffersListRoute'),
            meta: {
                title: 'AB Affinity Offers - Partners',
                notSecure: true
            }
        },
        {
            path: '/partners/ab-plus-number/:abPlusNumber',
            name: 'AB Affinity Offers',
            component: () => import('@/components/affinity-offers/AffinityOffersListRoute'),
            meta: {
                title: 'AB Affinity Offers',
                notSecure: true
            }
        },
        {
            path: '/partners/cosmote-offers/',
            name: 'Cosmote Offer',
            component: () => import('@/components/affinity-offers/CosmoteOfferRoute'),
            meta: {
                title: 'Cosmote Offer',
                notSecure: true
            }
        },
        {
            path: '/partners/:affinityOfferId(\\d+)',
            name: 'AB Affinity Offer',
            component: () => import('@/components/affinity-offers/AffinityOfferRoute'),
            meta: {
                title: 'AB Affinity Offer',
                notSecure: true
            }
        },
        {
            path: '/partners/inactive-affinity-offer',
            name: 'Inactive Affinity Offer',
            component: () => import('@/components/affinity-offers/InactiveAffinityOfferRoute'),
            meta: {
                title: 'Inactive Affinity Offer',
                notSecure: true
            }
        },
        {
            path: '/partners/:affinityOfferId/more-info',
            name: 'AB Affinity More Informations',
            component: () => import('@/components/affinity-offers/AffinityMoreInfoRoute'),
            meta: {
                title: 'AB Affinity More Informations',
                notSecure: true
            }
        },
        {
            path: '/partners/:affinityOfferId/insert-code',
            name: 'AB Affinity Offer - Insert Code',
            component: () => import('@/components/affinity-offers/AffinityInsertCodeRoute'),
            meta: {
                title: 'AB Affinity Offer - Insert Code'
            }
        },
        {
            path: '/partners/cosmote-offers/insert-code',
            name: 'Cosmote Offer - Insert Code',
            component: () => import('@/components/affinity-offers/CosmoteInsertCodeRoute'),
            meta: {
                title: 'Cosmote Offer - Insert Code'
            }
        },
        {
            path: '/partners/cosmote-offers/more-info',
            name: 'Cosmote More Informations',
            component: () => import('@/components/affinity-offers/CosmoteMoreInfoRoute'),
            meta: {
                title: 'Cosmote More Informations',
                notSecure: true
            }
        },
        {
            path: '/partners/:affinityOfferId/code/:code/coupons',
            name: 'AB Affinity Offer - Coupons Old',
            component: () => import('@/components/affinity-offers/AffinityCouponsListRoute'),
            meta: {
                title: 'AB Affinity Offer - Coupons'
            }
        },
        {
            path: '/partners/:affinityOfferId/ab-plus-number/:abPlusNumber',
            name: 'AB Affinity Offer - Coupons',
            component: () => import('@/components/affinity-offers/AffinityOfferRoute'),
            meta: {
                title: 'AB Affinity Offer - Coupons'
            }
        },
        {
            path: '/games/:gameId?/ab-plus-number/:abPlusNumber',
            name: 'Mobile Games url Code',
            meta: {
                title: 'AB Plus & Games'
            },
            component: () => import('@/components/games/GameListRoute')
        },
        {
            path: '/offers/:offerId?/ab-plus-number/:abPlusNumber',
            name: 'AB Digital Coupons',
            component: () => import('@/components/coupons/DigitalCouponsRoute'),
            meta: {
                title: 'AB Digital Coupons'
            }
        },
        {
            path: '/C/coupons/code/:urlCode',
            name: 'AB c Combo Coupons',
            component: () => import('@/components/combo/ComboCoupon'),
            meta: {
                title: 'AB Combo Coupons'
            }
        },
        {
            path: '/visits',
            name: 'AB visits',
            component: () => import('@/components/VisitsRoute'),
            meta: {
                title: 'AB Visits'
            }
        },
        {
            path: '/visits/u/:urlCode?',
            name: 'AB Url visits',
            component: () => import('@/components/VisitsRoute'),
            meta: {
                title: 'AB Visits'
            }
        },
        {
            path: '/games/profile',
            name: 'Games profile',
            component: () => import('@/components/games/EditProfileRoute'),
            meta: {
                title: 'My game Profile'
            }
        },
        {
            path: '/games/information',
            name: 'Games Information',
            component: () => import('@/components/games/GamesInfoRoute'),
            meta: {
                title: 'Games Information'
            }
        },
        {
            path: '/games/leaderboard',
            name: 'Games LeaderBoard',
            component: () => import('@/components/games/GamesLeaderBoardRoute'),
            meta: {
                title: 'Games LeaderBoard'
            }
        },
        {
            path: '/games/history',
            name: 'Games History',
            component: () => import('@/components/games/GamesHistoryRoute'),
            meta: {
                title: 'Games History'
            }
        },
        {
            path: '/games/:gameId',
            name: 'AB Game',
            component: () => import('@/components/games/GameRoute'),
            meta: {
                title: 'AB Game'
            }
        },
        {
            path: '/login',
            name: 'User Authentication',
            component: () =>
                import('@/components/LoginRoute'),
            meta: {
                title: 'User Authentication',
                notSecure: true
            }
        },
        {
            path: '/contests/:contestId',
            name: 'AB Contest',
            component: () => import('@/components/contests/ContestRoute'),
            meta: {
                title: 'AB Contest'
            },
            props(route) {
                return {
                    contestId:
                        typeof route.params.contestId === 'string'
                            ? parseInt(route.params.contestId)
                            : route.params.contestId
                }
            }
        },
        {
            path: '/contests/:contestId/redeem',
            name: 'AB Contest Redeem',
            component: () => import('@/components/contests/ContestRedeemRoute'),
            meta: {
                title: 'AB Contest Redeem'
            },
            props(route) {
                return {
                    contestId:
                        typeof route.params.contestId === 'string'
                            ? parseInt(route.params.contestId)
                            : route.params.contestId
                }
            }
        },
        {
            path: '/contests/:contestId/terms',
            name: 'AB Contest Terms',
            component: () => import('@/components/contests/ContestTermsRoute'),
            meta: {
                title: 'AB Contest Terms'
            }
        }
    ]
})

const redirect = (next, route) => {
    store.dispatch('logout')
    next(route)
    throw new Error()
}

// https://stackoverflow.com/a/51915224/986160
router.beforeEach(async(to, from, next) => {
    const appPrefix = new AppPrefix(to).getPrefix()
    if (appPrefix) {
        // const urlCode = to.params.urlCode
        const authToken = store.getters.getJwtToken
        const abPlusNumber = to.params.abPlusNumber
        // const directUrlPath = to.path.split('/').shift()
        if (jwtUtils.isValid(authToken)) {
            await store.dispatch('refreshCurrentUser')
        } else {
            store.dispatch('setJwtToken', null)
        }
        if (abPlusNumber) {
            let isCustomerBanned
            let loginResponse
            try {
                isCustomerBanned = await userService.isCardBanned(abPlusNumber)
            } catch {
                redirect(next, '/expired-or-invalid')
            }
            if (isCustomerBanned.content) {
                redirect(next, '/cancelled-card')
            }

            try {
                loginResponse = await userService.login(parseInt(abPlusNumber))
            } catch {
                redirect(next, '/expired-or-invalid')
            }
            store.dispatch('login', { barcode: parseInt(abPlusNumber), ...loginResponse })
        } else if (!store.getters.isUserLoggedIn && to.path !== '/login') {
            store.dispatch('logout').then(() => next({ path: '/login', query: { from: to.fullPath } }))
        }
    }
    if (store.getters.isUserLoggedIn && to.path === '/login') {
        next('/games')
    }
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title
    }
    next()
})

export default router
