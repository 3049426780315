<template>
    <div class="tile" @click="clickGame(game)">
        <div class="game-tile-first-container" :class="{'opacity-change': game.played}">
            <img
                v-if="game && game.thumbnailImage"
                class="game-image"
                :src="config.assetsHost + '/' + game.thumbnailImage.relativePath + '/'+ game.thumbnailImage.fileName"
            >
            <div>
                <div class="title">
                    {{ game.title }}
                </div>
                <div class="available">
                    <div>{{ $t('gameTile.availableTill') }} {{ dateFormatter(game.end) }}</div>
                </div>
            </div>
        </div>
        <div class="stars-or-check" :class="{'opacity-change': game.played}">
            {{ game.pointsList[ game.pointsList.length -1 ] }}
            <b-icon-star-fill class="ml-1 mt-n1" variant="warning" />
        </div>
        <b-button class="button px-4">
            <div v-if="!game.played">
                <span class="bold-text">{{ $t('gameTile.play')[0] }}</span>
                {{ $t('gameTile.play')[1] }}
            </div>
            <img v-if="!game.played" src="@/assets/btn_arrow_icon.png" class="responsive-icon" alt=" " draggable="false">
            <div v-if="game.played">
                {{ $t('gameTile.playAgain')[0] }}
                <span class="bold-text">{{ $t('gameTile.playAgain')[1] }}</span>
            </div>
            <img v-if="game.played" src="@/assets/play-again-white.png" class="responsive-icon-play-again" alt=" " draggable="false">
        </b-button>

    </div>
</template>
<script>
    import config from '@/mixins/config'
    import dateTimeUtils from '@/utils/date-time-utils'
    export default {
        name: 'GameTile',
        mixins: [config],
        props: {
            game: {
                type: Object,
                required: true
            }
        },
        methods: {
            async clickGame(game) {
                if (!game.played) {
                    this.$router.push(`/games/${game.id}`)
                } else {
                    this.$store.dispatch('showPlayAgainModal', game)
                }
            },
            dateFormatter(date) {
                return dateTimeUtils.dateFormatter(date)
            }
        }
    }
</script>
<style scoped>
.tile {
    background: #fff;
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    width: 529px;
    height: 345px;
    box-shadow: 0.2rem 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
    position: relative;
    border-radius: 18px;
    padding: 0 20px;
}

.game-tile-first-container {
    display: flex;
    text-align: left;
    align-items: center;
    margin-top: 20px;
}

.opacity-change {
    opacity: 0.4;
}

.game-image {
    margin-right: 20px;
    width: 170px;
    height: 170px;
    border-radius: 14.7px;
    box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
    border: 4px solid #fff;
}

.button {
    width: 300px;
    height: 70px;
    border-radius: 20px;
    background: #009fe3;
    color: #fff;
    font-family: 'CFA-Light';
    font-size: 1.3rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: transparent;
    margin: 0 auto;
}

.text {
    display: flex;
    height: 90%;
    justify-content: space-between;
    flex-direction: column;
    text-align: start;
    padding-left: 22%;
    padding-top: 13%;
}

.title {
    margin-bottom: 15px;
    font-size: clamp(0.75rem, 1vw + 1rem, 2.125rem);
    font-family: 'CFA-Bold';
    color: #0b2066;
    line-height: clamp(1.375rem, 1vw + 1rem, 2.125rem);
    margin-top: 20px;
}

.available {
    font-size: 17px;
    line-height: 20px;
    color: #0b2066;
    font-weight: 700;
    font-family: 'CFA-Light';
}

.bold-text {
    font-family: 'CFA-Bold';
}

.play-again-overlay img {
    height: 100%;
}

.stars-or-check {
    background: #fff;
    color: #0b2066;
    position: absolute;
    top: 4%;
    right: 4%;
    width: 84px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #eaf3f2;
    border-radius: 15px;
    font-family: 'CFA-bold';
    font-size: 20px;
}

@media screen and (max-width: 1660px) {
    .tile {
        width: 376px;
        min-height: 225px;
        height: auto;
    }

    .button {
        font-size: 17px;
        margin: 13px auto;
        width: 249px;
        height: 59px;
    }

    .game-image {
        width: 120px;
        height: 120px;
    }

    .available {
        font-size: 13.12px;
    }

    .stars-or-check {
        width: 60px;
        height: 27px;
        border-radius: 9px;
        font-size: 17px;
    }

    .responsive-icon {
        width: 15px;
        height: auto;
    }

    .responsive-icon-play-again {
        width: 22px;
        height: auto;
    }
}
</style>
