<template>
    <div class="container">
        <div v-if="!coupon" class="text-center">
            <img
                :src="require('@/assets/images/combo/dummy-coupon.png')"
                class="dummy"
            >
        </div>
        <div
            v-else
            class="result-wrapper"
        >
            <div class="coupon">
                <img
                    :src="couponImgPath"
                    class="coupon-image"
                >
            </div>
            <div
                v-if="!showModal"
                class="redeem-btn pointer"
                @click="redeemCoupon"
            >
                <div class="redeem-text-group">
                    <div>{{ $t('comboResults.cashIn') }}</div>
                    <div>{{ $t('comboResults.yourCoupon') }}</div>
                </div>
                <div class="arrows-wrapper">
                    <img
                        :src="require('@/assets/images/combo/bold_arrow_right.png')"
                        class="arrow1"
                    >
                    <img
                        :src="require('@/assets/images/combo/bold_arrow_right.png')"
                        class="arrow2"
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import config from '@/mixins/config'
    export default {
        mixins: [config],
        props: {
            coupon: {
                type: Object,
                default: null
            },
            showModal: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            couponImgPath() {
                if (!this.coupon) {
                    return ''
                }
                return `${this.config.assetsHost}/${this.coupon.digitalImage.relativePath}/${this.coupon.digitalImage.fileName}`
            }
        },
        methods: {
            redeemCoupon() {
                this.$parent.redeemCoupon()
            }
        }

    }
</script>

<style scoped>
    .container {
        width: 100%;
        padding: 0;
    }

    .dummy {
        width: 100%;
        box-shadow: 0.5px 0.866px 8px rgba(58, 58, 58, 0.23);
        border-radius: 5px;
    }

    .coupon {
        width: 100%;
        background: #fff;
        border-radius: 5px;
        margin-bottom: 5px;
    }

    .result-wrapper {
        text-align: center;
    }

    .result-wrapper img {
        box-shadow: 0.5px 0.866px 8px rgba(58, 58, 58, 0.23);
    }

    .coupon-image {
        width: 100%;
    }

    .bottom-img {
        width: 100%;
    }

    .redeem-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgb(99, 65, 160);
        height: 90px;
        border-radius: 20px;
        padding: 20px;
    }

    .redeem-text-group {
        color: white;
        font-size: 1.2rem;
        text-align: left;
        font-style: italic;
        font-weight: bold;
        font-family: ProximaBold, sans-serif;
    }

    .arrows-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    .arrows-wrapper img {
        width: 2rem;
        height: 2rem;
        margin: 0 0.15rem;
        box-shadow: none;
    }

    .arrow1 {
        animation: fade 4s ease-in-out infinite;
    }

    .arrow2 {
        animation: fade 4s ease-in-out infinite;
        animation-delay: 2s;
    }

    @keyframes fade {
        50% {
            opacity: 0.1;
        }

        100% {
            opacity: 1;
        }
    }

    @media screen and (max-width: 768px) {
        .coupon-image {
            width: 85%;
            margin: 0 auto;
        }

        .dummy {
            width: 85%;
        }

        .redeem-btn {
            height: 80px;
        }
    }
</style>
