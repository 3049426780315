const englishToGreekObject = {
    A: 'Α',
    B: 'Β',
    E: 'Ε',
    Z: 'Ζ',
    H: 'Η',
    I: 'Ι',
    K: 'Κ',
    M: 'Μ',
    N: 'Ν',
    O: 'Ο',
    P: 'Ρ',
    T: 'Τ',
    Y: 'Υ',
    X: 'Χ'
}

const englishToGreekChar = c => {
    return englishToGreekObject[c] || c
}

export default {
    englishToGreek(str) {
        let res = ''
        for (const char of str) {
            res += englishToGreekChar(char)
        }
        return res
    }
}
