import restAPI from '@/utils/rest-api'

export default {
    fetchAffinityOffers() {
        return restAPI.get(`/affinity-offers?filter:${encodeURIComponent('{active=true}')}`)
    },
    registerPhoneToOffer(affinityOfferId, phoneNumber) {
        return restAPI.post(`/affinity-offers/${affinityOfferId}/send/${phoneNumber}`)
    }
}
