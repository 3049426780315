<template>
    <div>
        <div
            class="expired-or-invalid-code text-center"
        >
            <div class="top-container">
                <div>{{ $t('expiredOrInvalidView.message')[0] }}</div>
            </div>
            <div class="second-text">{{ $t('expiredOrInvalidView.message')[1] }}</div>
            <div class="text-center mt-4">
                <img
                    :src="require('@/assets/big_card_ab.png')"
                    alt="ab plus number card"
                    class="mb-5 img-size"
                >
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    }
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
        font-weight: bold;
        color: #00a5e2;
    }

    .top-container {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        line-height: 25px;
        top: 0;
        width: 100%;
        height: 150px;
        background: #00a5e2;
    }

    .second-text {
        padding: 60px;
        font-size: 22px;
        line-height: 30px;
        font-weight: 500;
        color: #102e9e;
    }

    a:hover {
        color: #0095d2;
    }

    .expired-or-invalid-code {
        font-family: 'CFA-Bold';
    }

    .img-size {
        height: auto;
        max-width: 300px;
        width: 50%;
        transform: rotate(-15deg);
    }
</style>
