<template>
    <b-container v-if="offer && offer.id && offer.active" fluid class="background-image">
        <affinity-top-bar :show-home="!offer.hidden" :disable-home="false" />
        <div class="main-container">
            <div class="custom-margin text-responsive">
                <div class="text-bold-description mb-4">{{ offer.name }}</div>
                <div v-dompurify-html="offer.description" class="text-light-description" />
                <router-link :to="{path:`/partners/${offer.id}/insert-code`}">
                    <button class="code-button">
                        {{ $t('affinityOffer.gotCode') }} <img src="@/assets/images/arrow1.png" alt=" " draggable="false" class="ml-3">
                    </button>
                </router-link>
                <div class="my-4">
                    <button class="more-button" @click="goToInfo">
                        {{ $t('affinityOffer.more') }}
                    </button>
                </div>
            </div>
            <img :src="config.assetsHost + '/' + offer.sideImage.relativePath + '/' + offer.sideImage.fileName" class="side-image">
        </div>
    </b-container>
</template>

<script>
    import pageViewsService from '@/services/page-views-service'
    import offerService from '@/services/affinity-offer-service'
    import config from '@/mixins/config'
    import AffinityTopBar from '@/components/AffinityTopBar'

    export default {
        name: 'AffinityOfferRoute',
        components: {
            AffinityTopBar
        },
        mixins: [config],
        data() {
            return {
                offer: {},
                transactionExists: false,
                code: ''
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isUserLoggedIn
            }
        },
        created() {
            pageViewsService.submitPageView('AB PARTNERS OFFER', this.$route.path, this.$route.params)
            this.affinityOfferDeepLink()
        },
        methods: {
            async affinityOfferDeepLink() {
                if (!this.isLoggedIn) {
                    return
                }
                if (this.$route.params.affinityOfferId) {
                    try {
                        this.offer = await offerService.fetchAffinityOfferById(this.$route.params.affinityOfferId)
                    } catch {
                        this.$router.push('/partners/inactive-affinity-offer')
                    }
                }
                this.pushIfInactiveOffer(this.offer.active)
            },
            goToInfo() {
                this.$router.push({ name: 'AB Affinity More Informations', params: { affinityOfferId: this.offer.id } })
            },
            pushIfInactiveOffer(isOfferActive) {
                if (!isOfferActive) {
                    this.$router.push('/partners/inactive-affinity-offer')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.background-image {
    background: url("~@/assets/images/affinity_bg.jpg");
    background-repeat: repeat;
    height: 100%;
    position: fixed;
    width: 100%;
    overflow: auto;
}

.side-image {
    max-width: 100%;
}

.text-bold-description {
    font-size: 40px;
    line-height: 42px;
    color: #102e9e;
    font-family: 'CFA-Bold';
}

.text-light-description {
    font-size: 25px;
    color: #253e92;
    font-family: 'CFA-light';
}

.code-button {
    background: #253e92;
    color: white;
    font-family: 'CFA-Bold';
    width: 338px;
    max-height: 90px;
    border: none;
    border-radius: 20px;
    font-size: 26px;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.more-button {
    background: transparent;
    color: #253e92;
    font-family: 'CFA-Bold';
    width: 338px;
    max-height: 90px;
    border-radius: 20px;
    text-align: left;
    border: 1px solid #253e92;
    font-size: 26px;
    padding: 25px;
    z-index: 1;
}

.main-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.custom-margin {
    max-width: 990px;
}

@media screen and (max-width: 1600px) {
    .main-container {
        flex-wrap: wrap;
    }

    .button-container {
        text-align: center;
    }

    .code-button {
        width: 338px;
    }

    .custom-margin {
        margin: 0 10%;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .text-light-description {
        font-size: 25px;
    }
}

@media screen and (max-width: 776px) {
    .text-light-description {
        font-size: 20px;
    }

    .company-name {
        font-size: 32px;
        line-height: 34px;
    }
}

</style>
