<template>
    <div>
        <div
            class="cancelled-card text-center"
        >
            <div>{{ $t('canceldCardView.message')[0] }}</div>
            <div>{{ $t('canceldCardView.message')[1] }}
                <a href="https://www.ab.gr" taget="_blank">ab.gr</a>
                {{ $t('canceldCardView.message')[2] }}
            </div>
            <div class="text-center mt-4">
                <img
                    :src="require('@/assets/card-affinity.png')"
                    alt="ab plus number card"
                    class="mb-5 img-size"
                >
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    }
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
        font-weight: bold;
        color: #00a5e2;
    }

    a:hover {
        color: #0095d2;
    }

    .cancelled-card {
        font-size: 25px;
        font-family: "PFHandbookProMedium", sans-serif;
        margin: 50px 0;
        color: #00a5e2;
    }

    .img-size {
        height: 140px;
    }
</style>
