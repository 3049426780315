<template>
    <b-container fluid class="background-image">
        <affinity-top-bar :show-home="affinityOffer && !affinityOffer.hidden" :disable-home="false" />
        <b-row class="justify-content-center pt-4 flex-column text-center">
            <div class="text-bold-description">
                {{ $t('affinityInsertCode.insertCode') }}
            </div>
        </b-row>
        <b-row class="mt-5 justify-content-center align-items-center">
            <b-form-input
                :value="code"
                size="lg"
                placeholder="Κωδικός"
                class="input-box"
                @input="onInputChange($event, 'code')"
                @keypress="codeKeyPress($event)"
            />
            <button class="sumbit-button" :class="{'disabled-button': isCardBlocked == true}" :disabled="isCardBlocked" @click="submitCode()" />
        </b-row>
        <div class="error-message-container mt-4">
            <b-row v-if="maxCustomerPartitiopationReached" class="error-message-light">
                {{ $t('affinityInsertCode.reachedMaxParticipations') }}
            </b-row>
            <div v-if="!isValidCode && !isCardBlocked" class="error-message-light">
                <div>{{ $t('affinityInsertCode.wrongCode[0]') }}</div>
                <div class="bold-text">{{ $t('affinityInsertCode.wrongCode[1]') }}</div>
            </div>
            <div v-if="isCardBlocked" class="error-message-light">
                <div>
                    {{ $t('affinityInsertCode.tooManyTimesWrong[0]') }}
                </div>
                <div class="bold-text">
                    {{ $t('affinityInsertCode.tooManyTimesWrong[1]') }}
                </div>
            </div>
        </div>
        <b-alert v-model="submitDone" variant="primary" class=" d-inline-flex saved-msg ml-4 mt-4 w-75 pt-3" style="font-weight: 900;">
            <b-icon icon="check-circle" font-scale="2" class="mt-n1 mr-2 ml-n2" />
            <p class="align-self-center mb-0 mt-n1">{{ $t('editProfile.saved') }}</p>
        </b-alert>
        <div class="align-center text-responsive">
            <img src="@/assets/images/back_button.png" class="back-button mb-4" @click="goBack">
        </div>
    </b-container>
</template>

<script>
    import pageViewsService from '@/services/page-views-service'
    import affinityOfferService from '@/services/affinity-offer-service.js'
    import config from '@/mixins/config'
    import Vue from 'vue'
    import AffinityTopBar from '@/components/AffinityTopBar'
    import englishToGreekUtil from '@/utils/english-to-greek-util'

    export default {
        name: 'AffinityInsertCodeRoute',
        components: {
            AffinityTopBar
        },
        mixins: [config],
        data() {
            return {
                code: '',
                submitDone: false,
                isValidCode: true,
                isCardBlocked: false,
                maxCustomerPartitiopationReached: false,
                affinityOffer: null
            }
        },
        computed: {
            currentUser() {
                return this.$store.getters.getCurrentUser
            }
        },
        async created() {
            pageViewsService.submitPageView('AB PARTNERS INSERT CODE', this.$route.path, this.$route.params)
            this.affinityOffer = await affinityOfferService.fetchAffinityOfferById(this.$route.params.affinityOfferId)
        },
        methods: {
            async isBlocked(barcode) {
                if (!barcode || !this.$store.getters.getJwtToken) {
                    return true
                }
                const isBlocked = await affinityOfferService.checkIfAbCardIsBlocked(barcode).then(resp => {
                    return resp.content
                })
                return isBlocked
            },
            async submitCode() {
                this.code = englishToGreekUtil.englishToGreek(this.code)
                this.isValidCode = true
                this.maxCustomerPartitiopationReached = false
                const isBlocked = await this.isBlocked(this.currentUser?.barcode)
                if (isBlocked) {
                    this.isCardBlocked = true
                    return
                }

                const checkAffinityCodeResponse = await affinityOfferService.checkAffinityCode(this.code)
                if (!checkAffinityCodeResponse.content) {
                    this.isValidCode = false
                    return
                }
                const checkCustomerParticipationResponse = await affinityOfferService.checkAffinityCustomerParticipationByCode(this.code)
                if (!checkCustomerParticipationResponse.content) {
                    this.maxCustomerPartitiopationReached = true
                    return
                }

                this.$router.push({ path: `/partners/${this.$route.params.affinityOfferId}/code/${this.code}/coupons` })
            },
            codeKeyPress(event) {
                const isCodeKeyValid = /^[a-zA-Zα-ωΑ-Ω0-9]+$|^$/.test(event.key)
                if (!isCodeKeyValid || this.code.length === 10) {
                    event.preventDefault()
                }
            },
            onInputChange(input, inputName) {
                if (input && input.target) {
                    Vue.set(this, inputName, input.target.value)
                } else if (input && !input.target) {
                    Vue.set(this, inputName, input)
                }
            },
            goBack() {
                this.$router.back()
            }
        }
    }
</script>

<style scoped lang="scss">
.background-image {
    background: url("~@/assets/images/affinity_bg.jpg");
    background-repeat: repeat;
    height: 100%;
    position: fixed;
    width: 100%;
    overflow: scroll;
}

.text-bold-description {
    font-size: 50px;
    line-height: 52px;
    font-weight: 700;
    color: #102e9e;
    font-family: 'CFA-Bold';
}

.align-center {
    margin-top: 100px;
    margin-right: 5%;
    display: flex;
    justify-content: center;
}

.back-button {
    width: 350px;
    cursor: pointer;
}

.input-box {
    background-color: #fefefe;
    color: #ccc;
    border-radius: 20px;
    margin-right: 20px;
    border: 0;
    width: calc(330px + 30vmin);
    height: calc(25px + 7vmin);
    padding: 25px;
    text-align: center;
    font-family: 'CFA-bold';
    font-size: clamp(1.25rem, 1vw + 1rem, 1.875rem);
    align-self: baseline;
}

.input-box:focus {
    background-color: #0d5198;
    color: #fff;
}

.input-box::placeholder {
    color: #99a4c3;
}

.input-box.focused::placeholder {
    color: #fff;
}

.sumbit-button {
    background: url('~@/assets/images/insert_code.png') no-repeat;
    background-size: 100% auto;
    width: calc(25px + 7vmin);
    height: calc(25px + 7vmin);
    outline: none;
    border: none;
}

.disabled-button {
    opacity: 0.25 !important;
}

.error-message-container {
    margin: 0 auto;
    text-align: center;
    width: calc(190px + 38vmin);
}

.bold-text {
    font-family: 'CFA-Bold';
}

.error-message-light {
    font-family: 'CFA-light';
    font-weight: 700;
    font-size: clamp(12px, 1vw + 1rem, 18px);
    color: red;
    justify-content: center;
}

@media screen and (max-width: 977px) {
    .input-box {
        width: 270px;
        height: 45px;
        padding: 6px;
        border-radius: 10px;
    }

    .text-bold-description {
        margin: 0 10%;
        font-size: 32px;
        line-height: 34px;
    }

    .sumbit-button {
        width: 45px;
        height: 45px;
    }
}

@media screen and (max-width: 990px) {
    .back-button {
        width: 218px;
    }
}

</style>
