<template>
    <b-container>
        <b-modal
            id="intro-modal"
            v-model="modalShow"
            :hide-header="true"
            :hide-footer="true"
            centered
            @hidden="toggleModal"
        >
            <b-container class="top-directions">
                <span class="help-header-text">{{ $t('introModal.selectProduct[0]') }}
                    <span class="highlighted-top">{{ $t('introModal.selectProduct[1]') }}</span>
                    ...
                </span>
                <img
                    class="help-image"
                    :src="require('@/assets/images/combo/arrow_down.png')"
                    alt=""
                >
            </b-container>
            <intro-body :offer="offer" />
            <b-container class="p-3 p-sm-4 my-1 footer-wrapper" @click="toggleModal">
                <div class="footer-text">
                    <div>{{ $t('introFooter.createYour') }}</div>
                    <div class="combo-text">
                        {{ $t('introFooter.combo') }}
                    </div>
                </div>
                <div class="align-center">
                    <div class="align-center footer-left-arrow arrow1">
                        <img
                            :src="require('@/assets/images/combo/bold_arrow_right.png')"
                            alt=""
                        >
                    </div>
                    <div class="align-center footer-right-arrow arrow2">
                        <img
                            :src="require('@/assets/images/combo/bold_arrow_right.png')"
                            alt=""
                        >
                    </div>
                </div>
            </b-container>
        </b-modal>
    </b-container>
</template>

<script>
    import IntroBody from '@/components/combo/IntroBody'

    export default {
        components: {
            IntroBody
        },
        props: {
            offer: { type: Object, default: null }
        },
        data() {
            return {
                modalShow: true
            }
        },
        methods: {
            toggleModal() {
                this.$parent.showIntroModal()
            }
        }
    }
</script>

<style>
    #intro-modal .modal-dialog.modal-md {
        max-width: 90%;
        text-align: center;
        font-family: ThickStick, sans-serif;
    }

    #intro-modal___BV_modal_content_ {
        background: #eff1ec;
    }

    .overlay {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .top-directions {
        display: flex;
        flex-direction: row;
    }

    .help-header-text {
        font-size: 4.5vw;
        font-family: ThickStick, sans-serif;
    }

    .highlighted-top {
        color: #7c5cb4;
    }

    .help-image {
        width: 10vw;
        max-width: 100px;
        float: left;
    }

    .footer-wrapper {
        min-height: 3.5rem;
        max-height: 250px;
        background-image: url(~@/assets/images/combo/redeem-combo.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 0.7rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .footer-text {
        font-size: 1.4rem;
        line-height: 1;
        text-align: left;
        flex-direction: column;
        font-style: italic;
        font-family: ProximaBold, sans-serif;
        color: #f9855a;
        font-weight: 100;
        padding-left: 1rem;
    }

    .combo-text {
        font-size: 2rem;
    }

    .align-center {
        display: inline-flex;
        justify-content: flex-end;
    }

    .footer-right-arrow {
        width: 30%;
    }

    .footer-left-arrow {
        margin-right: 10%;
        width: 30%;
    }

    .arrow1 img,
    .arrow2 img {
        width: 100%;
        max-width: 100px;
    }

    .arrow1 {
        animation: fade 4s ease-in-out infinite;
    }

    .arrow2 {
        animation: fade 4s ease-in-out infinite;
        animation-delay: 2s;
    }

    @keyframes fade {
        50% {
            opacity: 0.1;
        }

        100% {
            opacity: 1;
        }
    }

    @media screen and (min-width: 576px) {
        .footer-text {
            font-size: 2rem;
        }

        .combo-text {
            font-size: 3rem;
        }

        .footer-right-arrow,
        .footer-left-arrow {
            width: 100%;
        }
    }

    @media screen and (min-width: 768px) {
        .modal-body {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }

        .footer-text {
            font-size: 3rem;
            padding-left: 1.5rem;
        }

        .combo-text {
            font-size: 4rem;
        }
    }

    @media screen and (min-width: 1200px) {
        .help-header-text {
            font-size: 60px;
        }

        .footer-text {
            font-size: 4rem;
            padding-left: 2rem;
        }

        .combo-text {
            font-size: 5rem;
        }
    }
</style>
