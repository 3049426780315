<template>
    <div class="outer-container ">
        <top-bar />
        <div class="info-container background-image">
            <div class="header">
                <div class="welcome">{{ $t('gameInfo.header[0]') }}</div>
                <div class="ab-plus-section">{{ $t('gameInfo.header[1]') }}</div>
            </div>
            <div class="info-text">
                <div>{{ $t('gameInfo.infoMessage[0]') }}</div>
                <div>{{ $t('gameInfo.infoMessage[1]') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import TopBar from '@/components/TopBar'
    import 'vueperslides/dist/vueperslides.css'
    export default {
        name: 'GamesInfoRoute',
        components: {
            TopBar
        },
        data() {
            return {}
        },
        computed: {
        },
        created() {
        },
        methods: {
        }
    }
</script>

<style scoped lang="scss">

    @media screen and (max-height: 768px) {
        .outer-container {
            position: relative !important;
        }
    }

    .outer-container {
        margin: 0 auto;
        height: 100%;
        position: fixed;
        width: 100%;
        background: repeat url('~@/assets/games_info_bg.jpg');
        background-size: cover;
        padding-bottom: 3rem;
    }

    .background-image {
        padding-bottom: 11rem;
        overflow: auto;
    }

    .info-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 4rem;
        padding-top: 10rem;
        height: 100%;
    }

    .header {
        font-family: CFA-Bold;
        font-weight: 800;
        padding: 0 11%;
    }

    .welcome {
        color: #ea1d24;
        font-size: 60px;
    }

    .ab-plus-section {
        color: #253e92;
        font-size: 40px;
        line-height: 40px;
    }

    .info-text {
        color: #253e92;
        font-size: 26px;
        font-family: CFA-light;
        font-weight: 700;
    }

    @media screen and (max-width: 1200px) {
        .info-container {
            padding-top: 5rem;
        }

        .welcome {
            font-size: 50px;
        }

        .ab-plus-section {
            font-size: 37px;
        }

        .info-text {
            font-size: 23px;
            padding: 0 15%;
        }
    }

    @media screen and (max-width: 767px) {
        .info-container {
            padding-top: 8rem;
        }

        .welcome {
            font-size: 40px;
        }

        .ab-plus-section {
            font-size: 34px;
        }

        .info-text {
            font-size: 20px;
            padding: 0 10%;
        }
    }

    @media screen and (max-width: 576px) {
        .background-image {
            background: repeat url('~@/assets/games_info_bg_mobile.png');
        }
    }
</style>
