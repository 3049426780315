<template>
    <div v-if="contest" fluid class="background-image">
        <contest-top-bar />
        <div class="main-container">
            <div class="text-wrapper">
                <div class="text-responsive">
                    <div class="title-bold mb-4">
                        {{ contest.sideInfo }}
                    </div>
                    <div
                        v-dompurify-html="termsText"
                        class="text-light-description"
                    />
                </div>
            </div>
            <div class="back-button-wrapper">
                <div class="back-button" @click="goBack">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    <span>{{ $t("contestTerms.back") }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ContestTopBar from '@/components/contests/ContestTopBar.vue'
    import contestService from '@/services/contest-service'

    export default {
        name: 'ContestTermsRoute',
        components: {
            ContestTopBar
        },
        data() {
            return {
                termsText: ''
            }
        },
        computed: {
            contest() {
                return this.$store.getters.getContest
            }
        },
        created() {
            contestService.getContestTerms(this.$route.params.contestId).then(response => {
                this.termsText = response.terms
            })
            this.onResize()
            document.addEventListener('resize', this.onResize)
        },
        beforeDestroy() {
            document.removeEventListener('resize', this.onResize)
        },
        methods: {
            goBack() {
                this.$router.back()
            },
            onResize() {
                const vh = window.innerHeight * 0.01
                document.documentElement.style.setProperty('--vh', `${vh}px`)
            }
        }
    }
</script>

<style scoped>
.text-responsive {
    background-color: white;
    border-radius: 1.25rem;
    height: calc(100 * var(--vh, 1vh) - 320px);
    overflow: auto;
    padding: 1.5rem;
}

.background-image {
    background-image: url('~@/assets/images/easter-contest-mobile-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    width: 100%;
}

.text-light-description {
    font-size: 1rem;
    color: #253e92;
    font-family: 'CFA-light', sans-serif;
}

.text-light-description ::v-deep strong {
    font-family: CFA-Normal, sans-serif;
}

.title-bold {
    font-size: 1.25rem;
    color: #102e9e;
    font-family: 'CFA-Bold', sans-serif;
}

.main-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem;
}

.text-wrapper {
    background-color: white;
    border-radius: 1.25rem;
    padding: 0.75rem;
}

.back-button {
    width: 20rem;
    cursor: pointer;
    display: flex;
    padding: 1.25rem;
    gap: 4rem;
    align-items: center;
    background-color: #00aeef;
    color: white;
    font-family: "CFA-Bold", sans-serif;
    font-size: 1.5rem;
    border-radius: 1.25rem;
    margin: 0 auto;
}

@media screen and (min-width: 768px) and (orientation: landscape) {
    .text-light-description {
        font-size: 1.5rem;
    }

    .main-container {
        margin: 25px 4.625rem 3.125rem;
        max-width: calc(100% - 32rem);
    }

    .title-bold {
        font-size: 40px;
        line-height: 42px;
    }

    .back-button-wrapper {
        position: fixed;
        bottom: 4.625rem;
        right: 4.625rem;
    }

    .back-button {
        padding: 1.25rem 1.875rem;
        font-size: 2.125rem;
        gap: 3rem;
    }

    .background-image {
        background-image: url('~@/assets/images/easter-contest-desktop-bg.png');
    }

    .text-responsive {
        height: calc(100vh - 17.75rem);
    }
}
</style>
