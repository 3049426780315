<template>
    <div class="menu-container">
        <div class="text-center" @click="hide">
            <router-link :to="`/games/profile`" class="avatar-icon">
                <img
                    v-if="$store.getters.getCurrentUser && !$store.getters.getCurrentUser.avatarUploadFile"
                    src="@/assets/avatar-placeholder.png"
                    draggable="false"
                    class="responsive-avatar"
                    alt=""
                >
                <img
                    v-else
                    :src="config.assetsHost + '/' + currentUser.avatarUploadFile.relativePath + '/'+ currentUser.avatarUploadFile.fileName"
                    draggable="false"
                    class="responsive-avatar"
                    alt=""
                >
                <div class="avatar-border" />
            </router-link>
            <div class="avatar-name mt-2 ml-1">
                {{ $store.getters.getCurrentUser.nickname }}
            </div>
        </div>
        <div class="buttons-container">
            <div>
                <router-link :to="`/games`">
                    <button class="button" @click="hide">
                        <img src="@/assets/menu5.png" class="mr-3" alt="">
                        {{ $t('menu.games') }}
                    </button>
                </router-link>
            </div>
            <div>
                <router-link :to="`/games/history`">
                    <button class="button" @click="hide">
                        <img src="@/assets/menu4.png" class="mr-3" alt="">
                        {{ $t('menu.history') }}
                    </button>
                </router-link>
            </div>
            <div>
                <router-link :to="`/games/leaderboard`">
                    <button class="button" @click="hide">
                        <img src="@/assets/menu2.png" class="mr-3" alt="">
                        {{ $t('menu.ranking') }}
                    </button>
                </router-link>
            </div>
            <div>
                <router-link :to="`/games/information`">
                    <button class="button" @click="hide">
                        <img src="@/assets/menu3.png" class="mr-3" alt="">
                        {{ $t('menu.info') }}
                    </button>
                </router-link>
            </div>
            <div>
                <router-link :to="`/games/profile`">
                    <button class="button" @click="hide">
                        <img src="@/assets/menu2.png" class="mr-3" alt="">
                        {{ $t('menu.character') }}
                    </button>
                </router-link>
            </div>
            <div v-if="false">
                <router-link :to="`/games/profile`">
                    <button class="button" @click="logOut">
                        <img src="@/assets/menu6.png" class="mr-3" alt="">
                        {{ $t('menu.logout') }}
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import config from '@/mixins/config'
    export default {
        name: 'MenuRoute',
        mixins: [config],
        computed: {
            currentUser() {
                return this.$store.getters.getCurrentUser || {}
            }
        },
        methods: {
            backToHome() {
                this.$emit('hideSubMenu')
                this.$router.push({ path: '/games' }).catch(() => {})
            },
            hide() {
                this.$emit('hideSubMenu')
            },
            logOut() {
                this.$store.dispatch('logout').then(() => {
                    this.$emit('hideSubMenu')
                    this.$router.push({ path: '/games' }).catch(() => {})
                })
            }
        }
    }
</script>

<style scoped lang="scss">
.menu-container {
    background-color: #e1eff8;
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    position: fixed;
    overflow: scroll;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.button {
    width: 366px;
    height: 58px;
    border-radius: 10px;
    background: rgba(42, 171, 226, 0.2);
    font-size: 20px;
    font-family: CFA-light, serif;
    text-align: left !important;
    color: #0d2166;
    border: none;
    font-weight: 700;
}

.avatar-name {
    font-size: clamp(20px, 3vw, 25px);
    font-family: CFA-bold, serif;
    color: #0d5198;
}

.avatar-icon {
    position: relative;
    margin-top: 45px;
    margin-bottom: 55px;
    max-width: 150px;
    width: 25%;
    height: 25%;
}

.avatar-border {
    position: absolute;
    height: 130px;
    width: 130px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    border: 8px solid #fff;
    border-radius: 130px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.buttons-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    row-gap: 10px;
}

.close-button {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}
</style>
