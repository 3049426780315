/* eslint-disable no-new */
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faQuestionCircle,
    faInfo,
    faPhone,
    faSignOutAlt,
    faBook,
    faCheckCircle,
    faChevronRight,
    faChevronLeft
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from '@/store'
import Promise from 'promise-polyfill'
import es5Shim from 'es5-shim' // eslint-disable-line no-unused-vars
import es6Shim from 'es6-shim' // eslint-disable-line no-unused-vars
import VueI18n from 'vue-i18n'
import { ToggleButton } from 'vue-js-toggle-button'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import el from '@/locale/el'

if (!window.Promise) {
    window.Promise = Promise
}

library.add(
    faQuestionCircle,
    faInfo,
    faPhone,
    faBook,
    faSignOutAlt,
    faCheckCircle,
    faChevronRight,
    faChevronLeft
)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueDOMPurifyHTML)
Vue.use(ToggleButton)
Vue.use(VueI18n)
Vue.use(Vuex)

Vue.config.productionTip = false
const messages = { el }
const i18n = new VueI18n({
    locale: 'el',
    messages
})

new Vue({
    el: '#app',
    store,
    router,
    i18n,
    render: h => h(App)
})
