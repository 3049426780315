<template>
    <div>
        <div v-if="visits" class="visits-container">
            <div class="header-container">
                <picture>
                    <source media="(max-width: 800px)" srcset="@/assets/images/visitsRoute/header_image_tablet.png">
                    <source media="(min-width: 801px)" srcset="@/assets/images/visitsRoute/header_image_desktop.png">
                    <img src="@/assets/images/visitsRoute/header_image_tablet.png" class="bg" alt="">
                </picture>
                <img src="@/assets/exit_new_button.png" class="exit-btn" alt="" @click="logOut">
            </div>
            <div class="content-container">
                <div class="card-container">
                    <img src="@/assets/ab_card.png" alt="">
                    <div class="d-flex flex-column">
                        <span class="card-number-text">ΚΩΔΙΚΟΣ ΚΑΡΤΑΣ</span>
                        <span class="card-number">0{{ $store.getters.getAbPlusNumber }}</span>
                    </div>
                </div>
                <p class="page-info-text">Κάνε τις αγορές σου στα AB και κέρδισε <span class="bold-text">6ευρες επιταγές!</span></p>
                <div class="progress-bar-container">
                    <div class="circle-star">
                        <img v-if="!isWinner()" src="@/assets/images/visitsRoute/star_deactivated.png" class="w-100 h-100" alt="">
                        <img v-else src="@/assets/images/visitsRoute/star_activated.png" class="w-100 h-100" alt="">
                    </div>
                    <div
                        v-if="!isWinner()"
                        class="circle-progress"
                        :style="{ left: ((visits.goal.amountSpent/visits.goal.firstGoal)*100) + '%' }"
                    >
                        <div class="circle" />
                        <div class="euro">
                            <span>{{ (visits.goal.amountSpent).toFixed(2) }}</span>&euro;
                        </div>
                    </div>
                    <div
                        class="progress-bar"
                        :style="{ width: `${ (visits.goal.amountSpent/visits.goal.firstGoal)*100 }%` }"
                    />
                </div>
                <div class="column-container">
                    <div class="check-container">
                        <div class="check-images">
                            <img v-if="winsTwoChecks()" src="@/assets/images/visitsRoute/dual-coupon.png" alt="">
                            <img v-else src="@/assets/images/visitsRoute/single-coupon.png" alt="">
                        </div>
                        <div class="target-euro">
                            <span>
                                {{ visits.goal.firstGoal }}&euro;
                            </span>
                        </div>
                    </div>
                    <div class="remaining-info">
                        <div v-if="visits.goal.amountSpent<visits.goal.firstGoal" class="progress-text">
                            Απομένουν ακόμα <span>{{ remainderAmount() }}&euro;</span> αγορών για να κερδίσεις
                            <span
                                v-if="winsTwoChecks()"
                            >
                                2 επιταγές 6&euro;!
                            </span>
                            <span v-else-if="!winsTwoChecks()">
                                1 επιταγή 6&euro;!
                            </span>
                        </div>
                        <div v-else class="winner-container-text">
                            <div v-if="winsTwoChecks()" class="winner-text">
                                <span>Συγχαρητήρια!</span>
                                Κέρδισες <span>2 επιταγές 6&euro;.</span>
                            </div>
                            <div v-else-if="!winsTwoChecks()" class="winner-text">
                                <span>Συγχαρητήρια!</span>
                                Κέρδισες <span>1 επιταγή 6&euro;.</span>
                            </div>
                            <img src="@/assets/images/visitsRoute/kupelo.png" alt="">
                        </div>
                        <div v-if="!isWinner()" class="last-update">
                            Τελευταία Ενημέρωση: {{ until() }}
                        </div>
                        <div v-if="!isWinner()" class="disclaimer">
                            <span>Αφορά σε συνολικό τελικό ποσό (αφαιρούμενων τυχόν εκπτώσεων και επιταγών) σε μία ή περισσότερες συναλλαγές με την κάρτα AB Plus έως {{ humanizedDate(visits.endDate) }}. Η έκδοση των επιταγών θα πραγματοποιείται με τη συμπλήρωση του ποσού. Ισχύει για αγορές σε όλα τα καταστήματα ΑΒ και στο AB eshop.
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="promoLink && promoLink.url" class="your-offers-container">
                    <img class="doudouka" src="@/assets/images/visitsRoute/doudouka.png" alt="">
                    <span v-dompurify-html="promoLink.properties.description" class="offers-text" />
                    <div class="see-offers-btn" @click="hitSpendbandStats(promoLink.url)">
                        <img src="@/assets/images/visitsRoute/karotsi.png" class="karotsi" alt="">
                        <span>{{ promoLink.properties.label }}</span>
                    </div>
                </div>
            </div>
        </div>
        <no-action-visits-component v-if="showNoActionModal" />
    </div>
</template>

<script>
    import visitsService from '@/services/visits-service'
    import promoLinkService from '@/services/promo-link-service'
    import dateTimeUtils from '@/utils/date-time-utils'
    import noActionVisitsComponent from '@/components/NoActionVisitsComponent'
    export default {
        components: { noActionVisitsComponent },
        data() {
            return {
                visits: null,
                promoLink: null,
                targetLimit: 140,
                urlCode: this.$route.params.urlCode,
                showNoActionModal: false
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isUserLoggedIn
            },
            currentUser() {
                return this.$store.getters.getCurrentUser
            }
        },
        watch: {
            currentUser: {
                immediate: true,
                handler(newVal) {
                    if (newVal) {
                        this.fetchVisits()
                    }
                }
            }
        },
        mounted() {
            if (!this.isLoggedIn && !this.urlCode) {
                this.$store.dispatch('showLoginModal')
            }
        },
        methods: {
            async fetchVisits() {
                try {
                    const visitsCount = await visitsService.fetchUserVisitsCount()
                    if (!visitsCount.goal) {
                        this.showNoActionModal = true
                        return
                    }
                    this.visits = visitsCount
                    const promoLinkResponse = await promoLinkService.fetchPromoLink(visitsCount.offerId)
                    if (promoLinkResponse.content.length > 0) {
                        this.promoLink = promoLinkResponse.content[0]
                    }
                } catch (error) {
                    if (error.response.status !== 400) {
                        return
                    }
                    this.showNoActionModal = true
                }
            },
            remainderAmount() {
                return Math.round((this.visits.goal.firstGoal - this.visits.goal.amountSpent) * 100) / 100
            },
            isWinner() {
                return this.visits.goal.amountSpent >= this.visits.goal.firstGoal
            },
            winsTwoChecks() {
                return this.visits.goal.firstGoal > this.targetLimit
            },
            until() {
                return dateTimeUtils.getPreviousDaySkipSundays()
            },
            humanizedDate(date) {
                return dateTimeUtils.getHumanizedDate(date)
            },
            async hitSpendbandStats(promoLinkUrl) {
                await visitsService.submitPromoLinkStat()
                window.location.href = promoLinkUrl
            },
            logOut() {
                this.visits = null
                this.promoLink = null
                this.$store.dispatch('logout')
                this.$store.dispatch('showLoginModal')
            }
        }
    }
</script>

<style scoped>
.visits-container {
    font-family: 'OpenSansBold';
    max-width: 1920px;
    margin: 0 auto;
}

.header-container {
    position: relative;
}

.header-container .bg {
    max-width: 100%;
    height: auto;
}

.header-container .exit-btn {
    position: absolute;
    top: 3%;
    right: 3%;
    width: 60px;
    height: 60px;
    cursor: pointer;
}

.content-container {
    padding: 0 5%;
}

.card-container {
    padding: 5% 0 0;
    display: flex;
    align-items: center;
    max-width: 280px;
}

.card-container img {
    max-width: 45px;
    margin-right: 0.6rem;
}

.card-number-text {
    font-size: 0.7rem;
    color: #9c9ea0;
}

.card-number {
    font-size: 0.875rem;
    font-family: "OpenSansRegular";
    font-weight: bold;
    color: #39aabe;
}

.page-info-text {
    font-size: 1.25rem;
    color: #0c52a0;
    font-family: "OpenSansRegular";
    font-weight: bold;
    margin: 1rem 0 2rem;
}

.page-info-text span {
    font-family: "OpenSansBold";
}

.progress-bar-container {
    margin: 0 auto 0 1.5rem;
    width: 85%;
    border-radius: 20px;
    background-color: #dee1e0;
    box-shadow: inset 0 5px 7px 0 rgba(0, 0, 0, 0.72);
    position: relative;
    height: 22px;
}

.circle-progress {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.circle {
    width: 120px;
    height: 60px;
    border-radius: 50%;
    background-color: #e5edef;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle-progress .euro {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    font-size: 1.563rem;
    font-weight: bold;
    color: #0c52a0;
}

.circle-star {
    position: absolute;
    right: 0%;
    top: 50%;
    transform: translate(50%, -50%);
}

.progress-bar {
    height: 100%;
    border-radius: 20px;
    background-color: #0c52a0;
}

.column-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 2rem;
}

.check-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
}

.check-images {
    margin-top: 0.5rem;
    display: flex;
    align-items: flex-start;
    order: 2;
    max-width: 80px;
}

.check-images img {
    width: 100%;
    height: auto;
}

.target-euro {
    font-size: 2rem;
    line-height: 1.8rem;
    color: #0c52a0;
    font-family: "ProximaBold";
    font-weight: bold;
}

.remaining-info {
    width: 100%;
    align-self: center;
    order: 2;
    font-family: "OpenSansRegular";
    background-color: #00a7e6;
    padding: 23px;
    border-radius: 20px;
    color: #fff;
}

.remaining-info .progress-text {
    font-size: 1.438rem;
    line-height: 1.6rem;
}

.winner-container-text {
    display: flex;
    align-items: center;
}

.winner-text {
    font-family: "OpenSansRegular";
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.9rem;
}

.winner-container-text img {
    max-width: 75px;
}

.progress-text span,
.winner-text span {
    font-family: "OpenSansBold";
    font-weight: bold;
}

.disclaimer,
.last-update {
    font-family: "OpenSansRegular";
    font-style: italic;
    font-size: 0.813rem;
    line-height: 0.95rem;
    margin-top: 0.5rem;
    text-align: justify;
}

.last-update {
    margin-top: 1.5rem;
    font-family: "OpenSansBold";
    font-weight: bold;
}

.your-offers-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.offers-text {
    margin-bottom: 1.25rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: "OpenSansRegular";
    font-weight: bold;
    color: #0c529f;
    text-align: center;
}

.see-offers-btn {
    width: 100%;
    background-color: #0c529f;
    color: #fff;
    font-family: "OpenSansBold";
    font-weight: bold;
    font-size: 1.1rem;
    padding: 1rem 1.5rem;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 1rem;
    cursor: pointer;
}

.see-offers-btn:hover {
    text-decoration: none;
}

.karotsi {
    margin-right: 1rem;
    max-width: 40px;
}

@media only screen and (min-width: 600px) {
    .progress-bar-container {
        margin: 0 auto 0 1rem;
        width: 93%;
    }
}

@media only screen and (min-width: 900px) {
    .card-container {
        padding: 0.8rem 0;
    }

    .card-container img {
        max-width: 75px;
    }

    .card-number {
        font-size: 1.1rem;
    }

    .content-container {
        padding: 0 3.5%;
    }

    .header-container .exit-btn {
        width: initial;
        height: initial;
    }

    .page-info-text {
        margin: 0 0 2rem;
        font-size: 2.3rem;
    }

    .progress-bar-container {
        margin: 0;
        width: 97%;
    }

    .column-container {
        position: relative;
        flex-direction: row;
        margin-top: 2rem;
        align-items: flex-start;
    }

    .check-images {
        margin-top: 0;
    }

    .remaining-info {
        width: 65%;
        padding: 18px;

        /* order: 1; */
    }

    .check-container {
        position: absolute;
        right: 0;
        flex-direction: column;
        align-items: flex-end;
        order: 2;
    }

    .target-euro {
        margin-bottom: 1rem;
        font-size: 2.5rem;
    }

    .winner-text {
        font-family: "OpenSansRegular";
        font-weight: normal;
        font-size: 1.688rem;
        line-height: 2.25rem;
    }

    .winner-container-text img {
        margin-left: 1rem;
        max-width: 60px;
    }

    .disclaimer {
        font-family: "OpenSansRegular";
        font-weight: bold;
    }

    .last-update {
        font-family: "OpenSansBold";
        font-weight: bold;
        font-style: normal;
    }

    .your-offers-container {
        margin-top: 0.3rem;
        flex-direction: row;
        max-width: 65%;
        border: 1px solid #0c529f;
        border-radius: 20px;
        padding: 0;
    }

    .doudouka {
        margin-left: 0.5rem;
    }

    .offers-text {
        margin-bottom: 0;
        font-family: "OpenSansBold";
        padding: 0 1rem;
        text-align: left;
    }

    .see-offers-btn {
        font-size: 1.375rem;
        min-width: 300px;
        max-width: 380px;
        margin-bottom: 0;
        margin-left: auto;
    }

    .karotsi {
        max-width: 45px;
    }
}
</style>
