<template>
    <div v-if="contest" class="redeem-container">
        <contest-top-bar class="top-bar" />
        <div v-if="!error" class="content">
            <div
                v-if="showPrizeAnimation"
                class="redeem-animation gift-animation"
                :style="{
                    'background-image': `url(${require('@/assets/images/gifs/Easter_animation_big_W_BG_HighQ.gif')}${animationQuery})`
                }"
            />
            <div
                v-if="loading"
                class="redeem-animation loading-animation"
                :style="{
                    'background-image': `url(${require('@/assets/images/gifs/Easter_animation_small_CLEAN.gif')}${animationQuery})`
                }"
            />
            <div v-else class="result">
                <value-campaign-tile class="redeem-tile" :class="{ full: showFullPrize }">
                    <div class="redeem-tile-container">
                        <div class="prize" :style="prizeStyle" />
                        <div class="text">
                            <div class="intro">
                                <div class="checkout-date">{{ $t('contestRedeem.transaction', { date: checkoutDate }) }}</div>
                                <div>
                                    <div class="congrats">
                                        {{ $t(`contestRedeem.${ hasGift ? 'winPrize' : 'winNoPrize' }`) }}
                                    </div>
                                    <div v-if="isGiftFreeProduct" class="free-product">
                                        {{ $t('contestRedeem.freeProduct') }}
                                    </div>
                                </div>
                            </div>
                            <div class="prize-description">
                                {{ giftDescription || $t('contestRedeem.bigGiftParticipation') }}
                            </div>
                        </div>
                    </div>
                </value-campaign-tile>
                <div class="next-participation">
                    <div v-if="showGiftInfo">
                        <div
                            v-if="remainingParticipations.length > 0"
                            v-dompurify-html="
                                $t('contestRedeem.remainingParticipations', {
                                    count: remainingParticipations.length
                                })
                            "
                            class="remaining-participations"
                        />
                        <div
                            v-else
                            v-dompurify-html="
                                $t('contestRedeem.noRemainingParticipations')
                            "
                            class="remaining-participations"
                        />
                    </div>
                    <div
                        v-if="showGiftInfo"
                        class="next-participation-btn btn"
                        :class="{ disabled: remainingParticipations.length <= 0 }"
                        @click="redeemNext"
                    >
                        <span>{{ $t('contestRedeem.nextParticipation') }}</span>
                        <font-awesome-icon class="btn-icon" :icon="['fas', 'chevron-right']" />
                    </div>
                </div>
                <div v-if="showGiftInfo" class="disclaimer">
                    {{ contest.winLoseDisclaimer }}
                </div>
            </div>
        </div>
        <div
            v-else
            class="error-message-container"
        >
            <div class="mt-1 error-message-light">
                <div>
                    {{ $t('contestRedeem.error') }}
                </div>
                <div class="try-again">
                    {{ $t("contestRedeem.tryAgain") }}
                </div>
            </div>
            <div class="align-center text-responsive">
                <div class="back-btn" @click="goBack">
                    <font-awesome-icon class="back-btn-icon" :icon="['fas', 'chevron-left']" />
                    <span class="back-btn-text">{{ $t('contestRedeem.back') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import ContestTopBar from '@/components/contests/ContestTopBar.vue'
    import ValueCampaignTile from '@/components/contests/ValueCampaignTile.vue'
    import contestService from '@/services/contest-service'
    import config from '@/mixins/config'

    export default {
        components: { ValueCampaignTile, ContestTopBar },
        mixins: [config],
        data() {
            return {
                loading: true,
                canShowPrizeAnimation: false,
                showPrizeAnimation: false,
                showGiftInfo: false,
                suspenseBuilt: false,
                showFullPrize: false,
                error: false,
                didRetry: false,
                results: [],
                assetsLoaded: 0,
                animationQuery: `?p=${Date.now()}`
            }
        },
        computed: {
            isGiftFreeProduct() {
                return this.hasGift && this.gift.giftCategory.giftType === 'FREE_PRODUCTS'
            },
            prizeStyle() {
                return {
                    backgroundImage: `url(${this.giftImageUrl})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'contain'
                }
            },
            giftImageUrl() {
                if (this.gift && this.gift.giftCategory && this.gift.giftCategory.image) {
                    return `${this.config.assetsHost}/${this.gift.giftCategory.image.relativePath}/${this.gift.giftCategory.image.fileName}`
                }
                return require('@/assets/images/PHOTOS_350x350px_32.png')
            },
            giftDescription() {
                if (!this.gift) {
                    return null
                }
                return this.gift.giftCategory.description
            },
            contest() {
                return this.$store.getters.getContest
            },
            remainingParticipations() {
                return this.contest.participations.filter(participation => !participation.redeemed)
            },
            checkoutDate() {
                if (this.results.length === 0) {
                    return null
                }
                return moment(this.results[this.results.length - 1].checkoutDate).format('DD/MM/YYYY')
            },
            gift() {
                if (this.results.length === 0) {
                    return null
                }
                return this.results[this.results.length - 1].gift
            },
            hasGift() {
                return !!this.gift
            },
            canShowGiftAnimation() {
                return this.suspenseBuilt && this.canShowPrizeAnimation
            }
        },
        watch: {
            assetsLoaded() {
                if (this.assetsLoaded === 2) {
                    this.startAnimations()
                }
            },
            canShowGiftAnimation(newValue) {
                if (newValue) {
                    this.showPrizeAnimation = true
                    setTimeout(() => {
                        this.loading = false
                    }, 100)
                    setTimeout(() => {
                        this.showFullPrize = true
                    }, 7500)
                    setTimeout(() => {
                        this.canShowPrizeAnimation = false
                        this.showPrizeAnimation = false
                        this.showGiftInfo = true
                        this.$nextTick(() => {
                            this.animationQuery = `?p=${Date.now()}`
                            this.loadingAnimation = new Image()
                            this.loadingAnimation.src =
                                require('@/assets/images/gifs/Easter_animation_small_CLEAN.gif') +
                                this.animationQuery
                            this.giftAnimation = new Image()
                            this.giftAnimation.src =
                                require('@/assets/images/gifs/Easter_animation_big_W_BG_HighQ.gif') +
                                this.animationQuery
                        })
                    }, 9360)
                }
            }
        },
        created() {
            // Image preloading
            this.loadingAnimation = new Image()
            this.loadingAnimation.src = require('@/assets/images/gifs/Easter_animation_small_CLEAN.gif') + this.animationQuery
            this.loadingAnimation.onload = () => {
                this.assetsLoaded += 1
            }
            this.giftAnimation = new Image()
            this.giftAnimation.src = require('@/assets/images/gifs/Easter_animation_big_W_BG_HighQ.gif') + this.animationQuery
            this.giftAnimation.onload = () => {
                this.assetsLoaded += 1
            }
        },
        methods: {
            startAnimations() {
                if (
                    this.contest &&
                    this.remainingParticipations.length > 0 &&
                    !this.error
                ) {
                    this.redeemNext(true)
                }
            },
            redeemNext(first = false) {
                if (!this.loading || first) {
                    this.loading = true
                    this.canShowPrizeAnimation = false
                    this.showPrizeAnimation = false
                    this.showGiftInfo = false
                    this.suspenseBuilt = false
                    this.showFullPrize = false
                    this.didRetry = false
                    setTimeout(() => {
                        this.suspenseBuilt = true
                    }, 2000)
                }
                const participationToRedeem = this.remainingParticipations[this.remainingParticipations.length - 1]
                contestService.redeemParticipation(participationToRedeem.id)
                    .then(response => {
                        this.didRetry = false
                        this.canShowPrizeAnimation = true
                        this.results.push(response.content[0])
                        this.$store.dispatch('redeemParticipation', participationToRedeem.id)
                    })
                    .catch(e => {
                        // Error codes
                        // NO_CUSTOMER_FOUND
                        // NO_PARTICIPATION_FOUND
                        // NO_CONTEST_FOUND
                        // CONTEST_NOT_ACTIVE
                        // NO_MORE_CODES_AVAILABLE
                        // REDEMPTION_FAILED
                        const errorCode = e?.response?.data?.code
                        if (!this.didRetry && errorCode === 'REDEMPTION_FAILED') {
                            this.didRetry = true
                            this.redeemNext()
                        } else {
                            this.error = true
                        }
                    })
            },
            goBack() {
                this.$router.back()
            }
        }
    }
</script>

<style scoped>
.top-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
}

.redeem-container {
    background: url('~@/assets/images/easter-contest-mobile-bg.png') no-repeat center center;
    background-size: cover;
    min-height: 100vh;
    color: #014bd2;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    min-height: 100vh;
}

.redeem-animation {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 100vw;
    height: min(75vh, 100vmin);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
}

.loading-animation {
    background-image: url("~@/assets/images/gifs/Easter_animation_small_CLEAN.gif");
}

.gift-animation {
    z-index: 3;
}

.result {
    padding: 8rem 1rem;
    gap: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.redeem-tile {
    scale: 0;
    width: 40rem;
    height: auto;
    max-width: 80vw;
    transition: all 3s ease-in-out;
}

.redeem-tile.full {
    scale: 1;
}

.redeem-tile-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 2.25rem;
    margin: auto 0;
    justify-content: space-between;
    line-height: 1.05;
}

.redeem-container .checkout-date {
    font-size: 22px;
}

.redeem-container .free-product {
    font-size: 32px;
}

.redeem-tile-container .prize {
    width: 21.875rem;
    height: 21.875rem;
    background-color: #bae5f7;
    border-radius: 1.25rem;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
}

.redeem-tile-container .text {
    width: 22.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: CFA-Light, sans-serif;
    font-size: 2rem;
    color: #014bd2;
}

.redeem-tile-container .text .intro {
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
}

.redeem-tile-container .text .intro .congrats {
    font-family: CFA-Bold, sans-serif;
    font-size: 2.5rem;
}

.redeem-tile-container .text .prize-description {
    font-size: 38px;
    font-family: CFA-Light, sans-serif;
    height: 100%;
    display: flex;
    align-items: flex-end;
}

.remaining-participations {
    text-align: center;
}

.next-participation-btn {
    color: white;
    font-family: "CFA-Bold", sans-serif;
    font-size: 1.5rem;
    padding: 1rem;
    width: 30rem;
    max-width: calc(100vw - 2rem);
    height: 5rem;
    background-color: #00aeef;
    display: flex;
    border-radius: 1.25rem;
    justify-content: space-between;
    align-items: center;
}

.disclaimer {
    text-align: center;
}

.next-participation-btn.disabled {
    background-color: #efefef;
    color: #696969;
    border: 1px solid #efefef;
    cursor: not-allowed;
    pointer-events: none;
}

.error-message-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.error-message-light {
    font-family: CFA-Bold, sans-serif;
    font-size: 3rem;
    text-align: center;
    color: #102e9e;
}

.try-again {
    color: #0855f3;
    font-size: 1.5rem;
    font-family: CFA-Light, sans-serif;
    margin-bottom: 1rem;
}

.back-btn {
    color: white;
    font-size: 1.75rem;
    background-color: #00aeef;
    padding: 1.5rem;
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.back-btn .back-btn-icon {
    align-items: flex-start;
    margin-left: 0;
}

.back-btn .back-btn-text {
    margin: auto;
}

@media (min-width: 769px) {
    .redeem-container {
        background-image: url("~@/assets/images/easter-contest-desktop-bg.png");
    }

    .redeem-animation {
        height: 100vh;
    }

    .next-participation-btn {
        padding: 1.875rem;
        margin-bottom: 3rem;
        font-size: 2.125rem;
        height: 6rem;
    }

    .result {
        padding: 10rem 2rem 2rem;
        margin: 0 auto;
    }

    .disclaimer {
        text-align: start;
    }

    .error-message-container {
        gap: 2rem;
    }

    .error-message-light {
        font-size: 5rem;
    }

    .back-btn {
        font-size: 2.125rem;
        width: 33rem;
    }
}
</style>
