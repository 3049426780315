<template>
    <div>
        <div class="panel text-center">
            <coupons-route :offer="offerData" :coupons="offerData.coupons" />
        </div>
        <div class="copyright pt-2">
            {{ $t('coupon.copyright') }}
        </div>
    </div>
</template>

<script>
    import CouponsRoute from './CouponsRoute'

    export default {
        components: {
            CouponsRoute
        },
        props: {
            offerData: { type: Object, required: true }
        }
    }
</script>

<style scoped>
    /* .panel {
        margin: 15px;
        padding: 4px;
    } */

    .copyright {
        font-family: "CFA-Bold", sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 15px;
        background: #fff;
        color: #39aabe;
        text-align: right;
        letter-spacing: 0.5px;
        height: 29px;
        padding: 10px;
    }
</style>
