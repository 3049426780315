<template>
    <div
        v-if="isConsentModalVisible"
        class="modal-body"
    >
        <b-container class="p-0 container-modal">
            <div class="picture-wrapper">
                <picture class="text-center">
                    <source media="(min-width: 1080px)" srcset="@/assets/ab_plus_consent_big.jpg">
                    <source media="(orientation: landscape) and (max-height: 500px)" srcset="@/assets/ab_plus_consent_big.jpg">
                    <img
                        src="@/assets/ab_plus_consent_small.png"
                        alt="logo_image"
                        class="consent-image px-5"
                    >
                </picture>
                <div class="mt-xl-0 buttons-wrapper px-lg-5">
                    <div class="my-2 ml-xl-5">
                        <button
                            size="lg"
                            class="approve-button"
                            @click="consentSelected(true)"
                        >
                            {{ $t('consentModal.approve') }}
                        </button>
                    </div>
                    <div class="my-2 mr-xl-5">
                        <button
                            size="lg"
                            nowrap
                            class="dont-approve-button"
                            @click="consentSelected(false)"
                        >
                            {{ $t('consentModal.reject') }}
                        </button>
                    </div>
                </div>
                <b-row>
                    <b-col class="d-flex justify-content-center">
                        <span class="error-message">{{ errorMessage }}</span>
                    </b-col>
                </b-row>
            </div>
        </b-container>
    </div>
</template>

<script>
    import consentService from '@/services/consent-service'
    export default {
        data() {
            return {
                errorMessage: ''
            }
        },
        computed: {
            isConsentModalVisible() {
                return this.$store.getters.isConsentModalVisible
            }
        },
        async created() {

        },
        beforeDestroy() {
            this.$store.dispatch('hideConsentModal')
        },
        methods: {
            consentSelected(accepted) {
                const consentDetails = this.$store.getters.getConsentDetails
                consentService.postConsent(consentDetails.consentWay, accepted).then(() => {
                }, () => {
                    this.errorMessage = 'Κάποιο σφάλμα συνέβη. Προσπαθήστε αργότερα πάλι.'
                }).finally(() => {
                    this.$store.dispatch('hideConsentModal')
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

    .modal-body {
        background-color: #112468;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        z-index: 1000;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .approve-button {
        max-width: 300px;
        width: 30vmin;
        min-width: 200px;
        font-size: 16px;
        color: #fff;
        font-family: 'CFA-Light', sans-serif;
        background: #00aeef;
        border-radius: 9px;
        border: none;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .dont-approve-button {
        max-width: 300px;
        width: 30vmin;
        min-width: 200px;
        font-size: 16px;
        color: #2aabe2;
        font-family: 'CFA-Light', sans-serif;
        background: #112468;
        border-radius: 9px;
        border: 2px solid #019fe3;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .consent-image {
        max-height: 1080px;
        width: 100%;
    }

    .buttons-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .container-modal {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .picture-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .error-message {
        color: #f00;
        background-color: #fff;
        font-size: 1.2em;
        font-weight: 700;
    }

    @media screen and (max-width: 1079px) {
        .consent-image {
            height: 80vh;
            width: auto;
        }

        .buttons-wrapper {
            justify-content: space-evenly;
        }

        button.btn {
            font-size: calc(0.55em + 1.3vmin);
            width: 25vmin;
            min-width: 115px;
        }

        .error-message {
            font-size: calc(0.5em + 1.2vmin);
        }
    }

    @media screen and (orientation: landscape) and (max-height: 500px) {
        .consent-image {
            width: 100%;
            height: auto;
        }
    }

</style>
