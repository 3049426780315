<template>
    <div>
        <div class="step-header-text mb-4">
            <div class="number-container">
                <div class="step-number"> 1 </div>
            </div>
            <div class="responsive-text-info">{{ $t('editProfile.selectAvatar') }}</div>
        </div>
        <div class="avatar-selection">
            <div class="avatar-container">
                <div v-for="avatar of avatars" :key="avatar.position" class="avatar-buttons p-0">
                    <img
                        :src="config.assetsHost + '/' + avatar.image.relativePath + '/'+ avatar.image.fileName"
                        alt=""
                        draggable="false"
                        class="img-responsive"
                        @click="selectAvatar(avatar)"
                    >
                    <div v-if="currentUser && currentUser.avatarUploadFile">
                        <div :class="{avatarSelectedBorder:avatar.image.id === currentUser.avatarUploadFile.id}" />
                        <b-icon v-if="avatar.image.id === currentUser.avatarUploadFile.id" icon="check-circle-fill" class="avatar-check-image-background" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import config from '@/mixins/config'
    import avatarService from '@/services/avatar-service'
    export default {
        name: 'GameSelectedAvatar',
        mixins: [config],
        data() {
            return {
                avatars: []
            }
        },
        computed: {
            currentUser() {
                return this.$store.getters.getCurrentUser
            }
        },
        created() {
            this.getAvailableAvatars()
        },
        methods: {
            getAvailableAvatars() {
                avatarService.fetchAvatars().then((avatars) => {
                    this.avatars = avatars.content
                })
            },
            selectAvatar(avatar) {
                this.currentUser.avatarUploadFile = avatar.image
                this.$store.dispatch('updateUserProfile', this.currentUser)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .avatar-container {
        overflow-x: auto;
        width: 90%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .avatar-selection {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
    }

    .step-number {
        background: #ea1d24;
        border-radius: 50%;
        color: #fff;
        width: 25px;
        height: 25px;
        font-weight: 700;
        font-size: 18px;
        line-height: 29px;
        margin-left: 5px;
    }

    .number-container {
        width: 30px;
    }

    .step-header-text {
        display: flex;
        font-size: clamp(22px, 1.5vw, 28px);
        font-family: 'CFA-light';
        align-items: center;
        font-weight: 700;
        color: #102e9e;
        justify-content: flex-start;
        column-gap: 20px;
        padding-left: 20px;
    }

    .step-title-header {
        font-size: clamp(15px, 1.5vw, 30px);
        font-family: 'CFA-light';
        color: #005d81;
    }

    .img-responsive {
        width: 6vw;
        min-width: 61px;
        max-width: 115px;
    }

    .game-number-text {
        width: 4vh;
        height: 4vh;
        border-radius: 4vh;
        background: #0d519a;
        color: #f8fbfd;
        font-size: 3vh;
        font-weight: 700;
        font-family: 'CFA-Bold';
    }

    .avatarSelectedBorder {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border: 5px solid #0d5198;
        border-radius: 100%;
    }

    .avatar-check-image-background {
        position: absolute;
        top: 0;
        width: 2vw;
        height: 2vw;
        color: #0d5198;
        right: 0;
        background-color: #fff;
        border-radius: 100%;
    }

    .vertical-align {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    .avatar-buttons {
        position: relative;
        border: none;
        outline: none;
    }

    @media screen and (max-width: 1600px) {
        .step-header-text {
            padding: 0;
        }

        .avatar-container {
            width: 100%;
            flex-wrap: nowrap;
            justify-content: flex-start;
            gap: 1%;
            padding: 0 0 1rem 0;
        }

        .responsive-text-info {
            text-align: left;
        }

        .avatar-selection {
            justify-content: left;
        }

        .avatar-check-image-background {
            width: 3vw;
            height: 3vw;
            min-width: 20px;
            min-height: 20px;
        }

        ::-webkit-scrollbar {
            width: 5px;
            height: 10px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #0d519a;
            border-radius: 10px;
        }

        ::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 10px;
        }
    }

</style>
