import restAPI from '@/utils/rest-api'

export default {
    fetchUserVisitsCount() {
        return restAPI.get('/visits')
    },
    submitPromoLinkStat() {
        return restAPI.post('/promo-link-stats/spendband')
    }
}
