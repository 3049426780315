<template>
    <b-container fluid class="background-image">
        <affinity-top-bar :disable-home="false" />
        <div class="main-container">
            <b-row class="align-center">
                <b-col md="7">
                    <div class="text-responsive">
                        <div class="more-info mb-2">{{ $t('affinityOffer.more') }}</div>
                        <div class="title-bold mb-4">Κατέβασε το COSMOTE Αpplication και δες τα διαθέσιμα εκπτωτικά κουπόνια:</div>
                        <ol class="text-light-description">
                            <li>Μπες στην ενότητα For You στην κατηγορία SUPERMARKET DEALS</li>
                            <li>Βρες τα αποκλειστικά εκπτωτικά κουπόνια που σε ενδιαφέρουν και δημιούργησε ένα ενιαίο κουπόνι</li>
                            <li>Πρόσθεσε τον κωδικό του ενιαίου κουπονιού SUPERMARKET DEALS της COSMOTE στην AB Plus κάρτα σου είτε στο kiosk των καταστημάτων, είτε μέσω eshop ή mobile app στην ενότητα Συνεργασίες - COSMOTE Deals</li>
                            <li>Κάνε τις αγορές σου σε ένα κατάστημα ΑΒ ή στο eshop ή στο mobile app με χρήση της AB Plus κάρτας σου</li>
                            <li>Η έκπτωση από τα κουπόνια COSMOTE θα αφαιρεθεί αυτόματα από τα προϊόντα που επέλεξες κατά το check out</li>
                        </ol>
                        <img src="@/assets/images/back_button.png" class="back-button mb-4" @click="goBack">
                    </div>
                </b-col>
                <b-col md="5" class="d-none d-md-block">
                    <img src="@/assets/cosmote_more.png" class="more-info-image">
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
    import config from '@/mixins/config'
    import AffinityTopBar from '@/components/AffinityTopBar'

    export default {
        name: 'AffinityMoreInfoRoute',
        components: {
            AffinityTopBar
        },
        mixins: [config],
        methods: {
            goBack() {
                this.$router.back()
            }
        }
    }
</script>
<style scoped lang="scss">

.align-center {
    align-items: center;
}

.background-image {
    background: url('~@/assets/images/affinity_bg.jpg');
    background-repeat: repeat;
    height: 100%;
    position: fixed;
    width: 100%;
    overflow: scroll;
}

.more-info-image {
    max-width: 100%;
    margin-bottom: 15px;
}

.more-info {
    font-size: 16px;
    font-weight: 700;
    color: #102e9e;
    font-family: 'CFA-Bold';
    white-space: pre;
}

.text-light-description {
    font-size: 25px;
    color: #253e92;
    font-family: 'CFA-light';
    white-space: pre-line;
}

.title-bold {
    font-size: 40px;
    line-height: 50px;
    color: #102e9e;
    font-family: 'CFA-Bold';
}

.main-container {
    margin-top: 25px;
}

.back-button {
    width: 350px;
    cursor: pointer;
}

@media screen and (max-width: 1600px) {
    .text-light-description {
        font-size: 16px;
    }
}

@media screen and (min-width: 1199px) {
    .main-container {
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media screen and (max-width: 990px) {
    .back-button {
        width: 218px;
    }
}
</style>
