import moment from 'moment'
import contentService from '@/services/consent-service'

function oneHourHasPassedSinceConsent() {
    const lastConsentModalDisplay = localStorage.getItem('lastConsentModalDisplay')
    if (lastConsentModalDisplay) {
        const duration = moment.duration(moment().diff(moment(lastConsentModalDisplay)))
        return duration.asHours() > 1
    }
    return true
}
function dispatchEvents(commit, consent) {
    commit('setVisibleConsentModal', { consent, consentModalVisible: true })
    localStorage.setItem('lastConsentModalDisplay', moment().toISOString())
}

export default {
    state: {
        consentModalVisible: false,
        consent: {}
    },
    mutations: {
        setVisibleConsentModal(state, payload) {
            state.consentModalVisible = payload.consentModalVisible
            state.consent = payload.consent
        }
    },
    actions: {
        showConsentModal({ commit }, consent) {
            contentService.getConsent().then(res => {
                const showConsent = oneHourHasPassedSinceConsent() && (!res || (!res.hasConsent && res.numberOfRejections < 4))
                if (showConsent) {
                    dispatchEvents(commit, consent)
                }
            }, err => {
                if (err.response.status === 404) {
                    dispatchEvents(commit, consent)
                }
            })
        },
        hideConsentModal({ commit }) {
            commit('setVisibleConsentModal', { consent: {}, consentModalVisible: false })
        }
    }
}
