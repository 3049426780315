<template>
    <div class="background">
        <div class="list-container">
            <div class="header-container">
                <div class="header-top">
                    <div>
                        <img src="@/assets/megaphone_left.png" class="megaphone">
                    </div>
                    <div class="px-3">
                        <div class="header">
                            {{ $t('couponHeader.header') }}
                        </div>
                        <div class="valid-until">
                            {{ $t('couponHeader.validUntil') }} {{ dateTimeUtils.dateFormatter(offer.endDate) }}
                        </div>
                    </div>
                    <div>
                        <img src="@/assets/megaphone_right.png" class="megaphone">
                    </div>
                </div>
                <div class="coupons-number">
                    {{ activatedCoupons }} {{ $t('couponHeader.from') }} {{ offer.coupons.length }} {{ $t('couponHeader.active') }}
                </div>
            </div>
            <div class="instruction-outer-container my-3">
                <span class="instruction-container">
                    <div class="step-number">1.</div>
                    <div class="bold-instruction">{{ $t('couponHeader.firstInstruction')[0] }}</div>
                    <div class="light-instruction">{{ $t('couponHeader.firstInstruction')[1] }}</div>
                </span>
                <span class="instruction-container">
                    <div class="step-number">2.</div>
                    <div class="bold-instruction">{{ $t('couponHeader.secondInstruction')[0] }}</div>
                    <div class="light-instruction">{{ $t('couponHeader.secondInstruction')[1] }}</div>
                </span>
                <span class="instruction-container">
                    <div class="step-number">3.</div>
                    <div class="bold-instruction">{{ $t('couponHeader.thirdInstruction')[0] }}</div>
                    <div class="light-instruction">{{ $t('couponHeader.thirdInstruction')[1] }}</div>
                </span>
            </div>
        </div>
        <div class="coupons-container">
            <div
                v-for="coupon in coupons"
                :key="coupon.id"
            >
                <div class="coupon-container">
                    <div class="image-redeemed">
                        <img
                            class="coupon"
                            :src="coupon.colorPath"
                            :class="[{'opacity-changer': coupon.cashRedemptionDate},{ 'activated': (coupon.redemptionWay || coupon.isFlipped) && !coupon.cashRedemptionDate}]"
                            @click="activateCoupon(coupon)"
                        >
                        <div v-if="coupon.cashRedemptionDate && coupon.cashRedemptionKiosk" class="coupon-redeemed">
                            <img v-if="coupon.cashRedemptionKiosk && coupon.cashRedemptionKiosk.storeCode === 686" src="@/assets/eshop.png" alt="">
                            <img v-else src="@/assets/shop.png" alt="" draggable="false">
                            <p v-if="!coupon.cashRedemptionKiosk" class="header-shop-text">{{ $t('webCoupons.fanchise') }}</p>
                            <p v-if="coupon.cashRedemptionKiosk.storeCode !== 686" class="header-shop-text">{{ $t('webCoupons.shop') }}</p>
                            <p v-else class="header-shop-text">{{ $t('webCoupons.eshop') }}</p>
                            <div v-if="coupon.cashRedemptionKiosk.storeCode === 686" class="simple-shop-text">
                                <div>{{ $t('webCoupons.eshopText')[0] }}</div>
                                <div>{{ $t('webCoupons.eshopText')[1] }}</div>
                            </div>
                            <div v-else>
                                <div class="simple-shop-text" :style="{visibility: coupon.cashRedemptionKiosk.storeCode !== 686 ? 'visible' : 'hidden'}">{{ coupon.cashRedemptionKiosk.address }}</div>
                                <div class="simple-shop-text" :style="{visibility: coupon.cashRedemptionKiosk.storeCode !== 686 ? 'visible' : 'hidden'}">{{ coupon.cashRedemptionKiosk.region }}</div>
                            </div>
                        </div>
                        <div v-if="!coupon.cashRedemptionKiosk && coupon.cashRedemptionDate" class="coupon-redeemed">
                            <img src="@/assets/shop.png" alt="" class="mt-n5">
                            <p v-if="!coupon.cashRedemptionKiosk" class="header-shop-text">{{ $t('webCoupons.franchise') }}</p>
                        </div>
                    </div>
                    <div v-if="coupon.cashRedemptionDate" class="button-cash-redemption">
                        {{ $t('webCoupons.cashRedemption') }}
                        {{ dateTimeUtils.dateFormatter(coupon.cashRedemptionDate) }}
                    </div>
                    <div v-if="coupon.redemptionDate && !coupon.cashRedemptionDate" class="button-activated">
                        {{ $t('webCoupons.activatedButton') }}
                        {{ dateTimeUtils.dateFormatter(coupon.redemptionDate) }}
                    </div>
                    <b-button v-if="!coupon.redemptionDate && !coupon.cashRedemptionDate" class="activate-button" :disabled="coupon.redemptionWay" @click="activateCoupon(coupon)">
                        {{ $t('webCoupons.pressButton') }}<img class="" src="@/assets/arrow.png" alt=" " draggable="false">
                    </b-button>
                </div>
            </div>
        </div>
        <div v-if="!promoLinks.length" class="bottom-container-no-promolinks">
            <div class="disclaimer-no-promolinks mb-4">
                <div>{{ $t('webCoupons.disclaimer[0]') }}</div>
                <div>{{ $t('webCoupons.disclaimer[1]') }}</div>
                <div class="small-text">{{ $t('webCoupons.disclaimer[2]') }}</div>
                <div class="small-text">{{ $t('webCoupons.disclaimer[3]') }}</div>
                <div class="small-text">{{ $t('webCoupons.disclaimer[4]') }}</div>
            </div>
        </div>
        <div v-else class="bottom-container">
            <div
                class="promo-link"
            >
                <div
                    v-for="(promoLink,index) in promoLinks"
                    :key="index"
                >
                    <promo-link :promo-data="promoLink" />
                </div>
            </div>
            <div class="disclaimer-container mb-4">
                <div>{{ $t('webCoupons.disclaimer[0]') }}</div>
                <div>{{ $t('webCoupons.disclaimer[1]') }}</div>
                <div class="small-text">{{ $t('webCoupons.disclaimer[2]') }}</div>
                <div class="small-text">{{ $t('webCoupons.disclaimer[3]') }}</div>
                <div class="small-text">{{ $t('webCoupons.disclaimer[4]') }}</div>
            </div>
        </div>
        <a v-if="config.env === 'development'" href="https://delhaizeapp.page.link/loyalty-cards" class="btn btn-primary">Test</a>
    </div>
</template>

<script>
    import pageViewsService from '@/services/page-views-service'
    import offerService from '@/services/offer-service'
    import dateTimeUtils from '@/utils/date-time-utils'
    import promoLinkService from '@/services/promo-link-service'
    import PromoLink from './PromoLink'
    import config from '@/mixins/config'

    export default {
        components: {
            PromoLink
        },
        mixins: [config],
        props: {
            coupons: { type: Array, default: () => [] },
            offer: { type: Object, default: null }
        },
        data() {
            return {
                componentKey: 0,
                dateTimeUtils: dateTimeUtils,
                activatedCoupons: 0,
                promoLinks: []
            }
        },
        created() {
            pageViewsService.submitPageView('AB OFFERS COUPONS', this.$route.path, this.$route.params)
            this.activeCoupons()
            this.fetchPromo()
        },
        methods: {
            async activateCoupon(coupon) {
                if (coupon.redemptionWay) {
                    return
                }
                await offerService.redeemCoupon(this.offer.id, coupon.promoId, this.$route.params.urlCode).then(res => {
                    if (!coupon.isFlipped) {
                        this.activatedCoupons++
                    }
                    coupon.isFlipped = true
                    coupon.redemptionDate = dateTimeUtils.getCurrentDate()
                    if (this.activatedCoupons === 1) {
                        window.fbq('trackCustom', 'CouponActivators')
                    }
                    window.fbq('trackCustom', 'CouponActivations')
                }
                )
            },
            pageChange(pageNumber) {
                if (isNaN(pageNumber)) {
                    pageNumber = 0
                }
                this.slideIndex = pageNumber
            },
            isLoadedOnCard(coupon) {
                return coupon.redemptionWay
            },
            activeCoupons() {
                let printedCoupons = 0
                this.offer.coupons.forEach(coupon => {
                    if (this.isLoadedOnCard(coupon)) {
                        printedCoupons++
                    }
                })
                this.activatedCoupons = printedCoupons
            },
            fetchPromo() {
                promoLinkService.fetchPromoLink(this.offer.id).then((response) => {
                    response.content.forEach((promoLink) => {
                        if (promoLink.id) {
                            this.promoLinks.push(promoLink)
                        }
                    })
                })
            }
        }
    }
</script>

<style scoped>

    .coupon-container img {
        max-width: 100%;
    }

    .background {
        background: #fff;
    }

    .list-container {
        display: flex;
        font-size: 20px;
        font-family: sans-serif;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        font-weight: 400;
    }

    .coupons-number {
        font-family: 'CFA-Bold';
        margin: 5px;
        font-weight: 600;
        font-size: 24px;
    }

    .header-container {
        background: #00aeef;
        color: #fff;
        width: 88%;
        margin-top: 20px;
    }

    .header-top {
        display: flex;
        justify-content: space-between;
        gap: 2%;
        align-items: center;
        margin: 0 8%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    .header {
        line-height: 1.1;
        font-family: 'CFA-Bold';
        margin-top: 15px;
        font-size: 26px;
    }

    .megaphone {
        align-self: center;
    }

    .valid-until {
        margin-top: 9px;
        font-family: 'CFA-Light';
        font-size: 18px;
    }

    .instruction-outer-container {
        display: flex;
        gap: 1%;
        padding: 0 6%;
        width: 100%;
        justify-content: space-between;
    }

    .instruction-container {
        background: rgba(42, 171, 226, 0.1);
        border-radius: 8px;
        color: #102e9e;
        font-family: 'CFA-Light';
        min-height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0.5% 0.8% 1.5% 0.8%;
        text-align: left;
        flex: 1;
    }

    .step-number {
        font-size: 27px;
    }

    .bold-instruction {
        font-size: 18px;
        font-family: 'CFA-Bold';
        line-height: 20px;
    }

    .light-instruction {
        font-size: 14px;
        line-height: 15px;
    }

    .coupons-container {
        display: grid;
        padding: 0 6% 5%;
        width: 100%;
        gap: 1.5%;
        grid-template-columns: repeat(4, 23.87%);
        box-sizing: border-box;
    }

    .coupon-container {
        background: #fff;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;
        width: 100%;
    }

    .activate-button {
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'CFA-Light';
        font-weight: 700;
        margin: 0.3rem 0 0.5rem;
        padding: 0.6rem 1rem;
        background: #004bd1;
        border-radius: 5.51778px;
        border: none;
    }

    .coupon {
        cursor: pointer;
    }

    .image-redeemed {
        position: relative;
    }

    .opacity-changer {
        opacity: 0.15;
    }

    .coupon-redeemed {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        color: #253e92;
        background-color: rgba(42, 171, 226, 0.4);
    }

    .header-shop-text {
        font-size: 18px;
        font-family: 'CFA-Bold';
    }

    .simple-shop-text {
        font-size: 15px;
        font-family: 'CFA-Bold';
    }

    .button-cash-redemption {
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'CFA-Light';
        font-weight: 700;
        margin: 0.3rem 0 0.5rem;
        padding: 0.6rem 1rem;
        background: #efefef;
        border-radius: 8.13043px;
        border: none;
        color: #9c9c9c;
    }

    .button-activated {
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'CFA-Light';
        font-weight: 700;
        margin: 0.3rem 0 0.5rem;
        padding: 0.6rem 1rem;
        background: #e7f6fb;
        border-radius: 8.13043px;
        border: none;
        color: #2aabe2;
    }

    .coupon.activated {
        opacity: 0.6;
        cursor: default;
    }

    .bottom-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0 6%;
    }

    .bottom-container-no-promolinks {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0 6%;
    }

    .promo-link {
        display: flex;
        align-items: center;
        padding-right: 3%;
    }

    .disclaimer-container {
        color: #253e92;
        text-align: left;
        font-size: 20px;
        border-left: 1px solid #253e92;
        padding-left: 3%;
    }

    .disclaimer-no-promolinks {
        color: #253e92;
        text-align: left;
        font-size: 20px;
        border-top: 1px solid #253e92;
        width: 100%;
        padding-top: 15px;
    }

    .small-text {
        font-size: 14px;
    }

    @media screen and (max-width: 1000px) {
        .coupons-container {
            row-gap: 0;
            column-gap: 2%;
            grid-template-columns: repeat(2, 49%);
        }

        .bottom-container {
            padding: 0 6%;
        }

        .header-container {
            width: 100%;
        }

        .promo-link {
            flex-direction: column;
        }
    }

    @media screen and (max-width: 767px) {
        .header,
        .coupons-number {
            font-size: 20px;
        }

        .valid-until {
            font-size: 14px;
        }

        .coupons-container {
            grid-template-columns: repeat(1, 100%);
            padding: 0 9%;
        }

        .bottom-container {
            grid-template-columns: 1fr;
            padding: 0 6%;
        }

        .disclaimer-container {
            font-size: 16px;
            border-top: 1px solid #253e92;
            border-left: none;
            padding: 3% 0 0;
            text-align: left;
        }

        .promo-link {
            padding: 3% 0 0;
        }

        .small-text {
            font-size: 11px;
        }
    }

    @media screen and (max-width: 577px) {
        .instruction-outer-container {
            padding: 0 4%;
        }

        .coupons-container {
            padding: 0 8%;
        }

        .bold-instruction {
            font-size: 16px;
            line-height: 20px;
        }

        .light-instruction {
            font-size: 12px;
            line-height: 15px;
        }

        .disclaimer-container {
            font-size: 14px;
        }

        .small-text {
            font-size: 11px;
        }
    }

    @media screen and (max-width: 480px) {
        .instruction-outer-container {
            padding: 0 2%;
        }

        .coupons-container {
            padding: 0 8%;
        }

        .bold-instruction {
            font-size: 12px;
            line-height: 20px;
        }

        .light-instruction {
            font-size: 12px;
            line-height: 15px;
        }

        .header,
        .coupons-number {
            font-size: 16px;
        }

        .valid-until {
            font-size: 12px;
        }
    }
</style>
