<template>
    <div class="no-offer-parent-container">
        <b-card title="Μη διαθέσιμη ενέργεια" class="child text-center" vertical-align="center">
            <b-card-text>
                Η ενέργεια "Κάθε Καλάθι Μετράει" δεν είναι διαθέσιμη. Παίξε το νέο παιχνίδι μας και κέρδισε πόντους AB PLUS.
            </b-card-text>
            <div>
                <b-button class="games-button" @click="goToGames">
                    ΠΑΙΧΝΙΔΙΑ
                </b-button>
            </div>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: 'VisitsComponent',
        methods: {
            goToGames() {
                this.$router.push('/games')
            }
        }
    }
</script>

<style scoped>
    .no-offer-parent-container {
        position: absolute;
        font-family: OpenSansRegular, sans-serif;
        background: #00a7e6;
        width: 100vw;
        height: 100vh;
        font-size: 1rem;
    }

    .child {
        position: relative;
        margin: 0 auto;
        margin-top: 25vh;
        border: none;
        border-radius: 10px;
        min-height: 40vh;
        width: 70vw;
        background-color: #fff;
        color: #0c529f;
    }

    .games-button {
        background-color: #0c529f;
        border: 1px solid #0c529f;
        padding: 0.5rem 1rem;
    }
</style>
