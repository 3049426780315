import restAPI from '@/utils/rest-api'
// import store from '@/store'
export default {
    fetchCosmoteOffers() {
        return restAPI.get('/cosmote-campaigns/available')
    },
    fetchCosmoteAvailableOffer() {
        return restAPI.get('/cosmote-campaigns/available')
    },
    validateCosmoteCode(cosmoteCode) {
        return restAPI.post(`/cosmote-campaigns/validate/${cosmoteCode}`)
    }
}
