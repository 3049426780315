import axios from 'axios'
import jwtUtils from '@/utils/jwt-utils'

const API_URL = process.env.VUE_APP_HOST_DOMAIN + process.env.VUE_APP_BASE_API
export default {
    post: function(urlPath, data) {
        return this.request(urlPath, null, 'POST', data)
    },
    get: function(urlPath) {
        return this.request(urlPath, null, 'GET', null)
    },
    request: function(urlPath, newHeaders, newMethod, newData) {
        const method = newMethod || 'POST'
        const data = newData || {}
        const headers = newHeaders || {
            'Content-Type': 'application/json; charset=utf-8'
        }
        let customerToken = localStorage.getItem('web-app-jwt-token')
        let affinityToken = localStorage.getItem('affinity-jwt-admin-token')
        if (!jwtUtils.isValid(affinityToken)) {
            localStorage.removeItem('affinity-jwt-admin-token')
            affinityToken = null
        }
        if (!jwtUtils.isValid(customerToken)) {
            localStorage.removeItem('web-app-jwt-token')
            customerToken = null
        }
        if (affinityToken) {
            localStorage.removeItem('web-app-jwt-token')
            headers['X-Authorization'] = 'Bearer ' + affinityToken
        } else if (customerToken) {
            localStorage.removeItem('affinity-jwt-admin-token')
            headers['X-Authorization'] = 'Bearer ' + customerToken
        }
        headers.credentials = 'include'

        return new Promise(function(resolve, reject) {
            axios({
                url: API_URL + urlPath,
                data: data,
                method: method,
                headers: headers
            })
                .then(function(response) {
                    const contentType = response.headers['content-type']
                    if (response.status >= 400) {
                        reject(response)
                    } else {
                        if (contentType && contentType.indexOf('application/json') > -1) {
                            return resolve(response.data)
                        } else {
                            return resolve(response)
                        }
                    }
                })
                .catch(function(error) {
                    reject(error)
                })
        })
    }
}
