<template>
    <b-container v-if="isLoggedIn" fluid class="background-image">
        <affinity-top-bar />
        <div v-if="!showNoOfferMessage" class="header-text-simple">{{ $t('affinityList.subHeader') }}</div>
        <div v-if="!showNoOfferMessage" class="offers-container">
            <div
                v-if="cosmoteOffers.length > 0"
                lg="3"
                cols="12"
                class="offer-image"
                @click="goToCosmoteOffer()"
            >
                <img
                    draggable="false"
                    class="img-fluid comsote-img"
                    src="@/assets/cosmote_offer.png"
                    alt=""
                >
            </div>
            <div
                v-for="offer in offers"

                :key="offer.id"
                lg="3"
                cols="12"
                class="offer-image"
                @click="goToAffinityOffer(offer)"
            >
                <img
                    v-if="offer.thumbnailImage"
                    draggable="false"
                    class="img-fluid "
                    :src="config.assetsHost + '/' + offer.thumbnailImage.relativePath + '/' + offer.thumbnailImage.fileName"
                    alt=""
                >
                <div class="offer-text">
                    {{ offer.name }}
                </div>
            </div>
        </div>
        <div v-if="showNoOfferMessage" class="no-offer header-text-simple mt-5">
            <div class="text-center">
                <img src="@/assets/no_offers.png" class="img-fluid no-offer-img" alt="">
            </div>
            <div>{{ $t('affinityList.noOffersRightNow') }}</div>
        </div>
    </b-container>
</template>

<script>
    import pageViewsService from '@/services/page-views-service'
    import offerService from '@/services/affinity-offer-service'
    import cosmoteOfferService from '@/services/cosmote-offer-service.js'
    import config from '@/mixins/config'
    import AffinityTopBar from '@/components/AffinityTopBar'

    export default {
        name: 'AffinityOffersListRoute',
        components: {
            AffinityTopBar
        },
        mixins: [config],
        data() {
            return {
                offers: [],
                cosmoteOffers: []
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isUserLoggedIn
            },
            showNoOfferMessage() {
                return this.offers.length === 0 && this.cosmoteOffers.length === 0
            }
        },
        created() {
            pageViewsService.submitPageView('AB PARTNERS POOL', this.$route.path, this.$route.params)
            this.getOffers()
        },
        methods: {
            goToAffinityOffer(offer) {
                this.$router.push(`/partners/${offer.id}`)
            },
            goToCosmoteOffer() {
                this.$router.push('/partners/cosmote-offers/')
            },
            async getOffers() {
                if (!this.isLoggedIn) {
                    return
                }
                await offerService.fetchAffinityOffers().then((resp) => {
                    this.offers = resp.content
                })
                await cosmoteOfferService.fetchCosmoteAvailableOffer().then((resp) => {
                    this.cosmoteOffers = resp.content
                })
            }
        }
    }
</script>

<style scoped lang="scss">

.background-image {
    background: url("~@/assets/images/affinity_bg.jpg");
    background-repeat: repeat;
    height: 100%;
    position: fixed;
    width: 100%;
    overflow: auto;
}

.no-offer-img {
    max-width: 120px;
    max-height: 120px;
}

.offers-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
}

.no-offer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.offer-image {
    position: relative;
    margin: 15px 0;
    border-radius: 12px;
    cursor: pointer;
}

.header-text-simple {
    text-align: center;
    margin-bottom: 100px;
    font-size: 40px;
    line-height: 43px;
    font-family: 'CFA-Bold';
    color: #102e9e;
}

.comsote-img {
    border-radius: 12px;
}

.offer-text {
    position: absolute;
    top: 45%;
    left: 5%;
    width: 100%;
    max-width: 200px;
    font-size: 25px;
    line-height: 27px;
    font-family: 'CFA-light';
    font-weight: 600;
    color: #253e92;
}

@media screen and (max-width: 1600px) {
    .header-text-simple {
        margin-bottom: 20px;
        padding: 25px;
        font-size: 25px;
        line-height: 27px;
    }

    .offer-text {
        font-size: 18px;
        line-height: 20px;
        max-width: 175px;
    }
}

</style>
