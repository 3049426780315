<template>
    <div class="affinity-top-bar">
        <div class="left-container">
            <div v-if="showHome" class="hidden-element">
                <button :disabled="disableHome" class="btn icon-home" @click="backToHome()" />
            </div>
            <div class="card-container">
                <img src="@/assets/card-code.png" alt=" " class="letter-icon">
                <div class="ml-3" :class="[{ 'card-text-position': showHome }, {'card-text-position-without-home': !showHome}]">
                    <div class="card-text-up">{{ $t('gameTopBar.cardCode') }}</div>
                    <div v-if="abPlusNumber" class="card-text-down">{{ 'XXXXXXXXXX' + abPlusNumber.toString().slice(9, 12) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AffinityTopBar',
        props: {
            showHome: {
                type: Boolean,
                default: () => true
            },
            disableHome: {
                type: Boolean,
                default: () => true
            }
        },
        computed: {
            abPlusNumber() {
                return this.$store.getters.getAbPlusNumber
            },
            currentUser() {
                return this.$store.getters.getCurrentUser || {}
            }
        },
        methods: {
            logOut() {
                this.$store.dispatch('logout', '/partners')
            },
            backToHome() {
                this.$router.push({ path: '/partners' })
            }
        }
    }
</script>

<style scoped lang="scss">
.btn-sign-out {
    height: 138px;
    width: 137px;
    border: none;
    outline: none;
    background: none;
}

.letter-icon {
    position: relative;
    width: 100%;
    height: auto;
}

.icon-home {
    background-image: url("~@/assets/home_new_button.png");
    display: inline-block;
    height: 138px;
    width: 137px;
}

.affinity-top-bar {
    margin-top: 20px;
    margin-bottom: 50px;
    max-height: 115px;
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.left-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.card-container {
    display: flex;
    align-items: center;
}

.card-text-position {
    left: 250px;
    position: absolute;
}

.card-text-position-without-home {
    left: 115px;
    position: absolute;
}

.card-text-up {
    color: #fff;
    font-size: 18px;
    font-family: 'CFA-Bold';
}

.card-text-down {
    text-align: left;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    font-family: 'CFA-light';
}

@media screen and (max-width: 1600px) {
    .card-text-up {
        font-size: 12px;
    }

    .card-text-position {
        left: 80px;
    }

    .card-text-position-without-home {
        left: 75px;
    }

    .letter-icon {
        max-width: 218px;
    }

    .card-text-down {
        font-size: 12px;
    }

    .hidden-element {
        display: none !important;
    }

    .affinity-top-bar {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .btn-sign-out {
        width: 100%;
        max-width: 110px;
        height: auto;
    }

    .exit-image {
        width: 100%;
        height: auto;
    }
}
</style>
