import restAPI from '@/utils/rest-api'

export default {
    fetchCurrentUser() {
        return restAPI.get('/users/current')
    },
    fetchCurrentAdminUser() {
        return restAPI.get('/admin-users/current')
    },
    login(barcode) {
        return restAPI.post('/users/login', {
            barcode
        })
    },
    loginByUrlCode(urlCode) {
        return restAPI.post('/users/login-by-urlcode', {
            urlCode
        })
    },
    adminLogin(username, password) {
        return restAPI.post('/admin-users/login', {
            username,
            password
        })
    },
    checkPhoneNumber(mobileNumber) {
        return restAPI.post('/users/validate-phone-number', { mobileNumber })
    },
    editProfile(user) {
        return restAPI.post('/users/current/edit-profile', user)
    },
    checkCardLimit(mobileNumber) {
        return restAPI.get(`/users/more-than-four-cards/${mobileNumber}`)
    },
    isCardBanned(abPlusCard) {
        return restAPI.post('/users/banned', { barcode: abPlusCard })
    },
    isCustomerOfUrlCodeBanned(urlCode) {
        return restAPI.get(`/users/url-code/${urlCode}/banned`)
    }
}
