<template>
    <b-container id="body" class="mb-3">
        <combo-combination
            id="top"
            :combo-products="topComboProducts"
        />
        <div class="upper-line" />

        <combo-combination
            id="bottom"
            :combo-products="bottomComboProducts"
        />
        <div class="upper-line lower-line" />

        <div class="help-text">
            <div>
                <img
                    :src="require('@/assets/images/combo/arrow_down.png')"
                    class="rotate-arrow"
                    alt=""
                >
            </div>
            <div class="header-text">
                {{ $t('introBody.select') }}
                <span class="highlighted-bottom">{{ $t('introBody.bottomShelf') }}</span>
                {{ $t('introBody.toCreate') }}
                <div class="points-container">
                    {{ points }}
                </div>
                {{ $t('introBody.points') }}
            </div>
        </div>
    </b-container>
</template>

<script>
    import ComboCombination from '@/components/combo/ComboCombination'
    export default {
        components: {
            ComboCombination
        },
        props: {
            offer: { type: Object, default: null }
        },
        data() {
            return {
                comboImages: {
                    topImages: [],
                    bottomImages: []
                }
            }
        },
        computed: {
            topComboProducts() {
                if (!this.offer) {
                    return []
                }
                return this.offer.firstComboProducts
            },
            bottomComboProducts() {
                if (!this.offer) {
                    return []
                }
                return this.offer.secondComboProducts
            },
            points() {
                return this.offer.coupons[0].points
            }
        }
    }
</script>
<style lang="scss" scoped>
    .products {
        width: 100%;
    }

    .help-text {
        display: flex;
        flex-direction: row;
    }

    .rotate-arrow {
        height: 50%;
        width: 10vw;
        max-width: 100px;
        transform: rotate(180deg);
    }

    .header-text {
        font-size: 4.5vw;
        font-family: ThickStick, sans-serif;
    }

    .highlighted-bottom {
        color: #f9855a;
    }

    .upper-line {
        height: 2.5vw;
        max-height: 24px;
        border-radius: 0 0 5px 5px;
        transform: translateY(-1rem);
        background-color: #7c5cb4;
    }

    .lower-line {
        background-color: #f9855a;
    }

    .points-container {
        background-color: #00a0e4;
        width: 7vw;
        max-width: 85px;
        height: 7vw;
        max-height: 85px;
        border-radius: 50%;
        color: white;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    @media screen and (min-width: 575px) {
        .upper-line {
            width: calc(100% - 30px);
            margin: auto;
            height: 2vw;
            transform: translateY(-1.25rem);
        }
    }

    @media screen and (min-width: 1200px) {
        .header-text {
            font-size: 60px;
        }
    }
</style>
