import restAPI from '@/utils/rest-api'

export default {
    fetchAffinityOffers() {
        return restAPI.get('/affinity-offers/available')
    },
    fetchAffinityOfferById(affinityOfferId) {
        return restAPI.get(`/affinity-offers/${affinityOfferId}`)
    },
    fetchDefaultAffinityOffer() {
        return restAPI.get('/affinity-offers/default')
    },
    checkAffinityCode(affinityCode) {
        return restAPI.post('/affinity-offers/code-is-valid', { affinityCode })
    },
    checkAffinityCustomerParticipationByCode(affinityCode) {
        return restAPI.get(`/affinity-offers/allow-customer-to-participate/${affinityCode}`)
    },
    checkIfAbCardIsBlocked(abCard) {
        return restAPI.get(`/affinity-offers/blocked-with-failed-attempts/ab-card/${abCard}`)
    },
    fetchAffinityOfferCoupons(affinityCode) {
        return restAPI.post('/affinity-offers/get-offer-by-code', { affinityCode })
    },
    redeemAffinityCoupons(affinityOfferId, couponPromoIds, code) {
        return restAPI.post(`/affinity-offers/${affinityOfferId}/redeem-on-web/${code}`, { couponPromoIds })
    }
}
