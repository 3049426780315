export default {
    state: {
        contest: null
    },
    mutations: {
        setContest(state, contest) {
            state.contest = contest
        },
        redeemParticipation(state, participationId) {
            const participation = state.contest.participations.find(participation => participation.id === participationId)
            participation.redeemed = true
        }
    },
    actions: {
        setContest({ commit }, contest) {
            commit('setContest', contest)
        },
        redeemParticipation({ commit }, participationId) {
            commit('redeemParticipation', participationId)
        }
    }
}
