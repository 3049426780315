<template>
    <div
        ref="login"
        class="outer-container"
    >
        <img src="@/assets/big_card_ab.png" class="logo-image" alt="ab_plus_logo">
        <div class="bold-text-header">
            {{ $t('loginModal.typeCode[0]') }} <span class="light-text-header">{{ $t('loginModal.typeCode[1]') }}</span>
        </div>
        <div class="light-text-header">
            της <span class="bold-text-header">{{ $t('loginModal.typeCode[2]') }}</span> {{ $t('loginModal.typeCode[3]') }}
        </div>
        <div class="form-container">
            <b-form-input
                ref="focusThis"
                v-model="barcode"
                type="text"
                class="place-holder-container"
                :class="{wrong: !correctBarcodeFormat}"
                placeholder="047xxxxxxxxxx"
                onfocus="this.placeholder = ''"
                :formatter="formatBarcode"
                @keypress.enter="cardValidation"
            />
        </div>
        <div v-if="wrongCardCode" class="error-container">
            <b-icon class="x-circle-image ml-3 mt-1" icon="x-circle" variant="danger" />
            <p class="error-text"> {{ $t('loginModal.tryAgain') }} </p>
        </div>
        <div v-else-if="connectionIssue" class="error-container">
            <b-icon class="x-circle-image ml-3 mt-1" icon="x-circle" variant="danger" />
            <span class="error-text"> {{ $t('loginModal.connectionFailure') }} </span>
        </div>
        <div v-else-if="bannedCard" class="error-container flex-column px-2">
            <span class="error-text mb-0">{{ $t('errors.bannedCard[0]') }}</span>
            <span class="error-text mt-0">
                {{ $t('errors.bannedCard[1]') }}
                <a href="https://www.ab.gr/" target="_blank" class="emphasized-text">{{ $t('errors.bannedCard[2]') }}</a>
                {{ $t('errors.bannedCard[3]') }}
                <span class="emphasized-text">{{ $t('errors.bannedCard[4]') }}</span>
            </span>
        </div>
        <div class="info-text"> {{ $t('loginModal.codeInfo') }}</div>
        <button
            size="lg"
            class="login-button"
            :class="{wrong: !correctBarcodeFormat}"
            @click="cardValidation"
        >
            <span class="mr-5">{{ $t('loginModal.in') }} </span><img src="@/assets/arrow.png" :class="{wrong: !correctBarcodeFormat}" class="ml-4">
        </button>
        <div v-if="config.env === 'development'" class="mt-5">
            <b-btn
                size="sm"
                variant="info"
                @click="devLogin()"
            >
                {{ $t('loginModal.devIn') }}
            </b-btn>
        </div>
    </div>
</template>

<script>
    import userService from '@/services/user-service'
    import config from '@/mixins/config'
    export default {
        mixins: [config],
        data() {
            return {
                barcode: '',
                correctBarcodeFormat: true,
                wrongCardCode: false,
                bannedCard: false,
                connectionIssue: false
            }
        },
        methods: {
            cardValidation() {
                this.wrongCardCode = false
                this.connectionIssue = false
                this.bannedCard = false

                if (!this.correctBarcodeFormat || this.barcode.length === 0) {
                    return
                }
                userService.isCardBanned(this.barcode)
                    .then((response) => {
                        const isBanned = response.content
                        if (isBanned) {
                            this.bannedCard = true
                        } else {
                            this.login()
                        }
                    }).catch((err) => {
                        if (err.response && err.response.status !== 503) {
                            this.wrongCardCode = true
                        } else {
                            this.connectionIssue = true
                        }
                    })
            },
            focusMyElement(e) {
                this.$refs.focusThis.focus()
            },
            closeModal() {
                this.barcode = null
                this.$store.dispatch('hideLoginModal')
            },
            login() {
                userService.login(parseInt(this.barcode)).then(response => {
                    this.$store.dispatch('login', { barcode: parseInt(this.barcode), ...response })
                    this.$router.push(this.$route.query.from || '/games')
                }, () => {
                    this.correctBarcodeFormat = false
                    this.wrongCardCode = true
                })
            },
            devLogin() {
                this.barcode = this.config.devAbCard
                this.cardValidation()
            },
            formatBarcode(input) {
                if (input.length === 0) {
                    this.correctBarcodeFormat = true
                    return input
                }
                const card = /^\d{12,14}$/
                if (!input.match(card)) {
                    this.correctBarcodeFormat = false
                    return input.substring(0, 13)
                }
                this.correctBarcodeFormat = true
                return input.substring(0, 13)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-text {
        font-size: 0.7em;
    }

    .x-circle-image {
        height: 20px;
        width: 20px;
    }

    .place-holder-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        height: 50px;
        border-radius: 12px;
        min-width: 340px;
        margin-bottom: 15px;
        max-width: 356px;
    }

    .form-container {
        display: flex;
        justify-content: center;
    }

    ::placeholder {
        font-weight: 600;
        font-size: 24px;
        opacity: 0.4 !important;
        font-family: 'CFA-light';
    }

    .error-container {
        height: auto;
        min-height: 30px;
        max-width: 500px;
        margin: 10px auto;
        padding-top: 2px;
        font-size: clamp(13px, 1vw, 19px);
        font-family: 'CFA-light';
        vertical-align: center;
        font-weight: 700;
        color: #fff;
        background-color: #d18098;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
    }

    .flex-column {
        flex-direction: column;
    }

    .error-text {
        margin: 4px auto;
    }

    .emphasized-text {
        font-family: 'CFA-bold', 'sans-serif';
        font-size: clamp(14px, 1.2vw, 20px);
    }

    .inside-image.wrong {
        border: 3px solid red;
        color: #c82020;
    }

    .outer-container {
        text-align: center;
        background: url('~@/assets/player_info_background.png');
        margin: 0 auto;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 100px;
        height: 100%;
        position: fixed;
        z-index: 999999;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .info-text {
        max-width: 316px;
        line-height: 15px;
        font-family: 'CFA-light';
        color: #2aabe2;
        font-weight: 700;
        margin: 0 auto;
        font-size: 16px;
    }

    .logo-image {
        position: absolute;
        max-width: 400px;
        width: 100%;
        transform: rotate(-25deg);
        right: -55px;
        top: -16px;
    }

    .login-button {
        margin-top: 90px;
        font-family: 'CFA-light';
        width: 211px;
        height: 50px;
        border: none;
        font-size: 18px;
        border-radius: 12px;
        background: #00aeef;
        color: #fff;
    }

    .login-button.wrong {
        opacity: 0.4;
    }

    .login-icon.wrong {
        opacity: 0.4;
    }

    .bold-text-header {
        font-size: clamp(24px, 1.5vw, 28px);
        font-family: 'CFA-Bold';
        color: #102e9e;
        font-weight: 700;
        margin-top: 350px;
    }

    @media screen and (max-width: 1200px) {
        .logo-image {
            max-width: 350px;
        }

        .bold-text-header {
            margin-top: 290px;
        }

        .outer-container {
            background: url('~@/assets/pattern.jpg');
            background-repeat: repeat;
            height: 100%;
            position: fixed;
            width: 100%;
        }
    }

    .light-text-header {
        font-size: clamp(24px, 1.5vw, 28px);
        font-family: 'CFA-light';
        color: #102e9e;
        font-weight: 700;
    }
</style>
