<template>
    <div class="top-bar">
        <top-bar class="mobile-only" />
        <div class="desktop-only">
            <button class="btn icon-home" @click="backToHome" />
            <div class="ab-card">
                <img :src="require('@/assets/card-code.png')" alt="AB card">
                <div class="ab-card-text">
                    <div class="ab-card-title">{{ $t('contestTopBar.abCard') }}</div>
                    <div class="ab-card-code">{{ obfuscatedAbPlusNumber }}</div>
                </div>
            </div>
            <button class="btn icon-exit" @click="logout" />
        </div>
    </div>
</template>

<script>
    import TopBar from '@/components/TopBar.vue'

    export default {
        components: { TopBar },
        computed: {
            abPlusNumber() {
                return this.$store.getters.getAbPlusNumber
            },
            obfuscatedAbPlusNumber() {
                return 'XXXXXXXXXX' + this.abPlusNumber.toString().slice(9, 12)
            }
        },
        methods: {
            backToHome() {
                this.$router.push({
                    name: 'Mobile Games'
                })
            },
            logout() {
                this.$store.dispatch('logout')
            }
        }
    }
</script>

<style scoped>
.mobile-only {
    display: flex;
}

.desktop-only {
    display: none;
    padding: 1rem 2rem;
    align-items: center;
    gap: 1.8125rem;
}

.btn {
    display: inline-block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 0.875rem;
    font-family: "CFA-Bold", sans-serif;
    width: 8.1875rem;
    height: 8.1875rem;
}

.icon-home {
    background-image: url("~@/assets/home_new_button.png");
}

.icon-exit {
    background-image: url("~@/assets/exit_new_button.png");
    margin-left: auto;
}

.ab-card {
    font-size: 1.125rem;
    text-align: center;
    font-family: CFA-Normal, sans-serif;
    color: white;
    position: relative;
}

.ab-card-text {
    position: absolute;
    top: 15%;
    left: 28%;
    width: 14.25rem;
    height: 5.125rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ab-card-title {
    font-family: "CFA-Bold", sans-serif;
}

@media screen and (min-width: 768px) and (orientation: landscape) {
    .mobile-only {
        display: none;
    }

    .desktop-only {
        display: flex;
    }
}
</style>
