<template>
    <b-container v-if="isAvailableCosmoteOffer && isLoggedIn" fluid class="background-image">
        <affinity-top-bar :disable-home="false" />
        <b-row class="justify-content-center pt-4 flex-column text-center">
            <div class="text-bold-description">
                {{ $t('affinityInsertCode.insertCode') }}
            </div>
        </b-row>
        <b-row class="mt-5 justify-content-center align-items-center">
            <b-form-input
                :value="code"
                size="lg"
                placeholder="Κωδικός"
                class="input-box"
                @input="onInputChange($event, 'code')"
                @keypress="codeKeyPress($event)"
            />
            <button
                class="sumbit-button"
                :disabled="!code"
                :class="{ 'disabled-button': !code }"
                @click="submitCode()"
            />
        </b-row>
        <b-alert v-model="submitDone" variant="primary" class="saved-msg ml-4 mt-4 w-75 pt-3" style="font-weight: 900;">
            <div class="alert-container">
                <b-icon icon="check-circle" font-scale="2" class="mt-n1 mr-2 ml-n2" />
                <p class="align-self-center mb-0 mt-n1">{{ $t("cosmote." + validationStatus , { coupon: code }) }}</p>
            </div>
        </b-alert>
        <div
            v-if="validationStatus && validationStatus !== 'OK'"
            class="error-message-container"
        >
            <div class="mt-1 error-message-light">
                <div>
                    {{ $t("cosmote." + validationStatus) }}
                </div>
            </div>
        </div>
        <div class="align-center text-responsive">
            <img src="@/assets/images/back_button.png" class="back-button mb-4" @click="goBack">
        </div>
    </b-container>
</template>

<script>
    import cosmoteOfferService from '@/services/cosmote-offer-service.js'
    import config from '@/mixins/config'
    import Vue from 'vue'
    import AffinityTopBar from '@/components/AffinityTopBar'

    export default {
        name: 'CosmoteInsertCodeRoute',
        components: {
            AffinityTopBar
        },
        mixins: [config],
        data() {
            return {
                isAvailableCosmoteOffer: false,
                code: '',
                submitDone: false,
                validationStatus: null,
                isValidCode: true
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isUserLoggedIn
            }
        },
        created() {
            cosmoteOfferService.fetchCosmoteAvailableOffer().then(cosmoteOffers => {
                this.isAvailableCosmoteOffer = cosmoteOffers.content.length > 0
            })
        },
        methods: {
            async submitCode() {
                const validateCosmoteCode = await cosmoteOfferService.validateCosmoteCode(
                    this.code
                )
                if (validateCosmoteCode.statuses.length > 1) {
                    const notOkStatusList = validateCosmoteCode.statuses.filter(
                        status => status !== 'OK'
                    )
                    if (
                        notOkStatusList.includes('SOME_COUPONS_FOUND_EXPIRED') &&
                        notOkStatusList.includes('SOME_COUPONS_NOT_FOUND')
                    ) {
                        this.validationStatus =
                            'SOME_COUPONS_FOUND_EXPIRED_AND_SOME_COUPONS_NOT_FOUND'
                    } else {
                        this.validationStatus = notOkStatusList[0]
                    }
                } else {
                    this.validationStatus = validateCosmoteCode.statuses[0]
                }
                if (this.validationStatus !== 'OK') {
                    return
                }
                this.submitDone = true
                this.showKeyboardOnInput = ''
                setTimeout(() => {
                    this.submitDone = false
                    this.code = ''
                }, 7000)
            },
            codeKeyPress(event) {
                const isCodeKeyValid = /^[a-zA-Zα-ωΑ-Ω0-9]+$|^$/.test(event.key)
                if (!isCodeKeyValid) {
                    event.preventDefault()
                }
            },
            onInputChange(input, inputName) {
                if (input && input.target) {
                    Vue.set(this, inputName, input.target.value)
                } else if (input && !input.target) {
                    Vue.set(this, inputName, input)
                }
            },
            goBack() {
                this.$router.back()
            }
        }
    }
</script>

<style scoped lang="scss">
.background-image {
    background: url("~@/assets/images/affinity_bg.jpg");
    background-repeat: repeat;
    height: 100%;
    position: fixed;
    width: 100%;
    overflow: scroll;
}

.text-bold-description {
    font-size: 50px;
    line-height: 52px;
    font-weight: 700;
    color: #102e9e;
    font-family: 'CFA-Bold';
}

.align-center {
    margin-top: 100px;
    margin-right: 5%;
    display: flex;
    justify-content: center;
}

.back-button {
    width: 350px;
    cursor: pointer;
}

.input-box {
    background-color: #fefefe;
    color: #ccc;
    border-radius: 20px;
    margin-right: 20px;
    border: 0;
    width: calc(330px + 30vmin);
    height: calc(25px + 7vmin);
    padding: 25px;
    text-align: center;
    font-family: 'CFA-bold';
    font-size: clamp(1.25rem, 1vw + 1rem, 1.875rem);
    align-self: baseline;
}

.alert-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-box:focus {
    background-color: #0d5198;
    color: #fff;
}

.input-box::placeholder {
    color: #99a4c3;
}

.input-box.focused::placeholder {
    color: #fff;
}

.sumbit-button {
    background: url('~@/assets/images/insert_code.png') no-repeat;
    background-size: 100% auto;
    width: calc(25px + 7vmin);
    height: calc(25px + 7vmin);
    outline: none;
    border: none;
}

.disabled-button {
    opacity: 0.25 !important;
}

.error-message-container {
    margin: 0 auto;
    text-align: center;
    width: calc(190px + 38vmin);
}

.bold-text {
    font-family: 'CFA-Bold';
}

.error-message-light {
    font-family: 'CFA-light';
    font-weight: 700;
    font-size: clamp(12px, 1vw + 1rem, 18px);
    color: red;
    justify-content: center;
}

@media screen and (max-width: 977px) {
    .input-box {
        width: 270px;
        height: 45px;
        padding: 6px;
        border-radius: 10px;
    }

    .text-bold-description {
        margin: 0 10%;
        font-size: 32px;
        line-height: 34px;
    }

    .sumbit-button {
        width: 45px;
        height: 45px;
    }
}

@media screen and (max-width: 990px) {
    .back-button {
        width: 218px;
    }
}

</style>
