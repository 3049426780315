import userService from '@/services/user-service'
import gameService from '@/services/game-service'
import jwtUtils from '@/utils/jwt-utils'
import tokenUtils from '@/utils/token-utils'
import router from '@/router'
export default {
    state: {
        jwtToken: localStorage.getItem('web-app-jwt-token'),
        refreshToken: localStorage.getItem('web-app-refresh-token'),
        refreshTokenExpiration: localStorage.getItem('web-app-refresh-token-expiration'),
        abPlusNumber: localStorage.getItem('ab-plus-number'),
        user: null,
        gameHistory: [],
        leaderboard: {}
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setGameHistory(state, gameHistory) {
            state.gameHistory = gameHistory
        },
        setLeaderboard(state, leaderboard) {
            state.leaderboard = leaderboard
        },
        setAbPlusNumber(state, abPlusNumber) {
            state.abPlusNumber = abPlusNumber
        },
        setJwtToken(state, jwtToken) {
            state.jwtToken = jwtToken
        },
        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken
        },
        setRefreshTokenExpiration(state, value) {
            state.refreshTokenExpiration = value
        }
    },
    actions: {
        updateUserProfile({ commit }, user) {
            userService.editProfile(user).then(userResp => {
                commit('setUser', userResp)
            })
        },
        login({ commit, dispatch }, payload) {
            localStorage.setItem('ab-plus-number', payload.barcode)
            commit('setAbPlusNumber', payload.barcode)
            dispatch('setRefreshToken', payload.refreshToken)
            dispatch('setJwtToken', payload.token)
            dispatch('refreshCurrentUser')
            dispatch('setRefreshTokenExpiration', payload.refreshTokenExpiration)
        },
        loginByUrlCode({ commit, dispatch }, payload) {
            const barcode = jwtUtils.getBarcode(payload.token)
            localStorage.setItem('ab-plus-number', barcode)
            commit('setAbPlusNumber', barcode)
            dispatch('setJwtToken', payload.token)
            dispatch('setRefreshToken', payload.refreshToken)
            dispatch('setRefreshTokenExpiration', payload.refreshTokenExpiration)
            dispatch('refreshCurrentUser')
        },
        logout({ commit, dispatch }, payload) {
            localStorage.removeItem('ab-plus-number')
            commit('setUser', null)
            commit('setAbPlusNumber', null)
            dispatch('setRefreshToken', null)
            dispatch('setJwtToken', null)
            dispatch('setRefreshTokenExpiration', null)
            if (!payload) {
                router.push('/login')
                return
            }
            router.push({ path: '/login', query: { from: payload } })
        },
        setJwtToken({ commit }, jwtToken) {
            if (jwtToken) {
                localStorage.setItem('web-app-jwt-token', jwtToken)
                commit('setJwtToken', jwtToken)
            } else {
                localStorage.removeItem('web-app-jwt-token')
                commit('setJwtToken', null)
            }
        },
        refreshJwtToken() {
            tokenUtils.refreshJwtUsingRefreshToken()
        },
        refreshCurrentUser({ commit, dispatch }) {
            const authToken = localStorage.getItem('web-app-jwt-token')
            if (authToken && !jwtUtils.isValid(authToken)) {
                dispatch('logout')
            }
            return userService.fetchCurrentUser().then((user) => {
                commit('setUser', user)
                dispatch('refreshGameHistory')
                dispatch('refreshLeaderboard')
            }).catch(() => {
                dispatch('logout')
            })
        },
        setRefreshTokenExpiration({ commit }, refreshTokenExpiration) {
            if (refreshTokenExpiration) {
                localStorage.setItem('web-app-refresh-token-expiration', refreshTokenExpiration)
                commit('setRefreshTokenExpiration', refreshTokenExpiration)
            } else {
                localStorage.removeItem('web-app-refresh-token-expiration')
                commit('setRefreshTokenExpiration', null)
            }
        },
        setRefreshToken({ commit }, refreshToken) {
            if (refreshToken) {
                localStorage.setItem('web-app-refresh-token', refreshToken)
                commit('setRefreshToken', refreshToken)
            } else {
                localStorage.removeItem('web-app-refresh-token')
                commit('setRefreshToken', null)
            }
        },
        refreshGameHistory({ commit }) {
            gameService.fetchGameHistory().then((history) => {
                commit('setGameHistory', history.content)
            })
        },
        refreshLeaderboard({ commit }) {
            gameService.fetchGameLeaderBoard().then(leaderboard => {
                commit('setLeaderboard', leaderboard)
            })
        }
    }
}
